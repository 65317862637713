import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { useGetArticulosMutation } from '../../../../store/api/endpoints/articulos/articulosApiSlice'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import '../../../../assets/css/table.css'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import BakeryDiningIcon from '@mui/icons-material/BakeryDining'



export default function Articulos() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)

    if (permisosDelUsuario.indexOf(22) == -1) return (<p>No tienes acceso a visualizar los artículos.</p>)

    const thead = []
    if (configArticulos.configArticulos?.img_principal.activo) thead.push({ label: "Imagen", align: "center" })
    if (configArticulos.configArticulos?.codigo) thead.push({ label: "Código", align: "center" })
    if (configArticulos.configArticulos?.categorias?.activo) thead.push({ label: "Categoría", align: "left" })
    if (configArticulos.configArticulos?.subcategorias?.activo) thead.push({ label: "Subcategoría", align: "left" })
    thead.push({ label: "Nombre", align: "left" })
    if (configArticulos.configArticulos?.precio?.activo) {
        for (let i = 0; i < configArticulos.configArticulos.precio.cantidad; i++) {
            thead.push({ label: configArticulos.configArticulos.precio.nombres[i], align: "right" })
        }
    }
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    if (configArticulos.configArticulos?.img_principal.activo) tbody.push({ tipo: "img", valor: "img_principal" })
    if (configArticulos.configArticulos?.codigo) tbody.push({ tipo: "texto", valor: "codigo" })
    if (configArticulos.configArticulos?.categorias?.activo) tbody.push({ tipo: "texto", valor: "categoria" })
    if (configArticulos.configArticulos?.subcategorias?.activo) tbody.push({ tipo: "texto", valor: "subcategoria" })
    tbody.push({ tipo: "texto", valor: "titulo" })
    if (configArticulos.configArticulos?.precio?.activo) {
        for (let i = 0; i < configArticulos.configArticulos.precio.cantidad; i++) {
            tbody.push({ tipo: "precio", options: [",", ".", ''], valor: "lista" + (i * 1 + 1) })
        }
    }

    let botones = []
    if (permisosDelUsuario.indexOf(22) > -1) botones.push({ color: "primary", icono: <EditIcon />, ruta: "/" + urlParams.dominio + '/articulos/' })
    tbody.push({ tipo: "botones", valor: botones })

    if (permisosDelUsuario.indexOf(22) > -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Artículos</Typography>
                    </Breadcrumbs>
                }
                icon={<BakeryDiningIcon />}
                end={(permisosDelUsuario.indexOf(23) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/articulos/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetArticulosMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}
