import { tiendasApi } from '../../api'

export const configApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getConfig: builder.mutation({
            query: () => ({
                url: '/config/config',
                method: 'GET',
            })
        }),
    })
})

export const {
    useGetConfigMutation,
} = configApiSlice