import { tiendasApi } from '../../api'

export const articulosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getArticulos: builder.mutation({
            query: (headersTemp) => ({
                url: '/articulos/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getArticulo: builder.mutation({
            query: (id) => ({
                url: '/articulos/' + id,
                method: 'GET',
            })
        }),
        createArticulo: builder.mutation({
            query: (body) => ({
                url: '/articulos',
                method: 'POST',
                body: body,
            })
        }),
        updateArticulo: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/articulos/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeArticulo: builder.mutation({
            query: (id) => ({
                url: '/articulos/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetArticulosMutation,
    useGetArticuloMutation,
    useCreateArticuloMutation,
    useUpdateArticuloMutation,
    useRemoveArticuloMutation,
} = articulosApiSlice