import { configureStore  } from "@reduxjs/toolkit"

import { setupListeners } from '@reduxjs/toolkit/query'
import { tiendasApi } from './api/api'

import { authSlice } from './slices/auth'
import { sidebarSlice } from './slices/sidebar'
import { feedbackSlice } from './slices/feedback'
import { configServiciosSlice } from './slices/configServicios'
import { configArticulosSlice } from './slices/configArticulos'
import { configUsuariosSlice } from './slices/configUsuarios'
import { configClientesSlice } from './slices/configClientes'
import { configVentasSlice } from './slices/configVentas'
import { configSlice } from './slices/config'
import { configDefaultsClientesSlice } from './slices/configDefaultsClientes'


export const store = configureStore({
    reducer: {
        [tiendasApi.reducerPath]: tiendasApi.reducer,

        auth: authSlice.reducer,
        sidebar: sidebarSlice.reducer,
        feedback: feedbackSlice.reducer,
        configServicios: configServiciosSlice.reducer,
        configArticulos: configArticulosSlice.reducer,
        configUsuarios: configUsuariosSlice.reducer,
        configClientes: configClientesSlice.reducer,
        configVentas: configVentasSlice.reducer,
        config: configSlice.reducer,
        configDefaultsClientes: configDefaultsClientesSlice.reducer,

    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(tiendasApi.middleware),
});

setupListeners(store.dispatch);