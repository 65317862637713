import { React } from 'react'
import { useNavigate } from 'react-router-dom'


export default function PedidoArticulos({ dominio, configArticulos, responseGetPedido }) {

    const navigate = useNavigate()

    return (
        <div className="tableContainer" style={{ marginBottom: '10px' }}>
            <table className="formato1" cellSpacing="0" cellPadding="0">
                <thead>
                    <tr>
                        {configArticulos.configArticulos.img_principal.activo && <th align="center">Img</th>}
                        <th align="left">Título</th>
                        {configArticulos.configArticulos.precio.activo && <th align="right">Unit.</th>}
                        <th align="right">Cant.</th>
                        {configArticulos.configArticulos.precio.activo && <th align="right">Total</th>}
                    </tr>
                </thead>
                <tbody>
                    {
                        responseGetPedido.articulos.map((unArticulo, index) => {

                            return (
                                <tr key={index}>
                                    {configArticulos.configArticulos.img_principal.activo && <td align="center"><img src={unArticulo.img_principal} height={50} /></td>}
                                    <td align='left'>{unArticulo.categoria}<br />{unArticulo.titulo}</td>
                                    {configArticulos.configArticulos.precio.activo && <td align="right">{unArticulo.unitario}</td>}
                                    <td align="right">{unArticulo.cantidad}</td>
                                    {configArticulos.configArticulos.precio.activo && <td align="right">{unArticulo.cantidad * unArticulo.unitario}</td>}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )

}