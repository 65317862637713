import { React, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "../../assets/css/TextEditor.css";

export default function TextEditor({ textoHtml, setTextoHtml }) {

  const contentBlock = htmlToDraft(textoHtml);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );

  const handleEditorChange = (state) => {
    setEditorState(state);
    sendContent();
  };

  const sendContent = () => {
    setTextoHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <div style={{ background: '#fff', border: '1px solid #DCDCDC' }}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={handleEditorChange}
      />
    </div>
  );
}

