import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'

import { useCreateClienteLoginsMutation } from '../../../../store/api/endpoints/clientes/clientesLoginsApiSlice'

import { GetClienteNombre } from '../clientes/GetClienteNombre'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import BadgeIcon from '@mui/icons-material/Badge'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import MailIcon from '@mui/icons-material/Mail'
import PasswordIcon from '@mui/icons-material/Password'
import CheckIcon from '@mui/icons-material/Check'

export default function ClientesLoginsNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreateClienteLoginsMutation()


    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_fijo, setTelFijo] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [contrasena1, setContrasena1] = useState('')
    const [contrasena2, setContrasena2] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            cliente: urlParams.idCliente,
            nombre,
            email,
            tel_fijo,
            tel_whatsapp,
            contrasena1,
            contrasena2,
        }

        createRegistro(body)


    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente)

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    if (permisosDelUsuario.indexOf(51) === -1) return <SinPermisos />

    if (isLoadingCreaRegistro) return <Loading />

    if (permisosDelUsuario.indexOf(51) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/"}>
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.idCliente}>
                            <GetClienteNombre idCliente={urlParams.idCliente} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente}>
                            Usuarios
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <p>Este usuario podrá acceder con su <b>email</b> y <b>contraseña</b> para realizar pedidos en la web.</p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre y Apellido"
                                icon={<BadgeIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailIcon />}
                                value={email}
                                onChange={setEmail}
                                helperText="Será su nombre de usuario para acceder."
                                type="mail"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Teléfono Fijo"
                                icon={<LocalPhoneIcon />}
                                value={tel_fijo}
                                onChange={setTelFijo}
                                type="tel"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                value={tel_whatsapp}
                                onChange={setTelWhatsapp}
                                type="tel"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena1}
                                onChange={setContrasena1}
                                type="password"
                                helperText="Crear una contraseña para el usuario. Luego podrá modificarla."
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Repetir Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena2}
                                onChange={setContrasena2}
                                type="password"
                                helperText="Repetir la contraseña."
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}