import { tiendasApi } from '../../api'

export const pedidosPdfApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getTipos: builder.mutation({
            query: () => ({
                url: '/ventas/pedidos-pdf',
                method: 'GET',
            })
        }),
        getBySlug: builder.mutation({
            query: (slug) => ({
                url: '/ventas/pedidos-pdf/' + slug,
                method: 'GET',
            })
        }),
        create: builder.mutation({
            query: ({slug, body}) => ({
                url: '/ventas/pedidos-pdf',
                method: 'POST',
                body: body,
            })
        }),
        updateBySlug: builder.mutation({
            query: ({slug, body}) => ({
                url: '/ventas/pedidos-pdf/' + slug,
                method: 'PUT',
                body: body,
            })
        }),
    })
})

export const {
    useGetTiposMutation,
    useGetBySlugMutation,
    useCreateMutation,
    useUpdateBySlugMutation,
} = pedidosPdfApiSlice