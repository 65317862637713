import { React, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useGetTiposMutation } from '../../../../store/api/endpoints/pedidos-pdf/pedidosPdfApiSlice'

import Loading from '../../../elementos/Loading'

import '../../../../assets/css/vistaFormulario.css'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import AddIcon from '@mui/icons-material/Add'

export default function ConfigReportesPdfPedidos() {

    const urlParams = useParams()

    const [
        getRegistros,
        {
            data: responseGetRegistros,
            isUninitialized: isUninitializedGetRegistros,
            isLoading: isLoadingGetRegistros,
            isSuccess: isSuccessGetRegistros,
            isError: isErrorGetRegistros,
        }
    ] = useGetTiposMutation()

    useEffect(() => {
        getRegistros()
    }, [])

    if (isUninitializedGetRegistros || isLoadingGetRegistros) return (<Loading />)

    if (isSuccessGetRegistros) return (
        <>
            <h4 style={{ marginBottom: '0px' }}>Pedidos</h4>
            <List style={{ width: '100%' }}>
                {
                    responseGetRegistros.map(item => {
                        return (
                            <ListItem dense disablePadding key={item.slug}>
                                <Link to={"/" + urlParams.dominio + "/config/reportes-pdf/pedidos/" + item.slug} style={{ width: '100%' }}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <PictureAsPdfIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={item.nombre} />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        )
                    })
                }
                <ListItem dense disablePadding >
                    <Link to={"/" + urlParams.dominio + "/config/reportes-pdf/pedidos/"} style={{ width: '100%' }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <AddIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Nuevo"} />
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
        </>
    )

}