import { tiendasApi } from '../api'

export const rutasApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        createArchivo: builder.mutation({
            query(data){
                const { carpeta, body } = data
                return {
                    url: '/rutas/' + carpeta,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useCreateArchivoMutation,
} = rutasApiSlice