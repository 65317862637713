import { useState, useEffect } from 'react'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';


const delay = 0.4

export default function Autocompletar(props) {

  const [value, setValue] = useState("")

  useEffect(() => {

    let timer1 = setTimeout(() => {

      props.getRegistrosOnKeyPress({ textsearch: props.valueInput })

    }, delay * 1000)

    return () => {
      clearTimeout(timer1)
    }

  }, [props.valueInput])

  const getDefaultValue = () => {

    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i].value == props.value) {
        return props.options[i];
      }
    }

  }

  return (
    <Autocomplete
      size={(props.size ? props.size : '')}
      sx={{
        marginTop: '15px'
      }}
      disablePortal
      noOptionsText={props.noOptionsText}
      options={props.options}

      getOptionLabel={(option) => option.label}
      renderOption={props.renderOption}
      filterOptions={(x) => x} //para asincronico
      clearText="Limpiar"

      isOptionEqualToValue={(option, value) => option.value === value.value}

      renderInput={(params) => <TextField
        {...params}
        label={props.label}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position="start">
              {props.icon}
            </InputAdornment>
          )
        }}
      />}

      defaultValue={(props.value > 0 ? getDefaultValue() : undefined)}

      onChange={(event, newValue) => {
        setValue(newValue)
        if (newValue) {
          props.onChange(newValue.value)
        } else {
          props.onChange(0)
        }
      }}

      onInputChange={(event, newInputValue) => {
        props.onChangeInput(newInputValue)
      }}
    />
  )

}


/*
  Ejemplo

  <Autocompletar
      size="small"
      label="Cliente"
      noOptionsText="No se encontraron Clientes"
      options={optionsClientes}
      value={props.cliente}
      onChange={props.handleChangeCliente}
      renderOption={(props, option) => (
          <Box component="li" {...props}>
              {option.label}
              <br />{option.documento}
          </Box>
      )}
      getRegistrosOnKeyPress={getClientes}
  />

*/


