import { React, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'


import { useGetClienteLoginsMutation } from '../../../../store/api/endpoints/clientes/clientesLoginsApiSlice'

import { GetClienteNombre } from '../clientes/GetClienteNombre'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import ButtonIcon from '../../../elementos/ButtonIcon'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'


import '../../../../assets/css/vistaFormulario.css'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import BadgeIcon from '@mui/icons-material/Badge'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import MailIcon from '@mui/icons-material/Mail'
import EditIcon from '@mui/icons-material/Edit'

export default function ClientesLoginsDetalle() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_fijo, setTelFijo] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetClienteLoginsMutation()

    useEffect(() => {

        getRegistro(urlParams.id)

    }, [])

    useEffect(() => {

        if (isSuccessGetRegistro) {

            setNombre(responseGetRegistro.nombre)
            setEmail(responseGetRegistro.email)
            setTelFijo(responseGetRegistro.tel_fijo)
            setTelWhatsapp(responseGetRegistro.tel_whatsapp)

        }

    }, [
        isSuccessGetRegistro,
        isErrorGetRegistro
    ])

    if (permisosDelUsuario.indexOf(50) === -1) return <SinPermisos />

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return <Loading />

    if (permisosDelUsuario.indexOf(50) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/"}>
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.idCliente}>
                            <GetClienteNombre idCliente={urlParams.idCliente} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente}>
                            Usuarios
                        </Link>
                        <Typography color="text.primary">Detalle de {nombre}</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
                end={
                    <>
                        {
                            permisosDelUsuario.indexOf(52) !== -1 &&
                            <><ButtonIcon
                                variant="outlined"
                                size="small"
                                color="primary"
                                startIcon={<EditIcon />}
                                to={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente + "/" + urlParams.id + "/editar"}
                                marginRight
                            >
                            </ButtonIcon> </>
                        }
                        {
                            permisosDelUsuario.indexOf(53) !== -1 &&
                            <><ConfirmDelete
                                httpDelete={"ventas/clientes-logins/" + urlParams.id}
                                returnUrl={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente}
                                variant="outlined"
                                size="small"
                                soloIcono
                            /></>
                        }
                    </>
                }
            />
            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Nombre y Apellido"
                            icon={<BadgeIcon />}
                            value={nombre}
                            onChange={null}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Email"
                            icon={<MailIcon />}
                            value={email}
                            onChange={null}
                            helperText="Será su nombre de usuario para acceder."
                            type="mail"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Teléfono Fijo"
                            icon={<LocalPhoneIcon />}
                            value={tel_fijo}
                            onChange={null}
                            type="tel"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Input
                            label="Whatsapp"
                            icon={<WhatsAppIcon />}
                            value={tel_whatsapp}
                            onChange={null}
                            type="tel"
                            disabled
                        />
                    </Grid>

                </Grid>
            </Container>


        </>
    )

}