import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { openFeedback } from '../../../store/slices/feedback'

import { useGetPedidosForAutocompleteMutation } from '../../../store/api/endpoints/pedidos/pedidosApiSlice'

import Box from '@mui/material/Box'

import AutocompletarAsync from '../AutocompletarAsync'

import RequestPageIcon from '@mui/icons-material/RequestPage'


export default function AutocompletePedidos({registro, setRegistro, registroInput, setRegistroInput, registrosOptions, setRegistrosOptions}) {

    const dispatch = useDispatch()

    const [
        getRegistros,
        {
            data: responseGetRegistros,
            isUninitialized: isUninitializedGetRegistros,
            isLoading: isLoadingGetRegistros,
            isSuccess: isSuccessGetRegistros,
            isError: isErrorGetRegistros,
            error: errorDataGetRegistros
        }
    ] = useGetPedidosForAutocompleteMutation()

    useEffect(() => {

        if (isSuccessGetRegistros) {

            let tempRegistros = responseGetRegistros.map(item => ({
                label: "N° "+item.id,
                value: item.id,
                textoBusqueda: item.id
            }))

            setRegistrosOptions(tempRegistros)

        }

        if (isErrorGetRegistros) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistros.data.message
            }))
        }

    }, [
        isSuccessGetRegistros,
        isErrorGetRegistros
    ])

    return (
        <AutocompletarAsync
            label="Buscar por N° Pedido"
            noOptionsText="No se encontraron Pedidos"
            icon={<RequestPageIcon />}
            
            options={registrosOptions}

            value={registro}
            onChange={setRegistro}

            valueInput={registroInput}
            onChangeInput={setRegistroInput}

            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.label}
                </Box>
            )}
            getRegistrosOnKeyPress={getRegistros}
        />
    )

}