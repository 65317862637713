import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'
import { useUpdatePagoMetodoMutation, useGetPagoMetodoMutation } from '../../../../store/api/endpoints/config/configPagoMetodoApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../auth/SinPermisos'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Loading from '../../../elementos/Loading'
import ConfirmDelete from '../../../elementos/ConfirmDelete'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import PaymentIcon from '@mui/icons-material/Payment'
import CheckIcon from '@mui/icons-material/Check'

export default function ConfigPagoMetodosEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getPagoMetodo,
        {
            data: responsePagoMetodos,
            isUninitialized: isUninitializedPagoMetodos,
            isLoading: isLoadingPagoMetodos,
            isSuccess: isSuccessPagoMetodos,
            isError: isErrorPagoMetodos,
        }
    ] = useGetPagoMetodoMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdatePagoMetodoMutation()



    const [nombre, setNombre] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
        }

        updateRegistro({ id: urlParams.id, body })


    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/config/pago-metodo")

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        getPagoMetodo(urlParams.id)

    }, [])

    useEffect(() => {

        if (isSuccessPagoMetodos) {
            setNombre(responsePagoMetodos.nombre)
        }

    }, [isSuccessPagoMetodos, isErrorPagoMetodos])

    if (permisosDelUsuario.indexOf(42) === -1) return <SinPermisos />

    if (isLoadingPagoMetodos || isUninitializedPagoMetodos || isLoadingUpdateRegistro) return <Loading />

    if (permisosDelUsuario.indexOf(42) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/config/pago-metodo"}>
                            Métodos de Pago
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<PaymentIcon />}
                end={(permisosDelUsuario.indexOf(25) !== -1 ? <ConfirmDelete
                    httpDelete={"config/pago-metodos/" + urlParams.id}
                    returnUrl={"/" + urlParams.dominio + "/config/pago-metodo"}
                    variant="outlined"
                    size="small"
                /> : '')}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<PaymentIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}