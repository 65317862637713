import { React } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../auth/SinPermisos'
import Volver from '../../../elementos/Volver'

import HeaderVista from '../../../elementos/HeaderVista'


import '../../../../assets/css/vistaFormulario.css'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

export default function ProveedorNuevo() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    if (permisosDelUsuario.indexOf(34) === -1) return <SinPermisos />

    if (permisosDelUsuario.indexOf(34) !== -1) return (
        <>
            <HeaderVista
                start={<Volver to={"/" + urlParams.dominio + "/compras/proveedores"} />}
                titulo="Proveedores / Crear"
                icon={<PeopleAltIcon />}
            />
            Formulario
        </>
    )

}