import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetArtCategoriasForSelectMutation } from '../../../../store/api/endpoints/articulos/artcatApiSlice'
import { useGetArtSubcategoriasForSelectMutation } from '../../../../store/api/endpoints/articulos/artsubcatApiSlice'
import { useCreateArticuloMutation } from '../../../../store/api/endpoints/articulos/articulosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import TextEditor from '../../../elementos/TextEditor'
import CropImage from '../../../elementos/CropImage'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import BakeryDiningIcon from '@mui/icons-material/BakeryDining'


export default function ArticuloNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)

    const [
        getCategorias,
        {
            data: responseGetCategorias,
            isUninitialized: isUninitializedGetCategorias,
            isLoading: isLoadingGetCategorias,
            isSuccess: isSuccessGetCategorias,
            isError: isErrorGetCategorias,
        }
    ] = useGetArtCategoriasForSelectMutation()

    const [
        getSubcategorias,
        {
            data: responseGetSubcategorias,
            isUninitialized: isUninitializedGetSubcategorias,
            isLoading: isLoadingGetSubcategorias,
            isSuccess: isSuccessGetSubcategorias,
            isError: isErrorGetSubcategorias,
        }
    ] = useGetArtSubcategoriasForSelectMutation()

    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreateArticuloMutation()


    const [categoriasOptions, setCategoriasOptions] = useState([])
    const [subcategoriasOptions, setSubcategoriasOptions] = useState([])
    const [nombre, setNombre] = useState('')
    const [codigo, setCodigo] = useState('')
    const [categoria, setCategoria] = useState('')
    const [subcategoria, setSubcategoria] = useState('')
    const [precio, setPrecio] = useState(['', '', '', '', '', '', '', '', ''])
    const [imgPrincipal, setImgPrincipal] = useState('')
    const [descripcion, setDescripcion] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            titulo: nombre,
            codigo,
            categoria,
            subcategoria,
            precio,
            descripcion,
            img_principal: imgPrincipal,
        }

        createRegistro(body)


    }

    const handleChangePrecio = (e, index) => {

        let precioNuevo = []
        for (let i = 0; i < 9; i++) {
            if (index === i) {
                precioNuevo.push(e)
            } else {
                precioNuevo.push(precio[i])
            }
        }
        setPrecio(precioNuevo)
    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/articulos")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    useEffect(() => {

        if (configArticulos.configArticulos?.categorias?.activo) getCategorias()

    }, [])

    useEffect(() => {

        if (isSuccessGetCategorias) {
            setCategoriasOptions(responseGetCategorias)
            if (configArticulos.configArticulos?.subcategorias?.activo) getSubcategorias({ idCategoria: (categoria != '' ? categoria : '0') })
        }

    }, [isSuccessGetCategorias, isErrorGetCategorias])

    useEffect(() => {

        if (isSuccessGetSubcategorias) {
            setSubcategoriasOptions(responseGetSubcategorias)
        }

    }, [isSuccessGetSubcategorias, isErrorGetSubcategorias])

    useEffect(() => {

        if (categoria != '' && configArticulos.configArticulos?.subcategorias?.activo) {
            getSubcategorias({ idCategoria: (categoria != '' ? categoria : '0') })
        }

    }, [categoria])

    if (permisosDelUsuario.indexOf(23) === -1) return <SinPermisos />

    if (
        isLoadingCreaRegistro ||
        (
            configArticulos.configArticulos?.categorias?.activo &&
            (
                isUninitializedGetCategorias ||
                isLoadingGetCategorias
            )
        )
        ||
        (
            configArticulos.configArticulos?.subcategorias?.activo &&
            (
                isLoadingGetSubcategorias ||
                isUninitializedGetSubcategorias
            )
        )
    ) return <Loading />


    var columnasPorImg = 12
    var columnasPorImgHijos = 3
    if (configArticulos.configArticulos?.img_principal.activo) {
        columnasPorImg = 8
        columnasPorImgHijos = 6
    }

    if (permisosDelUsuario.indexOf(23) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/articulos"}>
                            Artículos
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<BakeryDiningIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>

                        {
                            configArticulos.configArticulos?.img_principal.activo &&
                            <Grid item xs={12} md={4}>
                                <CropImage
                                    imagen={imgPrincipal}
                                    setRoute={setImgPrincipal}
                                    maxSize="1000000"
                                    width={configArticulos.configArticulos?.img_principal?.width}
                                    height={configArticulos.configArticulos?.img_principal?.height}
                                    ruta="articulos"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={columnasPorImg}>
                            <Grid container spacing={2}>
                                {
                                    configArticulos.configArticulos?.categorias.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Select
                                            label="Categoria"
                                            value={categoria}
                                            options={categoriasOptions.map((item) => ({
                                                value: item.id,
                                                label: item.nombre
                                            }))}
                                            onChange={setCategoria}
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                }
                                {
                                    configArticulos.configArticulos?.subcategorias.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Select
                                            label="Subcategoria"
                                            value={subcategoria}
                                            options={subcategoriasOptions.map((item) => ({
                                                value: item.id,
                                                label: item.nombre
                                            }))}
                                            onChange={setSubcategoria}
                                            type="text"
                                            disabled={categoria == ''}
                                            required
                                        />
                                    </Grid>
                                }
                                {
                                    configArticulos.configArticulos?.codigo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Código"
                                            icon={<QrCode2Icon />}
                                            value={codigo}
                                            onChange={setCodigo}
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Nombre"
                                        icon={<BakeryDiningIcon />}
                                        value={nombre}
                                        onChange={setNombre}
                                        type="text"
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    {
                        configArticulos.configArticulos?.descripcion &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextEditor textoHtml={descripcion} setTextoHtml={setDescripcion} />
                            </Grid>
                        </Grid>
                    }
                    <br />
                    {
                        configArticulos.configArticulos?.precio.activo &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h3>Precios y Listas</h3>
                            </Grid>
                            {
                                configArticulos.configArticulos?.precio &&
                                configArticulos.configArticulos?.precio.nombres.map((nombre, index) => (
                                    <Grid item xs={12} md={3} key={index}>
                                        <Input
                                            label={nombre}
                                            icon={<AttachMoneyIcon />}
                                            value={precio[index]}
                                            onChange={(e) => handleChangePrecio(e, index)}
                                            type="tel"
                                            restricciones={['soloNumerosDecimales']}
                                            required
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }

                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}