import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { getStateConfigUsuarios } from '../../../store/slices/configUsuarios'
import { useGetUsuariosMutation } from '../../../store/api/endpoints/usuarios/usuarios'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'
import TablaPaginada from '../../elementos/TablaPaginada'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'


export default function Usuarios() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configUsuarios = useSelector(getStateConfigUsuarios)

    if (permisosDelUsuario.indexOf(26) == -1) return (<p>No tienes acceso a visualizar los usuarios.</p>)

    const thead = []
    thead.push({ label: "Activo", align: "center" })
    if (configUsuarios.configUsuarios?.img_principal?.activo) thead.push({ label: "Imagen", align: "center" })
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Usuario", align: "left" })
    if (configUsuarios.configUsuarios?.posicion?.activo) thead.push({ label: "Posición", align: "left" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "estado", valor: "activo" })
    if (configUsuarios.configUsuarios?.img_principal?.activo) tbody.push({ tipo: "img", valor: "img_principal" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "usuario" })
    if (configUsuarios.configUsuarios?.posicion?.activo) tbody.push({ tipo: "texto", valor: "posicion" })

    let botones = []
    if (permisosDelUsuario.indexOf(28) > -1) botones.push({ color: "primary", icono: <EditIcon />, ruta: "/" + urlParams.dominio + '/usuarios/' })
    tbody.push({ tipo: "botones", valor: botones })

    if (permisosDelUsuario.indexOf(26) > -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Usuarios</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
                end={(permisosDelUsuario.indexOf(27) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/usuarios/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetUsuariosMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}

