import { forwardRef } from 'react'
import { NumericFormat } from 'react-number-format'
import parse from 'html-react-parser'

import { momentF } from '../../../elementos/Moment'
import { numberFormatF } from '../../../elementos/numberFormatF'

import '../../../../assets/css/PDF.css'

export const ComponentToPrint = forwardRef((props, ref) => {

    const encabezado = props.props.response.encabezado
    const articulos = props.props.response.articulos
    const marca = props.props.config.config.marca
    const rutas = props.props.config.config.rutas

    const total = articulos.map(item => { return item.total }).reduce((partialSum, a) => partialSum + a, 0)

    var html = props.props.pdf.pdf.body

    var html_articulos = ''
    for(let i=0 ; i<articulos.length ; i++){

        let temp = props.props.pdf.pdf.articulos
        temp = temp.replaceAll("${ARTICULO_CANTIDAD}", numberFormatF(articulos[i].cantidad))
        temp = temp.replaceAll("${ARTICULO_TITULO}", articulos[i].titulo)
        temp = temp.replaceAll("${ARTICULO_PRECIOUNITARIO}", numberFormatF(articulos[i].unitario))
        temp = temp.replaceAll("${ARTICULO_SUBTOTAL}", numberFormatF(articulos[i].total))

        html_articulos += temp
    }
    html = html.replaceAll("${ARTICULOS}", html_articulos)
    html = html.replaceAll("${LOGO}", rutas.logo)
    html = html.replaceAll("${PEDIDO_NUMERO}", encabezado.id)
    html = html.replaceAll("${PEDIDO_FECHA}", momentF(encabezado.fecha, "DD-MM-YYYY"))
    html = html.replaceAll("${PEDIDO_DIRECCION}", encabezado.direccion)
    html = html.replaceAll("${PEDIDO_LOCALIDAD}", encabezado.localidad)
    html = html.replaceAll("${PEDIDO_CODIGOPOSTAL}", encabezado.codigo_postal)
    html = html.replaceAll("${PEDIDO_PROVINCIA}", encabezado.provincia)
    html = html.replaceAll("${PEDIDO_PAIS}", encabezado.pais)
    html = html.replaceAll("${PEDIDO_ENVIO}", encabezado.envioMetodo)
    html = html.replaceAll("${PEDIDO_VENDEDOR}", (encabezado.vendedor ? encabezado.vendedor : 'Enviado por Cliente'))
    html = html.replaceAll("${CLIENTE_NOMBRE}", encabezado.clienteNombre)
    html = html.replaceAll("${CLIENTE_EMAIL}", encabezado.clienteEmail)
    html = html.replaceAll("${CLIENTE_WHATSAPP}", encabezado.clienteWhatsapp)
    html = html.replaceAll("${CLIENTE_TELFIJO}", encabezado.clienteTelFijo)
    html = html.replaceAll("${CLIENTE_DOCUMENTO}", encabezado.clienteDocumento)
    html = html.replaceAll("${PEDIDO_OBSERVACION}", encabezado.observacion)
    html = html.replaceAll("${PEDIDO_TOTAL}", numberFormatF(total))
    
    html = html.replaceAll("${MARCA_NOMBRE}", marca.marca)
    html = html.replaceAll("${MARCA_TELFIJO}", marca.tel_fijo)
    html = html.replaceAll("${MARCA_WHATSAPP}", marca.tel_fijo)
    html = html.replaceAll("${MARCA_DIRECCION}", marca.tel_fijo)
    html = html.replaceAll("${MARCA_MAPA}", marca.tel_fijo)
    html = html.replaceAll("${MARCA_EMAIL}", marca.email)

    


    return (<div ref={ref} id="pdf-contenedor">{parse(html)}</div>)

    
})