import { tiendasApi } from '../../api'

export const configEstadosPedidoApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getEstadosPedidoPaginated: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/estados-pedidos/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getEstadosPedidoAll: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/estados-pedidos/all',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getEstadosPedido: builder.mutation({
            query: (id) => ({
                url: '/config/estados-pedidos/' + id,
                method: 'GET',
            })
        }),
        createEstadosPedido: builder.mutation({
            query: (body) => ({
                url: '/config/estados-pedidos',
                method: 'POST',
                body: body,
            })
        }),
        updateEstadosPedido: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/config/estados-pedidos/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeEstadosPedido: builder.mutation({
            query: (id) => ({
                url: '/config/estados-pedidos/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetEstadosPedidoPaginatedMutation,
    useGetEstadosPedidoAllMutation,
    useGetEstadosPedidoMutation,
    useCreateEstadosPedidoMutation,
    useUpdateEstadosPedidoMutation,
    useRemoveEstadosPedidoMutation,
} = configEstadosPedidoApiSlice