import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'
import { useCreateEstadosPedidoMutation, useGetEstadosPedidoAllMutation } from '../../../../store/api/endpoints/config/configEstadosPedidoApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import InputColorPaleta from '../../../elementos/InputColorPaleta'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import CheckIcon from '@mui/icons-material/Check'

export default function ConfigEstadosPedidoNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreateEstadosPedidoMutation()

    const [
        getEstadosForSelect,
        {
            data: responseGetEstadosForSelect,
            isUninitialized: isUninitializedGetEstadosForSelect,
            isLoading: isLoadingGetEstadosForSelect,
            isSuccess: isSuccessGetEstadosForSelect,
            isError: isErrorGetEstadosForSelect,
        }
    ] = useGetEstadosPedidoAllMutation()


    const [nombre, setNombre] = useState('')
    const [color_fondo, setColorFondo] = useState('')
    const [color_texto, setColorTexto] = useState('')
    const [estado_previo, setEstadoPrevio] = useState('')
    const [estadosForSelect, setEstadosForSelect] = useState([])


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            color_fondo,
            color_texto,
            estado_previo,
        }

        createRegistro(body)


    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/config/estados-pedidos")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    useEffect(() => {

        getEstadosForSelect()

    }, [])

    useEffect(() => {

        if (isSuccessGetEstadosForSelect) {
            setEstadosForSelect(responseGetEstadosForSelect)
        }

    }, [isSuccessGetEstadosForSelect, isErrorGetEstadosForSelect])

    if (permisosDelUsuario.indexOf(42) === -1) return <SinPermisos />

    if (isLoadingCreaRegistro || isLoadingGetEstadosForSelect || isUninitializedGetEstadosForSelect) return <Loading />

    if (permisosDelUsuario.indexOf(42) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/config/estados-pedidos"}>
                            Estados de Pedidos
                        </Link>
                        <Typography color="text.primary">Crear</Typography>
                    </Breadcrumbs>
                }
                icon={<AccessTimeIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre del Estado"
                                icon={<AccessTimeIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                icon={<AccessTimeFilledIcon />}
                                label="Estado Anterior"
                                value={estado_previo}
                                options={estadosForSelect.map((item) => ({
                                    value: item.id,
                                    label: item.nombre
                                }))}
                                onChange={setEstadoPrevio}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <InputColorPaleta
                                label="Color Fondo"
                                value={color_fondo}
                                onChange={setColorFondo}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputColorPaleta
                                label="Color Texto"
                                value={color_texto}
                                onChange={setColorTexto}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <p align="center">Ejemplo de colores</p>
                            <div style={{ backgroundColor: color_fondo, color: color_texto, padding: '10px', textAlign: 'center' }}>
                                {nombre}
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}