import { createSlice } from "@reduxjs/toolkit"


export const configArticulosSlice = createSlice({
    name: 'configArticulos',
    initialState: {
        
    },
    reducers: {
        setStateConfigArticulos: (state, action) => {
            state.configArticulos = action.payload
        },
    }
})

export const { setStateConfigArticulos } = configArticulosSlice.actions

export const getStateConfigArticulos = (state) => state.configArticulos

