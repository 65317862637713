import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Loading from '../../elementos/Loading'
import SinPermisos from '../auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetConfigVentasMutation, useUpdateConfigVentasMutation } from '../../../store/api/endpoints/config/configVentasApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import SwitchInput from '../../elementos/SwitchInput'
import Input from '../../elementos/Input'

import '../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider } from '@mui/material'

export default function ConfigVentas() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetConfigVentasMutation()


    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateConfigVentasMutation()


    const [activo, setActivo] = useState(false)
    const [solo_clientes, setSoloClientes] = useState(false)
    const [pagoPlazoActivo, setPagoPlazoActivo] = useState(false)
    const [pagoPlazoEligeCliente, setPagoPlazoEligeCliente] = useState(false)
    const [pagoMetodoActivo, setPagoMetodoActivo] = useState(false)
    const [pagoMetodoEligeCliente, setPagoMetodoEligeCliente] = useState(false)
    const [envioMetodoActivo, setEnvioMetodoActivo] = useState(false)
    const [envioMetodoEligeCliente, setEnvioMetodoEligeCliente] = useState(false)

    const handleFalse = () => {
        return false
    }
    const handleChangeActivo = () => {
        if (activo) {
            setActivo(false)
            setSoloClientes(false)
        } else {
            setActivo(true)
        }
    }
    const handleChangeSetPagoPlazoActivo = () => {
        if (pagoPlazoActivo) {
            setPagoPlazoActivo(false)
            setPagoPlazoEligeCliente(false)
        } else {
            setPagoPlazoActivo(true)
        }
    }
    const handleChangeSetPagoMetodoActivo = () => {
        if (pagoMetodoActivo) {
            setPagoMetodoActivo(false)
            setPagoMetodoEligeCliente(false)
        } else {
            setPagoMetodoActivo(true)
        }
    }
    const handleChangeSetEnvioMetodoActivo = () => {
        if (envioMetodoActivo) {
            setEnvioMetodoActivo(false)
            setEnvioMetodoEligeCliente(false)
        } else {
            setEnvioMetodoActivo(true)
        }
    }
    


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            activo: activo,
            solo_clientes: solo_clientes,
            pago_plazo: {
                activo: pagoPlazoActivo,
                elige_cliente: pagoPlazoEligeCliente,
            },
            pago_metodo: {
                activo: pagoMetodoActivo,
                elige_cliente: pagoMetodoEligeCliente,
            },
            envio_metodo: {
                activo: envioMetodoActivo,
                elige_cliente: envioMetodoEligeCliente,
            },
        }

        updateRegistro(body)


    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        getConfig()

    }, [])

    useEffect(() => {

        if (isSuccessGetConfig && responseGetConfig) {
            setActivo(responseGetConfig.activo)
            setSoloClientes(responseGetConfig.solo_clientes)
            setPagoPlazoActivo(responseGetConfig.pago_plazo.activo)
            setPagoPlazoEligeCliente(responseGetConfig.pago_plazo.elige_cliente)
            setPagoMetodoActivo(responseGetConfig.pago_metodo.activo)
            setPagoMetodoEligeCliente(responseGetConfig.pago_metodo.elige_cliente)
            setEnvioMetodoActivo(responseGetConfig.envio_metodo.activo)
            setEnvioMetodoEligeCliente(responseGetConfig.envio_metodo.elige_cliente)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])


    if (permisosDelUsuario.indexOf(45) === -1) return <SinPermisos />

    if (
        isLoadingGetConfig ||
        isUninitializedGetConfig ||
        isLoadingUpdateRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(45) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="#">
                            Config.
                        </Link>
                        <Typography color="text.primary">Ventas</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>1. General</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={activo}
                                onChange={handleChangeActivo}
                                helperText="Indica si están activos las ventas/pedidos."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                        <SwitchInput
                                label="Sólo Clientes"
                                value={solo_clientes}
                                onChange={() => setSoloClientes(!solo_clientes)}
                                helperText="Indica si sólo los clientes pueden generar pedidos o cualquiera."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>2. Método de Envío</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={envioMetodoActivo}
                                onChange={handleChangeSetEnvioMetodoActivo}
                                helperText="Indica si las ventas tendrán un método de envío."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Elige Cliente"
                                value={envioMetodoEligeCliente}
                                onChange={(envioMetodoActivo ? setEnvioMetodoEligeCliente : handleFalse)}
                                helperText="Indica si el método de envío lo puede elegir el cliente en cada venta o es fijo."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>3. Método de Pago</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={pagoMetodoActivo}
                                onChange={handleChangeSetPagoMetodoActivo}
                                helperText="Indica si las ventas tendrán un método de pago."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Elige Cliente"
                                value={pagoMetodoEligeCliente}
                                onChange={(pagoMetodoActivo ? setPagoMetodoEligeCliente : handleFalse)}
                                helperText="Indica si el método de pago lo puede elegir el cliente en cada venta o es fijo."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>4. Plazo de Pago</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={pagoPlazoActivo}
                                onChange={handleChangeSetPagoPlazoActivo}
                                helperText="Indica si las ventas tendrán un plazo de pago."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Elige Cliente"
                                value={pagoPlazoEligeCliente}
                                onChange={(pagoPlazoActivo ? setPagoPlazoEligeCliente : handleFalse)}
                                helperText="Indica si el plazo de pago lo puede elegir el cliente en cada venta o es fijo."
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}