import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'

import '../../../../assets/css/table.css'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AddIcon from '@mui/icons-material/Add'



const Proveedores = () => {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    if (permisosDelUsuario.indexOf(33) == -1) return (<p>No tienes acceso a visualizar los proveedores.</p>)

    if (permisosDelUsuario.indexOf(33) > -1) return (
        <>
            <HeaderVista
                titulo="Proveedores"
                icon={<PeopleAltIcon />}
                end={(permisosDelUsuario.indexOf(34) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/compras/proveedores/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            Tabla de Proveedores
        </>
    )

}

export default Proveedores