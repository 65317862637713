import { tiendasApi } from '../../api'

export const pedidosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPedidos: builder.mutation({
            query: (headersTemp) => ({
                url: '/ventas/pedidos/paginated2',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPedidoById: builder.mutation({
            query: (idPedido) => ({
                url: '/ventas/pedidos/' + idPedido,
                method: 'GET',
            })
        }),
        getPedidosColumnasForOrderBy: builder.mutation({
            query: (headersTemp) => ({
                url: '/ventas/pedidos/columnas-order-by',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPedidosForAutocomplete: builder.mutation({
            query: (headersTemp) => ({
                url: '/ventas/pedidos/autocomplete',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPedido: builder.mutation({
            query: (id) => ({
                url: '/ventas/pedidos/' + id,
                method: 'GET',
            })
        }),
        getNombrePedido: builder.mutation({
            query: (id) => ({
                url: '/ventas/pedidos/' + id + '/nombre',
                method: 'GET',
            })
        }),
        createPedido: builder.mutation({
            query: (body) => ({
                url: '/ventas/pedidos',
                method: 'POST',
                body: body,
            })
        }),
        updatePedido: builder.mutation({
            query(data) {
                const { id, body } = data
                return {
                    url: '/ventas/pedidos/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removePedido: builder.mutation({
            query: (id) => ({
                url: '/ventas/pedidos/' + id,
                method: 'DELETE',
            })
        }),
        updateCambioDeEstado: builder.mutation({
            query(data) {
                const { id, estado } = data
                return {
                    url: '/ventas/pedidos/cambio-de-estado/' + id,
                    method: 'PUT',
                    body: {
                        estado: estado,
                    },
                }
            }
        }),
    })
})

export const {
    useGetPedidosMutation,
    useGetPedidoByIdMutation,
    useGetPedidosColumnasForOrderByMutation,
    useGetPedidosForAutocompleteMutation,
    useGetPedidoMutation,
    useGetNombrePedidoMutation,
    useCreatePedidoMutation,
    useUpdatePedidoMutation,
    useRemovePedidoMutation,
    useUpdateCambioDeEstadoMutation,
} = pedidosApiSlice