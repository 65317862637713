import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Loading from '../../elementos/Loading'
import SinPermisos from '../auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetConfigArticulosMutation, useUpdateConfigArticulosMutation } from '../../../store/api/endpoints/config/configArticulosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import SwitchInput from '../../elementos/SwitchInput'
import Input from '../../elementos/Input'
import Select from '../../elementos/Select'

import '../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider } from '@mui/material'

export default function ConfigArticulos() {

    const dispatch = useDispatch()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetConfigArticulosMutation()


    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateConfigArticulosMutation()


    const [activo, setActivo] = useState(false)
    const [solo_clientes, setSoloClientes] = useState(false)
    const [descripcion, setDescripcion] = useState(false)
    const [codigo, setCodigo] = useState(false)
    const [img_principalActivo, setImgPrincipalActivo] = useState(false)
    const [img_principalHeight, setImgPrincipalHeight] = useState(0)
    const [img_principalWidth, setImgPrincipalWidth] = useState(0)

    const [precio_listas_activo, setPrecioLIstasActivo] = useState(false)
    const [precio_listas_cantidad, setPrecioListasCantidad] = useState(1)
    const [precio_listas_nombres, setPrecioListasNombres] = useState([])

    const [categoriasActivo, setCategoriasActivo] = useState(false)
    const [cat_img_principalActivo, setCatImgPrincipalActivo] = useState(false)
    const [cat_img_principalHeight, setCatImgPrincipalHeight] = useState(0)
    const [cat_img_principalWidth, setCatImgPrincipalWidth] = useState(0)

    const [subcategoriasActivo, setSubcategoriasActivo] = useState(false)
    const [subcat_img_principalActivo, setSubcatImgPrincipalActivo] = useState(false)
    const [subcat_img_principalHeight, setSubcatImgPrincipalHeight] = useState(0)
    const [subcat_img_principalWidth, setSubcatImgPrincipalWidth] = useState(0)

    const handleListaNombreChange = (index, e) => {

        let nombres = []
        for(let indexEl=0 ; indexEl<precio_listas_cantidad ; indexEl++){
            if (index === indexEl) {
                nombres.push(e)
            } else {
                nombres.push(precio_listas_nombres[indexEl])
            }
        }
        setPrecioListasNombres(nombres)

    }

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            solo_clientes: solo_clientes,
            activo: activo,
            descripcion: descripcion,
            codigo: codigo,
            img_principal: {
                activo: img_principalActivo,
                height: img_principalHeight,
                width: img_principalWidth,
            },
            precio: {
                activo: precio_listas_activo,
                cantidad: precio_listas_cantidad,
                nombres: precio_listas_nombres,
            },
            categorias: {
                activo: categoriasActivo,
                img_principal: {
                    activo: cat_img_principalActivo,
                    height: cat_img_principalHeight,
                    width: cat_img_principalWidth,
                }
            },
            subcategorias: {
                activo: subcategoriasActivo,
                img_principal: {
                    activo: subcat_img_principalActivo,
                    height: subcat_img_principalHeight,
                    width: subcat_img_principalWidth,
                }
            },
        }

        updateRegistro(body)


    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        getConfig()

    }, [])

    useEffect(() => {

        if (isSuccessGetConfig && responseGetConfig.activo) {
            setSoloClientes((responseGetConfig.solo_clientes != undefined ? responseGetConfig.solo_clientes : false))
            setActivo(responseGetConfig.activo)
            setDescripcion(responseGetConfig.descripcion)
            setCodigo(responseGetConfig.codigo)
            setImgPrincipalActivo(responseGetConfig.img_principal.activo)
            setImgPrincipalHeight(responseGetConfig.img_principal.height)
            setImgPrincipalWidth(responseGetConfig.img_principal.width)

            setPrecioLIstasActivo(responseGetConfig.precio.activo)
            setPrecioListasCantidad(responseGetConfig.precio.cantidad)
            if( responseGetConfig.precio.activo ) setPrecioListasNombres(responseGetConfig.precio.nombres)

            setCategoriasActivo(responseGetConfig.categorias.activo)
            setCatImgPrincipalActivo(responseGetConfig.categorias.img_principal.activo)
            setCatImgPrincipalHeight(responseGetConfig.categorias.img_principal.height)
            setCatImgPrincipalWidth(responseGetConfig.categorias.img_principal.width)

            setSubcategoriasActivo(responseGetConfig.subcategorias.activo)
            setSubcatImgPrincipalActivo(responseGetConfig.subcategorias.img_principal.activo)
            setSubcatImgPrincipalHeight(responseGetConfig.subcategorias.img_principal.height)
            setSubcatImgPrincipalWidth(responseGetConfig.subcategorias.img_principal.width)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])


    if (permisosDelUsuario.indexOf(31) === -1) return <SinPermisos />

    if (
        isLoadingGetConfig ||
        isUninitializedGetConfig ||
        isLoadingUpdateRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(31) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="#">
                            Config.
                        </Link>
                        <Typography color="text.primary">Artículos</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Artículos</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setActivo}
                                value={activo}
                                helperText="Indica si la tienda utiliza artículos."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Sólo Clientes"
                                onChange={setSoloClientes}
                                value={solo_clientes}
                                helperText="Indica si los artículos son sólo para clientes o para todos."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Código"
                                onChange={setCodigo}
                                value={(activo ? codigo : false)}
                                helperText="Indica si los artículos tienen un código alfanumérico ej: ABC001."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Descripción"
                                onChange={setDescripcion}
                                value={(activo ? descripcion : false)}
                                helperText="Indica si los artículos tienen una descripción."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setImgPrincipalActivo}
                                value={(activo ? img_principalActivo : false)}
                                helperText="Indica si los artículos tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setImgPrincipalWidth}
                                value={(activo ? img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && img_principalActivo}
                                disabled={!activo || !img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setImgPrincipalHeight}
                                value={(activo ? img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && img_principalActivo}
                                disabled={!activo || !img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Precios y Listas</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Listas de Precios"
                                onChange={setPrecioLIstasActivo}
                                value={(activo ? precio_listas_activo : false)}
                                helperText="Indica si los artículos tienen listas de precios."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Cantidad de Listas"
                                value={precio_listas_cantidad}
                                options={[1,2,3,4,5,6,7,8,9].map((item => ({
                                    label: item+' Listas',
                                    value: item
                                })))}
                                onChange={setPrecioListasCantidad}
                                required={activo && precio_listas_activo}
                                disabled={!activo || !precio_listas_activo}
                            />
                        </Grid>
                    </Grid>
                    {
                        [0,1,2,3,4,5,6,7,8].map((index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={12} md={4}>
                                    <Input
                                        label={"Nombre Lista " + (index * 1 + 1)}
                                        onChange={(e) => handleListaNombreChange(index, e)}
                                        value={precio_listas_nombres[index]}
                                        type="text"
                                        required={activo && precio_listas_activo && (index*1+1)<=precio_listas_cantidad}
                                        disabled={!activo || !precio_listas_activo || (index*1+1)>precio_listas_cantidad}
                                    />
                                </Grid>
                            </Grid>
                        ))
                    }

                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Categorías</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setCategoriasActivo}
                                value={(activo ? categoriasActivo : false)}
                                helperText="Indica si los artículos tienen categoría."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setCatImgPrincipalActivo}
                                value={(activo && categoriasActivo ? cat_img_principalActivo : false)}
                                helperText="Indica si las categorías tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setCatImgPrincipalWidth}
                                value={(activo && categoriasActivo && cat_img_principalActivo ? cat_img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && cat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !cat_img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setCatImgPrincipalHeight}
                                value={(activo && categoriasActivo && cat_img_principalActivo ? cat_img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && cat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !cat_img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Subcategorías</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setSubcategoriasActivo}
                                value={(activo && categoriasActivo ? subcategoriasActivo : false)}
                                helperText="Indica si los artículos tienen categoría."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setSubcatImgPrincipalActivo}
                                value={(activo && categoriasActivo && subcategoriasActivo ? subcat_img_principalActivo : false)}
                                helperText="Indica si las subcategorías tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setSubcatImgPrincipalWidth}
                                value={(activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo ? subcat_img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !subcategoriasActivo || !subcat_img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setSubcatImgPrincipalHeight}
                                value={(activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo ? subcat_img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !subcategoriasActivo || !subcat_img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}