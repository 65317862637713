import { React, useState } from 'react'
import { Link, redirect, useNavigate, useParams } from "react-router-dom"

import { useSelector } from 'react-redux'
import { getStatusSidebar, getStateSidebarData } from '../../store/slices/sidebar'
import { getUsuarioPermisos } from '../../store/slices/auth'

import { SidebarIcono } from './SidebarIconos'

import '../../assets/css/Sidebar.css'

export default function Sidebar() {

    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const statusSidebar = useSelector(getStatusSidebar)
    const sidebarData = useSelector(getStateSidebarData)

    const [submenuEstado, setSubmenuEstado] = useState(
        {
            'ventas': false,
            'compras': false,
            'servicios': false,
            'productos': false,
            'configuracion': false,
        }
    )

    const handleClickMenu = (submenu) => {

        let temp = submenuEstado

        temp[submenu] = !submenuEstado[submenu]

        setSubmenuEstado(temp)

    }

    const handleClickFuncionPrevia = (elemento) => {

        if (elemento.handleClickFuncionPrevia === 0) {
            //Click en pedidos
            localStorage.removeItem("conftable-ventas-pedidos")
        }

        navigate("/" + urlParams.dominio + elemento.path)

    }

    return (
        <div id="sidebar" className={(statusSidebar ? 'open' : 'closed')}>
            <ul>
                {sidebarData.map((val, key) => {

                    if (val.hijos) {

                        return (
                            <Link key={key} to={val.path} onClick={() => handleClickMenu(val.hijos)}>
                                <li
                                    className="sidebar-row"
                                >
                                    <div className="icono">
                                        {<SidebarIcono codigo={val.icono} />}
                                    </div>
                                    <div className="titulo">
                                        {val.titulo}
                                    </div>
                                </li>
                            </Link>
                        )

                    } else if (val.hijoMenu) {

                        if (val.handleClickFuncionPrevia != undefined) {

                            return (
                                <Link onClick={(e) => { e.preventDefault(); handleClickFuncionPrevia(val) }} key={key} className={(submenuEstado[val.hijoMenu] ? 'hijoAbierto' : 'hijoCerrado')}>
                                    <li
                                        className="sidebar-row hijo"
                                    >
                                        <div className="icono">
                                            {<SidebarIcono codigo={val.icono} />}
                                        </div>
                                        <div className="titulo">
                                            {val.titulo}
                                        </div>
                                    </li>
                                </Link>
                            )

                        } else {

                            return (
                                <Link key={key} to={"/" + urlParams.dominio + val.path} className={(submenuEstado[val.hijoMenu] ? 'hijoAbierto' : 'hijoCerrado')}>
                                    <li
                                        className="sidebar-row hijo"
                                    >
                                        <div className="icono">
                                            {<SidebarIcono codigo={val.icono} />}
                                        </div>
                                        <div className="titulo">
                                            {val.titulo}
                                        </div>
                                    </li>
                                </Link>
                            )

                        }



                    } else {

                        return (
                            <Link key={key} to={"/" + urlParams.dominio + val.path}>
                                <li
                                    className="sidebar-row"
                                >
                                    <div className="icono">
                                        {<SidebarIcono codigo={val.icono} />}
                                    </div>
                                    <div className="titulo">
                                        {val.titulo}
                                    </div>
                                </li>
                            </Link>
                        )

                    }
                })}
            </ul>
        </div>
    )

}
