import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { openFeedback } from '../../../store/slices/feedback'

import { useGetForAutocompleteMutation } from '../../../store/api/endpoints/clientes/clientesApiSlice'

import Box from '@mui/material/Box'

import AutocompletarAsync from '../AutocompletarAsync'

import PersonIcon from '@mui/icons-material/Person'


export default function AutocompleteClientes({cliente, setCliente, clienteInput, setClienteInput, clientesOptions, setClientesOptions}) {

    const dispatch = useDispatch()

    const [
        getClientes,
        {
            data: responseGetClientes,
            isUninitialized: isUninitializedGetClientes,
            isLoading: isLoadingGetClientes,
            isSuccess: isSuccessGetClientes,
            isError: isErrorGetClientes,
            error: errorDataGetClientes
        }
    ] = useGetForAutocompleteMutation()

    useEffect(() => {

        if (isSuccessGetClientes) {

            setClientesOptions(responseGetClientes.registros.map(item => ({
                label: item.nombre,
                documento: item.documento,
                value: item.id,
                textoBusqueda: item.nombre + " " + item.documento
            })))

        }

        if (isErrorGetClientes) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetClientes.data.message
            }))
        }

    }, [
        isSuccessGetClientes,
        isErrorGetClientes
    ])

    return (
        <AutocompletarAsync
            label="Buscar por Cliente"
            noOptionsText="No se encontraron Clientes"
            icon={<PersonIcon />}
            
            options={clientesOptions}

            value={cliente}
            onChange={setCliente}

            valueInput={clienteInput}
            onChangeInput={setClienteInput}

            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.label}
                    <br />{option.documento}
                </Box>
            )}
            getRegistrosOnKeyPress={getClientes}
        />
    )

}