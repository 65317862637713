import { tiendasApi } from '../../api'

export const servsubcatApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getServSubcategorias: builder.mutation({
            query: (headersTemp) => ({
                url: '/servsubcat/paginated/' + headersTemp.filtroCategoria,
                method: 'GET',
                headers: {
                    page: headersTemp.page,
                    textsearch: headersTemp.textsearch,
                },
            })
        }),
        getServSubcategoriasForSelect: builder.mutation({
            query(data){
                const { idCategoria } = data
                return {
                    url: '/servsubcat/all/' + idCategoria,
                    method: 'GET',
                }
            }
        }),
        getServSubcategoria: builder.mutation({
            query: (id) => ({
                url: '/servsubcat/' + id,
                method: 'GET',
            })
        }),
        createServSubcategoria: builder.mutation({
            query: (body) => ({
                url: '/servsubcat',
                method: 'POST',
                body: body,
            })
        }),
        updateServSubcategoria: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/servsubcat/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeServSubcategoria: builder.mutation({
            query: (id) => ({
                url: '/servsubcat/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetServSubcategoriasMutation,
    useGetServSubcategoriasForSelectMutation,
    useGetServSubcategoriaMutation,
    useCreateServSubcategoriaMutation,
    useUpdateServSubcategoriaMutation,
    useRemoveServSubcategoriaMutation,
} = servsubcatApiSlice