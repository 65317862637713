import { React, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { getStateConfigClientes } from '../../../../store/slices/configClientes'
import { getStateConfigVentas } from '../../../../store/slices/configVentas'
import { useGetClienteMutation } from '../../../../store/api/endpoints/clientes/clientesApiSlice'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ClientePedidos from './ClientePedidos'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import DisplayInfoUl from '../../../elementos/DisplayInfoUl'
import Loading from '../../../elementos/Loading'
import ConfirmDelete from '../../../elementos/ConfirmDelete'

import EditIcon from '@mui/icons-material/Edit'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import GroupIcon from '@mui/icons-material/Group'
import FmdGoodIcon from '@mui/icons-material/FmdGood';


export default function ClienteDetalle() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)
    const configClientes = useSelector(getStateConfigClientes)
    const configVentas = useSelector(getStateConfigVentas)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetClienteMutation()

    useEffect(() => {

        getRegistro(urlParams.id)

    }, [])

    if (permisosDelUsuario.indexOf(38) == -1) return (<p>No tienes acceso a visualizar los clientes.</p>)

    if (
        isLoadingGetRegistro ||
        isUninitializedGetRegistro
    ) return <Loading />


    var datosDeContacto = []
    if (configClientes.configClientes.telefono.activo) datosDeContacto.push({ index: 'Tel. Fijo', value: responseGetRegistro.telefono })
    if (configClientes.configClientes.whatsapp.activo) datosDeContacto.push({ index: 'Tel. Whatsapp', value: responseGetRegistro.whatsapp })
    if (configClientes.configClientes.email.activo) datosDeContacto.push({ index: 'Email', value: responseGetRegistro.email })
    if (configClientes.configClientes.web.activo) datosDeContacto.push({ index: 'Web', value: responseGetRegistro.web })

    var datosFacturacion = []
    if (configClientes.configClientes.tipo_doc.activo) datosFacturacion.push({ index: 'Tipo Doc', value: responseGetRegistro.tipo_doc_desc })
    if (configClientes.configClientes.documento.activo) datosFacturacion.push({ index: 'N° Doc', value: responseGetRegistro.documento })
    if (configClientes.configClientes.razon_social.activo) datosFacturacion.push({ index: 'Razón Social', value: responseGetRegistro.razon_social })
    if (configClientes.configClientes.tipo_doc.activo) datosFacturacion.push({ index: 'Cond. IVA', value: responseGetRegistro.condicion_iva_desc })
    if (configClientes.configClientes.direccion.activo) datosFacturacion.push({ index: 'Dirección', value: responseGetRegistro.direccion })
    if (configClientes.configClientes.direccion.activo) datosFacturacion.push({ index: 'Localidad', value: responseGetRegistro.localidad })
    if (configClientes.configClientes.direccion.activo) datosFacturacion.push({ index: 'Código Postal', value: responseGetRegistro.codigo_postal })
    if (configClientes.configClientes.direccion.activo) datosFacturacion.push({ index: 'Provincia', value: responseGetRegistro.provincia })
    if (configClientes.configClientes.direccion.activo) datosFacturacion.push({ index: 'País', value: responseGetRegistro.pais })

    var valoresPredeterminados = []
    if (configArticulos?.configArticulos?.precio?.activo) valoresPredeterminados.push({ index: 'Lista de Precios', value: configArticulos.configArticulos.precio.nombres[responseGetRegistro.lista_precios - 1] })
    if (configVentas?.configVentas?.pago_metodo?.activo) valoresPredeterminados.push({ index: 'Método de Pago', value: responseGetRegistro.pagoMetodoNombre })
    if (configVentas?.configVentas?.pago_plazo?.activo) valoresPredeterminados.push({ index: 'Plazo de pago', value: responseGetRegistro.pagoPlazoNombre })
    if (configVentas?.configVentas?.envio_metodo?.activo) valoresPredeterminados.push({ index: 'Método de Envío', value: responseGetRegistro.envioMetodoNombre })
    valoresPredeterminados.push({ index: 'Ult.Venta', value: responseGetRegistro.ultima_venta })


    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes"}>
                            Clientes
                        </Link>
                        <Typography color="text.primary">{responseGetRegistro.nombre}</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
                end={
                    <>
                        {(permisosDelUsuario.indexOf(54) > -1 ?
                            <><ButtonIcon
                                variant="outlined"
                                size="small"
                                color="dark"
                                startIcon={<FmdGoodIcon />}
                                to={"/" + urlParams.dominio + "/ventas/clientes-direcciones/" + urlParams.id}
                            ></ButtonIcon> </> : '')}
                        {(permisosDelUsuario.indexOf(50) > -1 ?
                            <><ButtonIcon
                                variant="outlined"
                                size="small"
                                color="dark"
                                startIcon={<GroupIcon />}
                                to={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.id}
                            ></ButtonIcon> </> : '')}
                        {(permisosDelUsuario.indexOf(40) > -1 ?
                            <><ButtonIcon
                                variant="outlined"
                                size="small"
                                color="primary"
                                startIcon={<EditIcon />}
                                to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.id + "/editar"}
                            ></ButtonIcon> </> : '')}
                        {(permisosDelUsuario.indexOf(40) > -1 && permisosDelUsuario.indexOf(41) > -1 ? " " : "")}
                        {(permisosDelUsuario.indexOf(41) > -1 ?
                            <ConfirmDelete
                                soloIcono
                                httpDelete={"ventas/clientes/" + urlParams.id}
                                returnUrl={"/" + urlParams.dominio + "/ventas/clientes"}
                                variant="outlined"
                                size="small"
                            /> : '')}
                    </>
                }
            />
            <Container component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} >
                        <DisplayInfoUl
                            titulo="Info de Cliente"
                            data={
                                [
                                    { index: 'Nombre', value: responseGetRegistro.nombre },
                                    { index: 'Vendedor', value: responseGetRegistro.vendedorNombre },
                                ]
                            }
                        />
                        <DisplayInfoUl
                            titulo="Datos de Contacto"
                            data={datosDeContacto}
                        />
                    </Grid>

                    {
                        datosFacturacion.length > 0 &&
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Datos de Facturación"
                                data={datosFacturacion}
                            />
                        </Grid>
                    }

                    {
                        configClientes.configClientes.comentarios.activo &&
                        <Grid item xs={12} md={4} >
                            <DisplayInfoUl
                                titulo="Comentarios"
                                data={
                                    [
                                        { index: 'Comentarios', value: <div dangerouslySetInnerHTML={{ __html: responseGetRegistro.comentarios }}></div> },
                                    ]
                                }
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={4} >
                        <DisplayInfoUl
                            titulo="Valores Predeterminados"
                            data={valoresPredeterminados}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <br />
                        <h4 align="center">Historial de Pedidos</h4>
                        <ClientePedidos
                            idCliente={urlParams.id}
                        />
                    </Grid>
                </Grid>

            </Container>
        </>
    )

}

