import { createSlice } from "@reduxjs/toolkit"


export const configUsuariosSlice = createSlice({
    name: 'configUsuarios',
    initialState: {
        
    },
    reducers: {
        setStateConfigUsuarios: (state, action) => {
            state.configUsuarios = action.payload
        },
    }
})

export const { setStateConfigUsuarios } = configUsuariosSlice.actions

export const getStateConfigUsuarios = (state) => state.configUsuarios

