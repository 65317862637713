import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigServicios } from '../../../../store/slices/configServicios'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetServCategoriasForSelectMutation } from '../../../../store/api/endpoints/servicios/servcatApiSlice'
import { useGetServSubcategoriasForSelectMutation } from '../../../../store/api/endpoints/servicios/servsubcatApiSlice'
import { useCreateServicioMutation } from '../../../../store/api/endpoints/servicios/serviciosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import TextEditor from '../../../elementos/TextEditor'
import CropImage from '../../../elementos/CropImage'

import '../../../../assets/css/vistaFormulario.css'

import LocalCafeIcon from '@mui/icons-material/LocalCafe'
import CheckIcon from '@mui/icons-material/Check'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

export default function ServicioNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configServicios = useSelector(getStateConfigServicios)

    const [
        getCategorias,
        {
            data: responseGetCategorias,
            isUninitialized: isUninitializedGetCategorias,
            isLoading: isLoadingGetCategorias,
            isSuccess: isSuccessGetCategorias,
            isError: isErrorGetCategorias,
        }
    ] = useGetServCategoriasForSelectMutation()

    const [
        getSubcategorias,
        {
            data: responseGetSubcategorias,
            isUninitialized: isUninitializedGetSubcategorias,
            isLoading: isLoadingGetSubcategorias,
            isSuccess: isSuccessGetSubcategorias,
            isError: isErrorGetSubcategorias,
        }
    ] = useGetServSubcategoriasForSelectMutation()

    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreateServicioMutation()


    const [categoriasOptions, setCategoriasOptions] = useState([])
    const [subcategoriasOptions, setSubcategoriasOptions] = useState([])
    const [nombre, setNombre] = useState('')
    const [categoria, setCategoria] = useState('')
    const [subcategoria, setSubcategoria] = useState('')
    const [precio, setPrecio] = useState('')
    const [imgPrincipal, setImgPrincipal] = useState('')
    const [descripcion, setDescripcion] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            categoria,
            subcategoria,
            precio,
            descripcion,
            img_principal: imgPrincipal,
        }

        createRegistro(body)


    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/servicios")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    useEffect(() => {

        getCategorias()

    }, [])

    useEffect(() => {

        if (isSuccessGetCategorias) {
            setCategoriasOptions(responseGetCategorias)
            getSubcategorias({ idCategoria: (categoria != '' ? categoria : '0') })
        }

    }, [isSuccessGetCategorias, isErrorGetCategorias])

    useEffect(() => {

        if (isSuccessGetSubcategorias) {
            setSubcategoriasOptions(responseGetSubcategorias)
        }

    }, [isSuccessGetSubcategorias, isErrorGetSubcategorias])

    useEffect(() => {

        if (categoria != '') getSubcategorias({ idCategoria: (categoria != '' ? categoria : '0') })

    }, [categoria])

    if (permisosDelUsuario.indexOf(9) === -1) return <SinPermisos />

    if (
        isLoadingCreaRegistro ||
        isUninitializedGetCategorias ||
        isLoadingGetCategorias ||
        isLoadingGetSubcategorias ||
        isUninitializedGetSubcategorias
    ) return <Loading />


    var columnasPorImg = 12
    var columnasPorImgHijos = 3
    if (configServicios.configServicios?.img_principal.activo) {
        columnasPorImg = 8
        columnasPorImgHijos = 6
    }

    if (permisosDelUsuario.indexOf(9) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/servicios"}>
                            Servicios
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<LocalCafeIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>

                        {
                            configServicios.configServicios?.img_principal.activo &&
                            <Grid item xs={12} md={4}>
                                <CropImage
                                    imagen={imgPrincipal}
                                    setRoute={setImgPrincipal}
                                    maxSize="1000000"
                                    width={configServicios.configServicios?.img_principal?.width}
                                    height={configServicios.configServicios?.img_principal?.height}
                                    ruta="servicios"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={columnasPorImg}>
                            <Grid container spacing={2}>
                                {
                                    configServicios.configServicios?.categorias.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Select
                                            label="Categoria"
                                            value={categoria}
                                            options={categoriasOptions.map((item) => ({
                                                value: item.id,
                                                label: item.nombre
                                            }))}
                                            onChange={setCategoria}
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                }
                                {
                                    configServicios.configServicios?.subcategorias.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Select
                                            label="Subcategoria"
                                            value={subcategoria}
                                            options={subcategoriasOptions.map((item) => ({
                                                value: item.id,
                                                label: item.nombre
                                            }))}
                                            onChange={setSubcategoria}
                                            type="text"
                                            disabled={categoria == ''}
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Nombre"
                                        icon={<LocalCafeIcon />}
                                        value={nombre}
                                        onChange={setNombre}
                                        type="text"
                                        required
                                    />
                                </Grid>
                                {
                                    configServicios.configServicios?.precio &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Precio"
                                            icon={<AttachMoneyIcon />}
                                            value={precio}
                                            onChange={setPrecio}
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    {
                        configServicios.configServicios?.descripcion &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextEditor textoHtml={descripcion} setTextoHtml={setDescripcion} />
                            </Grid>
                        </Grid>
                    }
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}