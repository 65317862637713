import { tiendasApi } from '../../api'

export const artcatApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getArtCategorias: builder.mutation({
            query: (headersTemp) => ({
                url: '/artcat/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getArtCategoriasForSelect: builder.mutation({
            query: () => ({
                url: '/artcat/all',
                method: 'GET',
            })
        }),
        getArtCategoria: builder.mutation({
            query: (id) => ({
                url: '/artcat/' + id,
                method: 'GET',
            })
        }),
        createArtCategoria: builder.mutation({
            query: (body) => ({
                url: '/artcat',
                method: 'POST',
                body: body,
            })
        }),
        updateArtCategoria: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/artcat/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeArtCategoria: builder.mutation({
            query: (id) => ({
                url: '/artcat/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetArtCategoriasMutation,
    useGetArtCategoriasForSelectMutation,
    useGetArtCategoriaMutation,
    useCreateArtCategoriaMutation,
    useUpdateArtCategoriaMutation,
    useRemoveArtCategoriaMutation,
} = artcatApiSlice