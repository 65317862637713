import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'

import { useGetEstadosPedidoAllMutation } from '../../../../store/api/endpoints/config/configEstadosPedidoApiSlice'
import { useUpdateCambioDeEstadoMutation } from '../../../../store/api/endpoints/pedidos/pedidosApiSlice'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Button } from '@mui/material'

import RequestPageIcon from '@mui/icons-material/RequestPage'

export default function PedidoCambiarEstado() {

    const urlParams = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [
        getEstados,
        {
            data: responseGetEstados,
            isUninitialized: isUninitializedGetEstados,
            isLoading: isLoadingGetEstados,
            isSuccess: isSuccessGetEstados,
            isError: isErrorGetEstados,
        }
    ] = useGetEstadosPedidoAllMutation()

    const [
        updateEstado,
        {
            data: responseUpdateEstado,
            isUninitialized: isUninitializedUpdateEstado,
            isLoading: isLoadingUpdateEstado,
            isSuccess: isSuccessUpdateEstado,
            isError: isErrorUpdateEstado,
            error: errorDataUpdateEstado,
        }
    ] = useUpdateCambioDeEstadoMutation()

    useEffect(() => {
        getEstados()
    }, [])

    const handleClick = (idEstado) => {
        updateEstado({ id: urlParams.id, estado: idEstado })
    }

    useEffect(() => {

        if (isSuccessGetEstados) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/ventas/pedidos/" + urlParams.id)

        }

        if (isErrorUpdateEstado) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateEstado.data.message
            }))
        }


    }, [isSuccessUpdateEstado, isErrorUpdateEstado])

    if (isUninitializedGetEstados || isLoadingGetEstados || isLoadingUpdateEstado) return (<Loading />)

    if (isSuccessGetEstados) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/pedidos"}>
                            Pedidos
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/pedidos/" + urlParams.id}>
                            N° {urlParams.id}
                        </Link>
                        <Typography color="text.primary">Cambiar Estado</Typography>
                    </Breadcrumbs>
                }
                icon={<RequestPageIcon />}
                end={<></>}
            />
            <p>Cambiar estado del pedido N° {urlParams.id} a:</p>
            {responseGetEstados.map((unEstado, index) => {

                return (
                    <p key={index}>
                        <Button
                            variant="outlined"
                            style={{
                                backgroundColor: unEstado.color_fondo,
                                color: unEstado.color_texto,
                                border: 'none'
                            }}
                            onClick={() => handleClick(unEstado.id)}
                        >
                            {unEstado.nombre}
                        </Button >
                    </p>
                )
            })}
        </>
    )

}