import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'

import { useGetClienteLoginsMutation, useUpdateClienteLoginsMutation } from '../../../../store/api/endpoints/clientes/clientesLoginsApiSlice'

import { GetClienteNombre } from '../clientes/GetClienteNombre'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import ButtonIcon from '../../../elementos/ButtonIcon'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'


import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import BadgeIcon from '@mui/icons-material/Badge'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import MailIcon from '@mui/icons-material/Mail'
import PasswordIcon from '@mui/icons-material/Password'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'

export default function ClientesLoginsEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_fijo, setTelFijo] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [contrasena1, setContrasena1] = useState('')
    const [contrasena2, setContrasena2] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetClienteLoginsMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateClienteLoginsMutation()

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            email,
            tel_fijo,
            tel_whatsapp,
            contrasena1,
            contrasena2,
        }

        updateRegistro({ id: urlParams.id, body: body })


    }

    useEffect(() => {

        getRegistro(urlParams.id)

    }, [])

    useEffect(() => {

        if (isSuccessGetRegistro) {

            setNombre(responseGetRegistro.nombre)
            setEmail(responseGetRegistro.email)
            setTelFijo(responseGetRegistro.tel_fijo)
            setTelWhatsapp(responseGetRegistro.tel_whatsapp)

        }

    }, [
        isSuccessGetRegistro,
        isErrorGetRegistro
    ])

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha modificado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente)

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    if (permisosDelUsuario.indexOf(52) === -1) return <SinPermisos />

    if (
        isUninitializedGetRegistro ||
        isLoadingGetRegistro ||
        isLoadingUpdateRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(52) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/"}>
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.idCliente}>
                            <GetClienteNombre idCliente={urlParams.idCliente} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente}>
                            Usuarios
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente + "/" + urlParams.id}>
                            {nombre}
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre y Apellido"
                                icon={<BadgeIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailIcon />}
                                value={email}
                                onChange={setEmail}
                                helperText="Será su nombre de usuario para acceder."
                                type="mail"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Teléfono Fijo"
                                icon={<LocalPhoneIcon />}
                                value={tel_fijo}
                                onChange={setTelFijo}
                                type="tel"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                value={tel_whatsapp}
                                onChange={setTelWhatsapp}
                                type="tel"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena1}
                                onChange={setContrasena1}
                                type="password"
                                helperText="Crear una contraseña para el usuario. Luego podrá modificarla."

                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Repetir Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena2}
                                onChange={setContrasena2}
                                type="password"
                                helperText="Repetir la contraseña."

                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>

        </>
    )

}