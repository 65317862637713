import { createSlice } from "@reduxjs/toolkit"


export const configDefaultsClientesSlice = createSlice({
    name: 'configDefaultsClientes',
    initialState: {
        
    },
    reducers: {
        setStateConfigDefaultsClientes: (state, action) => {
            state.configDefaultsClientes = action.payload
        },
    }
})

export const { setStateConfigDefaultsClientes } = configDefaultsClientesSlice.actions

export const getStateConfigDefaultsClientes = (state) => state.configDefaultsClientes

