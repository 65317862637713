import { useParams } from 'react-router-dom'

import { useSelector } from 'react-redux'
import moment from 'moment'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigVentas } from '../../../../store/slices/configVentas'
import { getStateConfigClientes } from '../../../../store/slices/configClientes'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { useGetPedidosMutation, useGetPedidosColumnasForOrderByMutation } from '../../../../store/api/endpoints/pedidos/pedidosApiSlice'

import TablaPaginada2 from '../../../elementos/TablaPaginada2/TablaPaginada2'

import SearchIcon from '@mui/icons-material/Search'


export default function ClientePedidos({
    idCliente,
}) {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configVentas = useSelector(getStateConfigVentas)
    const configClientes = useSelector(getStateConfigClientes)
    const configArticulos = useSelector(getStateConfigArticulos)

    if (permisosDelUsuario.indexOf(26) == -1) return (<p>No tienes acceso a visualizar los usuarios.</p>)

    let visiblesTemp = []
    visiblesTemp.push(true) //estado
    visiblesTemp.push(true) //nro
    visiblesTemp.push(true) //fecha
    if (configVentas.configVentas.envio_metodo.activo) visiblesTemp.push(false) //entrega
    visiblesTemp.push(true) //cliente
    if (configClientes.configClientes.direccion.activo) visiblesTemp.push(false) //codigo postal
    if (configClientes.configClientes.documento.activo) visiblesTemp.push(false) //documento
    if (configArticulos.configArticulos.precio.activo) visiblesTemp.push(false) //total
    if (configArticulos.configArticulos.precio.activo) visiblesTemp.push(false) //cobranza
    visiblesTemp.push(true) //Acciones

    var configTable = {
        page: 1,
        textsearch: '',
        from: moment().add(-7, 'years').format("YYYY-MM-DD"),
        to: moment().format('YYYY-MM-DD'),
        orderBy: [[1, 2]],
        filtros: [null, null, null, [], idCliente, null],
        visibles: visiblesTemp,
        clienteAnterior: "",
        cliente: idCliente,
        clienteInput: "",
        clientesOptions: "",
        nropedidoAnterior: "",
        nropedido: "",
        nropedidoInput: "",
        nropedidoOptions: "",
    }
    var configLocalstorage = "conftable-clientes-pedidos"
    let temp = JSON.parse(localStorage.getItem(configLocalstorage))
    if (localStorage.getItem(configLocalstorage) && temp.filtros[4] == idCliente) {
        configTable = JSON.parse(localStorage.getItem(configLocalstorage))
    }

    const thead = []
    thead.push({ label: "Estado", align: "center" })
    thead.push({ label: "N°", align: "right" })
    thead.push({ label: "Fecha", align: "center" })
    thead.push({ label: "Entrega", align: "center" })
    thead.push({ label: "Cliente", align: "left" })
    thead.push({ label: "Cod. Postal", align: "right" })
    thead.push({ label: "Documento", align: "left" })
    thead.push({ label: "Total", align: "right" })
    thead.push({ label: "Cobranza", align: "right" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "estadoRegistro", valor: "estadoNombre" })
    tbody.push({ tipo: "texto", valor: "id" })
    tbody.push({ tipo: "fecha", valor: "fecha" })
    tbody.push({ tipo: "fecha", valor: "fecha_estimada_entrega" })
    tbody.push({ tipo: "texto", valor: "clienteNombre" })
    tbody.push({ tipo: "texto", valor: "codigo_postal" })
    tbody.push({ tipo: "texto", valor: "clienteDocumento" })
    tbody.push({ tipo: "precio", options: [",", ".", "$ "], valor: "total" })
    tbody.push({ tipo: "precio", options: [",", ".", "$ "], valor: "cobranza" })

    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/" + urlParams.dominio + '/ventas/pedidos/' })
    tbody.push({ tipo: "botones", valor: botones })


    if (permisosDelUsuario.indexOf(26) > -1) return (
        <>
            <TablaPaginada2
                api={useGetPedidosMutation}
                thead={thead}
                tbody={tbody}
                configTable={configTable}
                configLocalstorage={configLocalstorage}
                autoCompleteAsync={{}}
                columnasOptionsGet={useGetPedidosColumnasForOrderByMutation}
            />
        </>
    )

}

