import { useEffect, useState, forwardRef, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { ComponentToPrint } from './ComponentToPrint'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { getStateConfigClientes } from '../../../../store/slices/configClientes'
import { getStateConfig } from '../../../../store/slices/config'

import { useGetPedidoByIdMutation } from '../../../../store/api/endpoints/pedidos/pedidosApiSlice'
import { useGetTiposMutation, useGetBySlugMutation } from '../../../../store/api/endpoints/pedidos-pdf/pedidosPdfApiSlice'


import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Moment from '../../../elementos/Moment'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import ButtonIcon from '../../../elementos/ButtonIcon'


import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Divider } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import RequestPageIcon from '@mui/icons-material/RequestPage'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import DescriptionIcon from '@mui/icons-material/Description'


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function PedidoPrint({ idPedido }) {

    const componentRef = useRef()
    const config = useSelector(getStateConfig)

    const [openDialog, setOpenDialog] = useState(false)

    const [
        getPedido,
        {
            data: responseGetPedido,
            isUninitialized: isUninitializedGetPedido,
            isLoading: isLoadingGetPedido,
            isSuccess: isSuccessGetPedido,
            isError: isErrorGetPedido,
        }
    ] = useGetPedidoByIdMutation()

    const [
        getTipos,
        {
            data: responseGetTiposPdf,
            isUninitialized: isUninitializedGetTiposPdf,
            isLoading: isLoadingGetTiposPdf,
            isSuccess: isSuccessGetTiposPdf,
            isError: isErrorGetTiposPdf,
        }
    ] = useGetTiposMutation()

    const [
        getBySlug,
        {
            data: responseGetBySlug,
            isUninitialized: isUninitializedGetBySlug,
            isLoading: isLoadingGetBySlug,
            isSuccess: isSuccessGetBySlug,
            isError: isErrorGetBySlug,
        }
    ] = useGetBySlugMutation()

    const handleOpenDialog = () => {
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const handleEligeOpcionPDF = (id) => {
        getBySlug(id)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const handleClick = () => {
        handleOpenDialog()
        getPedido(idPedido)
        getTipos()
    }

    useEffect(() => {
        if( isSuccessGetBySlug && isSuccessGetPedido ){
            handlePrint()
            handleCloseDialog()
        }
    }, [isSuccessGetBySlug])

    if (isLoadingGetPedido) return (<Loading />)

    if (isUninitializedGetPedido) return (
        <ButtonIcon
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<LocalPrintshopIcon />}
            handleClick={() => handleClick()}
            noLink
            marginRight
        />
    )

    if (isSuccessGetPedido) return (
        <>
            {
                isSuccessGetBySlug && 
                <div style={{ display: "none" }}><ComponentToPrint props={{ pdf: responseGetBySlug, response: responseGetPedido, config: config }} ref={componentRef} /></div>
            }
            <ButtonIcon
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<LocalPrintshopIcon />}
                handleClick={() => handleClick()}
                noLink
                marginRight
            />

            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Elegí una Opción</DialogTitle>
                <DialogContent>
                    {
                        isUninitializedGetTiposPdf || isLoadingGetTiposPdf || isLoadingGetBySlug &&
                        <Loading />
                    }
                    {
                        (isSuccessGetTiposPdf && !isLoadingGetBySlug) && 
                        <List sx={{ pt: 0 }}>
                            {responseGetTiposPdf.map((item) => (
                                <ListItem disableGutters key={item.slug}>
                                    <ListItemButton onClick={() => handleEligeOpcionPDF(item.slug)}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <DescriptionIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={item.nombre} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

