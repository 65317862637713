import { tiendasApi } from '../../api'

export const configPagoMetodoApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPagoMetodoPaginated: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/pago-metodos/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPagoMetodoAll: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/pago-metodos/all',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPagoMetodo: builder.mutation({
            query: (id) => ({
                url: '/config/pago-metodos/' + id,
                method: 'GET',
            })
        }),
        createPagoMetodo: builder.mutation({
            query: (body) => ({
                url: '/config/pago-metodos',
                method: 'POST',
                body: body,
            })
        }),
        updatePagoMetodo: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/config/pago-metodos/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removePagoMetodo: builder.mutation({
            query: (id) => ({
                url: '/config/pago-metodos/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPagoMetodoPaginatedMutation,
    useGetPagoMetodoAllMutation,
    useGetPagoMetodoMutation,
    useCreatePagoMetodoMutation,
    useUpdatePagoMetodoMutation,
    useRemovePagoMetodoMutation,
} = configPagoMetodoApiSlice