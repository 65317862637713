import { React } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import Moment from '../../../elementos/Moment'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { Button, Divider } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

export default function CambiosDeEstado({ dominio, responseGetPedido }) {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    return (
        <Box sx={{ width: '100%' }}>
            <Divider />
            <p align="center" style={{ fontSize: '0.9rem', fontWeight: '600' }}>Cambios de Estado</p>
            <Stepper activeStep={responseGetPedido.cambiosDeEstado.length + 1} orientation="vertical">
                <Step>
                    <StepLabel>
                        <p style={{ margin: '0px', fontSize: '0.85rem', fontWeight: '600' }}>Creado</p>
                        <p style={{ margin: '0px', fontSize: '0.65rem', fontWeight: '500' }}>{<><Moment date={responseGetPedido.encabezado.fecha} /> ({responseGetPedido.encabezado.vendedor==null ? 'Cliente' : responseGetPedido.encabezado.vendedor})</>}</p>
                    </StepLabel>
                </Step>
                {responseGetPedido.cambiosDeEstado.map((estado, index) => {

                    return (
                        <Step key={index}>
                            <StepLabel>
                                <p style={{ margin: '0px', fontSize: '0.85rem', fontWeight: '600' }}>{estado.estado_nuevo}</p>
                                <p style={{ margin: '0px', fontSize: '0.65rem', fontWeight: '500' }}>{<><Moment date={estado.fecha} /> ({estado.usuario})</>}</p>
                            </StepLabel>
                        </Step>
                    )
                })}
                {
                    permisosDelUsuario.indexOf(47) > -1 &&
                    <Step>
                    <StepLabel StepIconComponent={AddIcon}>
                        <Link
                            to={"/" + dominio + "/ventas/pedidos/" + responseGetPedido.encabezado.id + "/cambiar-estado"}
                            style={{ margin: '0px', fontSize: '0.85rem', fontWeight: '600', padding: '0px' }}
                        >
                            Nuevo...
                        </Link>
                    </StepLabel>
                </Step>
                }
            </Stepper>
        </Box>
    )

}