import { createSlice } from "@reduxjs/toolkit"


export const configClientesSlice = createSlice({
    name: 'configClientes',
    initialState: {
        
    },
    reducers: {
        setStateConfigClientes: (state, action) => {
            state.configClientes = action.payload
        },
    }
})

export const { setStateConfigClientes } = configClientesSlice.actions

export const getStateConfigClientes = (state) => state.configClientes

