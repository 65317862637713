import { tiendasApi } from '../../api'

export const configPagoPlazosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPagoPlazosPaginated: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/pago-plazos/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPagoPlazosAll: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/pago-plazos/all',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPagoPlazo: builder.mutation({
            query: (id) => ({
                url: '/config/pago-plazos/' + id,
                method: 'GET',
            })
        }),
        createPagoPlazo: builder.mutation({
            query: (body) => ({
                url: '/config/pago-plazos',
                method: 'POST',
                body: body,
            })
        }),
        updatePagoPlazo: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/config/pago-plazos/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removePagoPlazo: builder.mutation({
            query: (id) => ({
                url: '/config/pago-plazos/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPagoPlazosAllMutation,
    useGetPagoPlazosPaginatedMutation,
    useGetPagoPlazoMutation,
    useCreatePagoPlazoMutation,
    useUpdatePagoPlazoMutation,
    useRemovePagoPlazoMutation,
} = configPagoPlazosApiSlice