import { tiendasApi } from '../../api'

export const clientesDireccionesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientesDirecciones: builder.mutation({
            query(data){
                const { page, textsearch, parametroEnUrl } = data
                return {
                    url: "/ventas/clientes-direcciones/" + parametroEnUrl + "/paginated/",
                    method: 'GET',
                    headers: {
                        page,
                        textsearch,
                    },
                }
            }
        }),
        getClientesDireccionesAll: builder.mutation({
            query(data){
                const { idCliente } = data
                return {
                    url: '/ventas/clientes-direcciones/' + idCliente + '/all',
                    method: 'GET',
                }
            }
        }),
        getClienteDireccion: builder.mutation({
            query(data){
                const { idCliente, idDireccion } = data
                return {
                    url: '/ventas/clientes-direcciones/' + idCliente + '/' + idDireccion,
                    method: 'GET',
                }
            }
        }),
        createClienteDireccion: builder.mutation({
            query: (body) => ({
                url: '/ventas/clientes-direcciones',
                method: 'POST',
                body: body,
            })
        }),
        updateClienteDireccion: builder.mutation({
            query(data){
                const { idCliente, idDireccion, body } = data
                return {
                    url: '/ventas/clientes-direcciones/' + idCliente + '/' + idDireccion,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeClienteDireccion: builder.mutation({
            query: (id) => ({
                url: '/ventas/clientes-direcciones/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetClientesDireccionesMutation,
    useGetClientesDireccionesAllMutation,
    useGetClienteDireccionMutation,
    useCreateClienteDireccionMutation,
    useUpdateClienteDireccionMutation,
    useRemoveClienteDireccionMutation,
} = clientesDireccionesApiSlice