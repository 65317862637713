import { tiendasApi } from '../../api'

export const serviciosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getServicios: builder.mutation({
            query: (headersTemp) => ({
                url: '/servicios/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getServicio: builder.mutation({
            query: (id) => ({
                url: '/servicios/' + id,
                method: 'GET',
            })
        }),
        createServicio: builder.mutation({
            query: (body) => ({
                url: '/servicios',
                method: 'POST',
                body: body,
            })
        }),
        updateServicio: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/servicios/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeServicio: builder.mutation({
            query: (id) => ({
                url: '/servicios/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetServiciosMutation,
    useGetServicioMutation,
    useCreateServicioMutation,
    useUpdateServicioMutation,
    useRemoveServicioMutation,
} = serviciosApiSlice