import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { grey } from '@mui/material/colors'

import { getStateConfigDefaultsClientes } from '../../../../store/slices/configDefaultsClientes'
import { getStateConfigVentas } from '../../../../store/slices/configVentas'

import AutocompleteClientes from '../../../elementos/Autocomplete/AutocompleteClientes'
import AutocompletePedidos from '../../../elementos/Autocomplete/AutocompletePedidos'

import ButtonIcon from '../../../elementos/ButtonIcon'
import Select from '../../../elementos/Select'
import RangoFechas from '../../../elementos/RangoFechas'
import TablaElegirColumnas from '../../../elementos/TablaPaginada2/TablaElegirColumnas'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Divider } from '@mui/material'

import PaymentIcon from '@mui/icons-material/Payment'
import CheckIcon from '@mui/icons-material/Check'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ScheduleIcon from '@mui/icons-material/Schedule'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import CloseIcon from '@mui/icons-material/Close'
import SelectMultiple from '../../../elementos/SelectMultiple'

export default function PedidosFiltros({
    filtros,
    setFiltros,
    bloqueFechas,
    setBloqueFechas,
    columnasOptions,
    setColumnasOptions,
    orderBy,
    setOrderBy,
    autoCompleteAsync,
}) {

    const filtrosLength = 5
    const configDefaultsClientes = useSelector(getStateConfigDefaultsClientes)
    const configVentas = useSelector(getStateConfigVentas)

    const [open, setOpen] = useState(false)
    const [filtrosLocal, setFiltrosLocal] = useState(filtros)
    const [bloqueFechasLocal, setBloqueFechasLocal] = useState(bloqueFechas)
    const [clienteInputLocal, setClienteInputLocal] = useState(autoCompleteAsync.valores.cliente.clienteInput)
    const [clientesOptionsLocal, setClientesOptionsLocal] = useState(autoCompleteAsync.valores.cliente.clientesOptions)
    const [clienteLocal, setClienteLocal] = useState(autoCompleteAsync.valores.cliente.cliente)
    const [nropedidoInputLocal, setNropedidoInputLocal] = useState(autoCompleteAsync.valores.nropedido.nropedidoInput)
    const [nropedidoOptionsLocal, setNropedidoOptionsLocal] = useState(autoCompleteAsync.valores.nropedido.nropedidoOptions)
    const [nropedidoLocal, setNropedidoLocal] = useState(autoCompleteAsync.valores.nropedido.nropedido)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {

        setOpen(false)
        setFiltrosLocal(filtros)
        setBloqueFechasLocal(bloqueFechas)
        setClienteInputLocal(autoCompleteAsync.valores.cliente.clienteInput)
        setClientesOptionsLocal(autoCompleteAsync.valores.cliente.clientesOptions)
        setClienteLocal(autoCompleteAsync.valores.cliente.cliente)
        setNropedidoInputLocal(autoCompleteAsync.valores.nropedido.nropedidoInput)
        setNropedidoOptionsLocal(autoCompleteAsync.valores.nropedido.nropedidoOptions)
        setNropedidoLocal(autoCompleteAsync.valores.nropedido.nropedido)
    }

    const aplicarFiltros = () => {

        let filtrosTemp = []
        for (let i = 0; i <= filtrosLength; i++) {

            if (i <= 2) {
                filtrosTemp.push((filtrosLocal[i] > 0 ? filtrosLocal[i] : null))
            } else if (i == 3) {
                filtrosTemp.push((filtrosLocal[i].length > 0 ? filtrosLocal[i] : []))
            } else if (i == 4) {
                filtrosTemp.push((clienteLocal > 0 ? clienteLocal : null))
            } else if (i == 5) {
                filtrosTemp.push((nropedidoLocal > 0 ? nropedidoLocal * 1 : null))
            }

        }

        autoCompleteAsync.funciones.cliente.setClientesOptions(clientesOptionsLocal)
        autoCompleteAsync.funciones.cliente.setClienteInput(clienteInputLocal)
        autoCompleteAsync.funciones.cliente.setCliente(clienteLocal)

        autoCompleteAsync.funciones.nropedido.setNropedidoOptions(nropedidoOptionsLocal)
        autoCompleteAsync.funciones.nropedido.setNropedidoInput((nropedidoLocal > 0 ? nropedidoInputLocal : ''))
        autoCompleteAsync.funciones.nropedido.setNropedido((nropedidoLocal > 0 ? nropedidoLocal : 0))

        setFiltros(filtrosTemp)
        setBloqueFechas(bloqueFechasLocal)

    }

    const handleChangeUnFiltro = (e, posicion) => {

        let filtrosTemp = []
        for (let i = 0; i <= filtrosLength; i++) {

            if (posicion == i) {
                filtrosTemp.push((e > 0 ? e : null))
            } else {
                filtrosTemp.push((filtrosLocal[i] ? filtrosLocal[i] : null))
            }

        }

        setFiltrosLocal(filtrosTemp)

    }

    const handleChangeUnFiltroArr = (e, posicion) => {

        let filtrosTemp = []
        for (let i = 0; i <= filtrosLength; i++) {

            if (posicion == i) {
                filtrosTemp.push((e.length > 0 ? e : []))
            } else {
                filtrosTemp.push((filtrosLocal[i] ? filtrosLocal[i] : null))
            }

        }

        setFiltrosLocal(filtrosTemp)

    }

    return (
        <>
            <Container className="containerBigTable" component="main" maxWidth="lg">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ButtonIcon
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<FilterAltIcon />}
                            noLink
                            handleClick={handleClickOpen}
                            marginRight
                        >
                            Filtros
                        </ButtonIcon>
                        <TablaElegirColumnas
                            columnasOptions={columnasOptions}
                            setColumnasOptions={setColumnasOptions}

                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                        />
                    </Grid>
                </Grid>
            </Container>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    Filtros
                </DialogTitle>
                <DialogContent>
                    <Divider />
                    <Container className="containerBigTable" component="main" maxWidth="lg">
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <AutocompleteClientes
                                    clienteInput={clienteInputLocal}
                                    setClienteInput={setClienteInputLocal}

                                    cliente={clienteLocal}
                                    setCliente={setClienteLocal}

                                    clientesOptions={clientesOptionsLocal}
                                    setClientesOptions={setClientesOptionsLocal}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <AutocompletePedidos

                                    registroInput={nropedidoInputLocal}
                                    setRegistroInput={setNropedidoInputLocal}

                                    registro={nropedidoLocal}
                                    setRegistro={setNropedidoLocal}

                                    registrosOptions={nropedidoOptionsLocal}
                                    setRegistrosOptions={setNropedidoOptionsLocal}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RangoFechas
                                    size="small"
                                    bloqueFechas={bloqueFechasLocal}
                                    changeBloqueFechas={setBloqueFechasLocal}
                                    botonAplicar={false}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SelectMultiple
                                    label="Estado del Pedido"
                                    icon={<ScheduleIcon />}
                                    size="small"
                                    value={(filtrosLocal[3] ? filtrosLocal[3] : [])}
                                    options={configDefaultsClientes.configDefaultsClientes.estadosPedido.map(elemento => ({
                                        label: elemento.nombre,
                                        value: elemento.id,
                                    }))}
                                    onChange={e => handleChangeUnFiltroArr(e, 3)}
                                />
                            </Grid>
                            {configVentas.configVentas.pago_metodo.activo &&
                                <Grid item xs={12} md={3}>
                                    <Select
                                        label="Método de Pago"
                                        size="small"
                                        icon={<PaymentIcon />}
                                        value={(filtrosLocal[0] ? filtrosLocal[0] : 0)}
                                        options={[{ value: "0", label: "Todos" }].concat(configDefaultsClientes.configDefaultsClientes.pagoMetodo.map(elemento => ({
                                            label: elemento.nombre,
                                            value: elemento.id,
                                        })))}
                                        onChange={e => handleChangeUnFiltro(e, 0)}
                                    />
                                </Grid>
                            }
                            {configVentas.configVentas.envio_metodo.activo &&
                                <Grid item xs={12} md={3}>
                                    <Select
                                        label="Método de Envío"
                                        size="small"
                                        icon={<LocalShippingIcon />}
                                        value={(filtrosLocal[1] ? filtrosLocal[1] : 0)}
                                        options={[{ value: "0", label: "Todos" }].concat(configDefaultsClientes.configDefaultsClientes.envioMetodos.map(elemento => ({
                                            label: elemento.nombre,
                                            value: elemento.id,
                                        })))}
                                        onChange={e => handleChangeUnFiltro(e, 1)}
                                    />
                                </Grid>
                            }
                            {configVentas.configVentas.pago_plazo.activo &&
                                <Grid item xs={12} md={3}>
                                    <Select
                                        label="Plazo de Pago"
                                        size="small"
                                        icon={<CalendarMonthIcon />}
                                        value={(filtrosLocal[2] ? filtrosLocal[2] : 0)}
                                        options={[{ value: "0", label: "Todos" }].concat(configDefaultsClientes.configDefaultsClientes.pagoPlazos.map(elemento => ({
                                            label: elemento.nombre,
                                            value: elemento.id,
                                        })))}
                                        onChange={e => handleChangeUnFiltro(e, 2)}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <p align="center">
                                    <ButtonIcon
                                        noLink
                                        variant="contained"
                                        color="grey"
                                        startIcon={<CloseIcon />}
                                        handleClick={e => handleClose()}
                                        marginRight
                                    >
                                        Cancelar
                                    </ButtonIcon>
                                    <ButtonIcon
                                        noLink
                                        variant="contained"
                                        color="success"
                                        startIcon={<CheckIcon />}
                                        handleClick={e => aplicarFiltros()}
                                    >
                                        Aplicar Filtros
                                    </ButtonIcon>
                                </p>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
            </Dialog>

        </>

    )

}