import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { useGetArtCategoriasMutation } from '../../../../store/api/endpoints/articulos/artcatApiSlice'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'


import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import TurnedInIcon from '@mui/icons-material/TurnedIn'



export default function Categorias() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)

    if (permisosDelUsuario.indexOf(18) == -1) return (<p>No tienes acceso a visualizar las categorías.</p>)
    if (!configArticulos.configArticulos?.activo) return (<p>No están habilitados los artículos.</p>)
    if (!configArticulos.configArticulos?.categorias.activo) return (<p>No están habilitadas las categorías.</p>)

    const thead = []
    if (configArticulos.configArticulos?.categorias.img_principal.activo) thead.push({ label: "Imagen", align: "center" })
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    if (configArticulos.configArticulos?.categorias.img_principal.activo) tbody.push({ tipo: "img", valor: "img_principal" })
    tbody.push({ tipo: "texto", valor: "nombre" })

    let botones = []
    if (permisosDelUsuario.indexOf(20) > -1) botones.push({ color: "primary", icono: <EditIcon />, ruta: "/" + urlParams.dominio + '/articulos/categorias/' })
    tbody.push({ tipo: "botones", valor: botones })


    if (permisosDelUsuario.indexOf(18) > -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/articulos"}>
                            Artículos
                        </Link>
                        <Typography color="text.primary">Categorías</Typography>
                    </Breadcrumbs>
                }
                icon={<TurnedInIcon />}
                end={(permisosDelUsuario.indexOf(19) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/articulos/categorias/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetArtCategoriasMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}

