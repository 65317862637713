import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'

import { useGetClienteDireccionMutation } from '../../../../store/api/endpoints/clientes/clientesDireccionesApiSlice'

import { GetClienteNombre } from '../clientes/GetClienteNombre'


import SinPermisos from '../../auth/SinPermisos'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import ButtonIcon from '../../../elementos/ButtonIcon'
import ConfirmDelete from '../../../elementos/ConfirmDelete'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'


import '../../../../assets/css/vistaFormulario.css'

import FmdGoodIcon from '@mui/icons-material/FmdGood'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import EditIcon from '@mui/icons-material/Edit'


export default function ClientesDireccionesDetalle() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)


    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro
        }
    ] = useGetClienteDireccionMutation()


    const [nombre, setNombre] = useState('')
    const [calle, setCalle] = useState('')
    const [altura, setAltura] = useState('')
    const [piso, setPiso] = useState('')
    const [departamento, setDepartamento] = useState('')
    const [localidad, setLocalidad] = useState('')
    const [codigo_postal, setCodigoPostal] = useState('')
    const [provincia, setProvincia] = useState('')
    const [pais, setPais] = useState('')
    const [observaciones, setObservaciones] = useState('')


    useEffect(() => {
        getRegistro({ idCliente: urlParams.idCliente, idDireccion: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetRegistro) {
            setNombre(responseGetRegistro.nombre)
            setCalle(responseGetRegistro.calle)
            setAltura(responseGetRegistro.altura)
            setPiso(responseGetRegistro.piso)
            setDepartamento(responseGetRegistro.departamento)
            setLocalidad(responseGetRegistro.localidad)
            setCodigoPostal(responseGetRegistro.codigo_postal)
            setProvincia(responseGetRegistro.provincia)
            setPais(responseGetRegistro.pais)
            setObservaciones(responseGetRegistro.observaciones)
        }

    }, [isSuccessGetRegistro, isErrorGetRegistro])


    if (permisosDelUsuario.indexOf(54) === -1) return <SinPermisos />

    if (
        isUninitializedGetRegistro ||
        isLoadingGetRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(54) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/"}>
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.idCliente}>
                            <GetClienteNombre idCliente={urlParams.idCliente} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes-direcciones/" + urlParams.idCliente}>
                            Direcciones
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<FmdGoodIcon />}
                end={<>
                    {
                        permisosDelUsuario.indexOf(56) > -1 &&
                        <><ButtonIcon
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<EditIcon />}
                            to={"/" + urlParams.dominio + "/ventas/clientes-direcciones/" + urlParams.idCliente + "/" + urlParams.id + "/editar"}
                            marginRight
                        >
                        </ButtonIcon> </>
                    }
                    {
                        permisosDelUsuario.indexOf(57) > -1 &&
                        <><ConfirmDelete
                            httpDelete={"/" + urlParams.dominio + "ventas/clientes-direcciones/" + urlParams.idCliente + "/" + urlParams.id}
                            returnUrl={"/" + urlParams.dominio + "/ventas/clientes-direcciones/" + urlParams.idCliente}
                            variant="outlined"
                            size="small"
                            soloIcono
                        /> </>
                    }
                </>}
            />

            <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Input
                            label="Nombre"
                            icon={<FmdGoodIcon />}
                            value={nombre}
                            onChange={setNombre}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Input
                            label="Calle"
                            icon={<FmdGoodIcon />}
                            value={calle}
                            onChange={setCalle}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Input
                            label="Altura"
                            icon={<FmdGoodIcon />}
                            value={altura}
                            onChange={setAltura}
                            type="tel"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Input
                            label="Piso"
                            icon={<FmdGoodIcon />}
                            value={piso}
                            onChange={setPiso}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Input
                            label="Departamento"
                            icon={<FmdGoodIcon />}
                            value={departamento}
                            onChange={setDepartamento}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input
                            label="Localidad"
                            icon={<FmdGoodIcon />}
                            value={localidad}
                            onChange={setLocalidad}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input
                            label="Código Postal"
                            icon={<FmdGoodIcon />}
                            value={codigo_postal}
                            onChange={setCodigoPostal}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input
                            label="Provincia"
                            icon={<FmdGoodIcon />}
                            value={provincia}
                            onChange={setProvincia}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input
                            label="País"
                            icon={<FmdGoodIcon />}
                            value={pais}
                            onChange={setPais}
                            type="text"
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label="Observaciones"
                            icon={<ChatBubbleIcon />}
                            value={observaciones}
                            onChange={setObservaciones}
                            type="text"
                            multiline
                            disabled
                        />
                    </Grid>
                </Grid>
            </Container>


        </>
    )

}