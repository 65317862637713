import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetArtCategoriasForSelectMutation } from '../../../../store/api/endpoints/articulos/artcatApiSlice'
import { useGetArtSubcategoriasForSelectMutation } from '../../../../store/api/endpoints/articulos/artsubcatApiSlice'
import { useGetArticuloMutation, useUpdateArticuloMutation } from '../../../../store/api/endpoints/articulos/articulosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import TextEditor from '../../../elementos/TextEditor'
import CropImage from '../../../elementos/CropImage'

import '../../../../assets/css/vistaFormulario.css'

import BakeryDiningIcon from '@mui/icons-material/BakeryDining'
import CheckIcon from '@mui/icons-material/Check'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import QrCode2Icon from '@mui/icons-material/QrCode2'

export default function ArticuloEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)

    const [
        getCategorias,
        {
            data: responseGetCategorias,
            isUninitialized: isUninitializedGetCategorias,
            isLoading: isLoadingGetCategorias,
            isSuccess: isSuccessGetCategorias,
            isError: isErrorGetCategorias,
        }
    ] = useGetArtCategoriasForSelectMutation()

    const [
        getSubcategorias,
        {
            data: responseGetSubcategorias,
            isUninitialized: isUninitializedGetSubcategorias,
            isLoading: isLoadingGetSubcategorias,
            isSuccess: isSuccessGetSubcategorias,
            isError: isErrorGetSubcategorias,
        }
    ] = useGetArtSubcategoriasForSelectMutation()

    const [
        getArticulo,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro
        }
    ] = useGetArticuloMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateArticuloMutation()


    const [categoriasOptions, setCategoriasOptions] = useState([])
    const [subcategoriasOptions, setSubcategoriasOptions] = useState([])
    const [nombre, setNombre] = useState('')
    const [codigo, setCodigo] = useState('')
    const [descripcion, setDescripcion] = useState('')
    const [categoria, setCategoria] = useState('')
    const [subcategoria, setSubcategoria] = useState('')
    const [precio, setPrecio] = useState(['', '', '', '', '', '', '', '', ''])
    const [imgPrincipal, setImgPrincipal] = useState('')
    const [isSpinner, setIsSpinner] = useState(true)
    const [primeraCarga, setPrimeraCarga] = useState(true)


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            titulo: nombre,
            codigo: codigo,
            categoria,
            subcategoria,
            precio,
            img_principal: imgPrincipal,
            descripcion,
        }

        updateRegistro({ id: urlParams.id, body })


    }

    const handleChangePrecio = (e, index) => {

        let precioNuevo = []
        for (let i = 0; i < 9; i++) {
            if (index === i) {
                precioNuevo.push(e)
            } else {
                precioNuevo.push(precio[i])
            }
        }
        setPrecio(precioNuevo)

    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/articulos")

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        if (configArticulos.configArticulos?.categorias?.activo) getCategorias()

    }, [])

    useEffect(() => {

        if (isSuccessGetCategorias) {
            setCategoriasOptions(responseGetCategorias)
            if (configArticulos.configArticulos?.subcategorias?.activo){
                getSubcategorias({ idCategoria: (categoria != '' ? categoria : '0') })
            }else{
                getArticulo(urlParams.id)
            }
        }

    }, [isSuccessGetCategorias, isErrorGetCategorias])

    useEffect(() => {

        if (isSuccessGetSubcategorias) {
            setSubcategoriasOptions(responseGetSubcategorias)
            getArticulo(urlParams.id)
        }

    }, [isSuccessGetSubcategorias, isErrorGetSubcategorias])

    useEffect(() => {

        if (isSuccessGetRegistro && primeraCarga) {
            if (responseGetRegistro.categoriaId > 0) setCategoria(responseGetRegistro.categoriaId)
            if (responseGetRegistro.subcategoriaId > 0) setSubcategoria(responseGetRegistro.subcategoriaId)
            setNombre(responseGetRegistro.titulo)
            setCodigo(responseGetRegistro.codigo)
            setImgPrincipal(responseGetRegistro.img_principal)
            setDescripcion(responseGetRegistro.descripcion)

            let precios = ["", "", "", "", "", "", "", "", ""]
            for (let i = 0; i < configArticulos.configArticulos?.precio.cantidad; i++) {
                if (responseGetRegistro["lista" + (i * 1 + 1)] > 0) precios[i] = responseGetRegistro["lista" + (i * 1 + 1)]
            }
            setPrecio(precios)

            setIsSpinner(false)
            setPrimeraCarga(false)
        }

    }, [isSuccessGetRegistro, isErrorGetRegistro])

    useEffect(() => {

        if (categoria != '' && configArticulos.configArticulos?.subcategorias?.activo) getSubcategorias({ idCategoria: (categoria != '' ? categoria : '0') })

    }, [categoria])

    if (permisosDelUsuario.indexOf(24) === -1) return <SinPermisos />

    if (
        isSpinner ||
        isLoadingGetRegistro ||
        isUninitializedGetRegistro ||
        isLoadingUpdateRegistro ||
        (
            configArticulos.configArticulos?.categorias?.activo &&
            (
                isUninitializedGetCategorias ||
                isLoadingGetCategorias
            )
        )
        ||
        (
            configArticulos.configArticulos?.subcategorias?.activo &&
            (
                isLoadingGetSubcategorias ||
                isUninitializedGetSubcategorias
            )
        )
    ) return <Loading />

    var columnasPorImg = 12
    var columnasPorImgHijos = 3
    if (configArticulos.configArticulos?.img_principal.activo) {
        columnasPorImg = 8
        columnasPorImgHijos = 6
    }

    if (permisosDelUsuario.indexOf(24) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/articulos"}>
                            Artículos
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<BakeryDiningIcon />}
                end={(permisosDelUsuario.indexOf(25) !== -1 ? <ConfirmDelete
                    httpDelete={"articulos/" + urlParams.id}
                    returnUrl={"/" + urlParams.dominio + "/articulos"}
                    variant="outlined"
                    size="small"
                /> : '')}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        {
                            configArticulos.configArticulos?.img_principal.activo &&
                            <Grid item xs={12} md={4}>
                                <CropImage
                                    imagen={imgPrincipal}
                                    setRoute={setImgPrincipal}
                                    maxSizeMB={1}
                                    width={configArticulos.configArticulos?.img_principal.width}
                                    height={configArticulos.configArticulos?.img_principal.height}
                                    ruta="articulos"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                {
                                    configArticulos.configArticulos?.categorias.activo &&
                                    <Grid item xs={12} md={6}>
                                        <Select
                                            label="Categoria"
                                            value={categoria}
                                            options={categoriasOptions.map((item) => ({
                                                value: item.id,
                                                label: item.nombre
                                            }))}
                                            onChange={setCategoria}
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                }
                                {
                                    configArticulos.configArticulos?.subcategorias.activo &&
                                    <Grid item xs={12} md={6}>
                                        <Select
                                            label="Subcategoria"
                                            value={subcategoria}
                                            options={subcategoriasOptions.map((item) => ({
                                                value: item.id,
                                                label: item.nombre
                                            }))}
                                            onChange={setSubcategoria}
                                            type="text"
                                            disabled={categoria == ''}
                                            required
                                        />
                                    </Grid>
                                }
                                {
                                    configArticulos.configArticulos?.codigo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Código"
                                            icon={<QrCode2Icon />}
                                            value={codigo}
                                            onChange={setCodigo}
                                            type="text"
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    <Input
                                        label="Nombre"
                                        icon={<BakeryDiningIcon />}
                                        value={nombre}
                                        onChange={setNombre}
                                        type="text"
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    {
                        configArticulos.configArticulos?.precio.activo &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h3>Precios y Listas</h3>
                            </Grid>
                            {
                                configArticulos.configArticulos?.precio &&
                                configArticulos.configArticulos?.precio.nombres.map((nombre, index) => (
                                    <Grid item xs={12} md={3} key={index}>
                                        <Input
                                            label={nombre}
                                            icon={<AttachMoneyIcon />}
                                            value={precio[index]}
                                            onChange={(e) => handleChangePrecio(e, index)}
                                            type="tel"
                                            restricciones={['soloNumerosDecimales']}
                                            required
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }

                    <br />
                    {
                        configArticulos.configArticulos?.descripcion &&
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextEditor textoHtml={descripcion} setTextoHtml={setDescripcion} />
                            </Grid>
                        </Grid>
                    }
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}