import { React } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import TablaPaginada from '../../../elementos/TablaPaginada'
import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'

import { useGetEnvioMetodosPaginatedMutation } from '../../../../store/api/endpoints/config/configEnvioMetodosApiSlice'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import '../../../../assets/css/vistaFormulario.css'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

export default function ConfigEnvioMetodos() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    if (permisosDelUsuario.indexOf(42) == -1) return (<p>No tienes acceso a visualizar los métodos de envío.</p>)


    const thead = []
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "nombre" })

    let botones = []
    if (permisosDelUsuario.indexOf(42) > -1) botones.push({ color: "primary", icono: <EditIcon />, ruta: "/" + urlParams.dominio + '/config/envio-metodos/' })
    tbody.push({ tipo: "botones", valor: botones })

    if (permisosDelUsuario.indexOf(42) > -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Métodos de Envío</Typography>
                    </Breadcrumbs>
                }
                icon={<LocalShippingIcon />}
                end={(permisosDelUsuario.indexOf(42) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/config/envio-metodos/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetEnvioMetodosPaginatedMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}