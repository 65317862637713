import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Loading from '../../elementos/Loading'
import SinPermisos from '../auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetConfigClientesMutation, useUpdateConfigClientesMutation } from '../../../store/api/endpoints/config/configClientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import SwitchInput from '../../elementos/SwitchInput'
import Input from '../../elementos/Input'

import '../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider } from '@mui/material'

export default function ConfigClientes() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetConfigClientesMutation()


    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateConfigClientesMutation()


    const [solo_clientes, setSoloClientes] = useState(false)
    const [tipo_docActivo, setTipoDocActivo] = useState(false)
    const [tipo_docRequired, setTipoDocRequired] = useState(false)
    const [documentoActivo, setDocumentoActivo] = useState(false)
    const [documentoRequired, setDocumentoRequired] = useState(false)
    const [razon_socialActivo, setRazonSocialActivo] = useState(false)
    const [razon_socialRequired, setRazonSocialRequired] = useState(false)
    const [direccionActivo, setDireccionActivo] = useState(false)
    const [direccionRequired, setDireccionRequired] = useState(false)
    const [telefonoActivo, setTelefonoActivo] = useState(false)
    const [telefonoRequired, setTelefonoRequired] = useState(false)
    const [whatsappActivo, setWhatsappActivo] = useState(false)
    const [whatsappRequired, setWhatsappRequired] = useState(false)
    const [emailActivo, setEmailActivo] = useState(false)
    const [emailRequired, setEmailRequired] = useState(false)
    const [webActivo, setWebActivo] = useState(false)
    const [webRequired, setWebRequired] = useState(false)
    const [comentariosActivo, setComentariosActivo] = useState(false)
    const [comentariosRequired, setComentariosRequired] = useState(false)



    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            solo_clientes: solo_clientes,
            tipo_doc: {
                activo: tipo_docActivo,
                required: (tipo_docActivo ? tipo_docRequired : false),
            },
            documento: {
                activo: documentoActivo,
                required: (documentoActivo ? documentoRequired : false),
            },
            razon_social: {
                activo: razon_socialActivo,
                required: (razon_socialActivo ? razon_socialRequired : false),
            },
            direccion: {
                activo: direccionActivo,
                required: (direccionActivo ? direccionRequired : false),
            },
            telefono: {
                activo: telefonoActivo,
                required: (telefonoActivo ? telefonoRequired : false),
            },
            whatsapp: {
                activo: whatsappActivo,
                required: (whatsappActivo ? whatsappRequired : false),
            },
            email: {
                activo: emailActivo,
                required: (emailActivo ? emailRequired : false),
            },
            web: {
                activo: webActivo,
                required: (webActivo ? webRequired : false),
            },
            comentarios: {
                activo: comentariosActivo,
                required: (comentariosActivo ? comentariosRequired : false),
            },
        }

        updateRegistro(body)


    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        getConfig()

    }, [])

    useEffect(() => {

        if (isSuccessGetConfig && responseGetConfig) {
            setSoloClientes(responseGetConfig.solo_clientes)
            setTipoDocActivo(responseGetConfig.tipo_doc.activo)
            setTipoDocRequired(responseGetConfig.tipo_doc.required)
            setDocumentoActivo(responseGetConfig.documento.activo)
            setDocumentoRequired(responseGetConfig.documento.required)
            setRazonSocialActivo(responseGetConfig.razon_social.activo)
            setRazonSocialRequired(responseGetConfig.razon_social.required)
            setDireccionActivo(responseGetConfig.direccion.activo)
            setDireccionRequired(responseGetConfig.direccion.required)
            setTelefonoActivo(responseGetConfig.telefono.activo)
            setTelefonoRequired(responseGetConfig.telefono.required)
            setWhatsappActivo(responseGetConfig.whatsapp.activo)
            setWhatsappRequired(responseGetConfig.whatsapp.required)
            setEmailActivo(responseGetConfig.email.activo)
            setEmailRequired(responseGetConfig.email.required)
            setWebActivo(responseGetConfig.web.activo)
            setWebRequired(responseGetConfig.web.required)
            setComentariosActivo(responseGetConfig.comentarios.activo)
            setComentariosRequired(responseGetConfig.comentarios.required)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])


    if (permisosDelUsuario.indexOf(37) === -1) return <SinPermisos />

    if (
        isLoadingGetConfig ||
        isUninitializedGetConfig ||
        isLoadingUpdateRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(37) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="#">
                            Config.
                        </Link>
                        <Typography color="text.primary">Clientes</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>General</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Sólo Clientes"
                                value={solo_clientes}
                                onChange={setSoloClientes}
                                helperText="Indica si sólo podrán acceder a la web clientes registrados."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Tipo Documento</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={tipo_docActivo}
                                onChange={setTipoDocActivo}
                                helperText="Indica si los clientes tienen tipo de documento."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(tipo_docActivo ? tipo_docRequired : false)}
                                onChange={setTipoDocRequired}
                                helperText="Indica si el tipo de documento es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>N° Documento</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={documentoActivo}
                                onChange={setDocumentoActivo}
                                helperText="Indica si los clientes tienen N° de documento."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(documentoActivo ? documentoRequired : false)}
                                onChange={setDocumentoRequired}
                                helperText="Indica si el documento es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Razón Social</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={razon_socialActivo}
                                onChange={setRazonSocialActivo}
                                helperText="Indica si los clientes tienen razón social."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(razon_socialActivo ? razon_socialRequired : false)}
                                onChange={setRazonSocialRequired}
                                helperText="Indica si la razón social es obligatoria."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Dirección</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={direccionActivo}
                                onChange={setDireccionActivo}
                                helperText="Indica si los clientes tienen dirección."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(direccionActivo ? direccionRequired : false)}
                                onChange={setDireccionRequired}
                                helperText="Indica si la dirección es obligatoria."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Teléfono</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={telefonoActivo}
                                onChange={setTelefonoActivo}
                                helperText="Indica si los clientes tienen teléfono."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(telefonoActivo ? telefonoRequired : false)}
                                onChange={setTelefonoRequired}
                                helperText="Indica si el teléfono es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Whatsapp</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={whatsappActivo}
                                onChange={setWhatsappActivo}
                                helperText="Indica si los clientes tienen whatsapp."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(whatsappActivo ? whatsappRequired : false)}
                                onChange={setWhatsappRequired}
                                helperText="Indica si el whatsapp es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Email</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={emailActivo}
                                onChange={setEmailActivo}
                                helperText="Indica si los clientes tienen email."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(emailActivo ? emailRequired : false)}
                                onChange={setEmailRequired}
                                helperText="Indica si el email es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Web</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={webActivo}
                                onChange={setWebActivo}
                                helperText="Indica si los clientes tienen web."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(webActivo ? webRequired : false)}
                                onChange={setWebRequired}
                                helperText="Indica si la web es obligatoria."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Comentarios</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={comentariosActivo}
                                onChange={setComentariosActivo}
                                helperText="Indica si los clientes tienen comentarios."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(comentariosActivo ? comentariosRequired : false)}
                                onChange={setComentariosRequired}
                                helperText="Indica si los comentarios son obligatorios."
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}