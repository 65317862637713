import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Loading from '../../elementos/Loading'
import SinPermisos from '../auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetConfigServiciosMutation, useUpdateConfigServiciosMutation } from '../../../store/api/endpoints/config/configServiciosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import SwitchInput from '../../elementos/SwitchInput'
import Input from '../../elementos/Input'

import '../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider } from '@mui/material'

export default function ConfigServicios() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetConfigServiciosMutation()


    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateConfigServiciosMutation()


    const [solo_clientes, setSoloClientes] = useState(false)
    const [activo, setActivo] = useState(false)
    const [precio, setPrecio] = useState(false)
    const [descripcion, setDescripcion] = useState(false)
    const [img_principalActivo, setImgPrincipalActivo] = useState(false)
    const [img_principalHeight, setImgPrincipalHeight] = useState(0)
    const [img_principalWidth, setImgPrincipalWidth] = useState(0)

    const [categoriasActivo, setCategoriasActivo] = useState(false)
    const [cat_img_principalActivo, setCatImgPrincipalActivo] = useState(false)
    const [cat_img_principalHeight, setCatImgPrincipalHeight] = useState(0)
    const [cat_img_principalWidth, setCatImgPrincipalWidth] = useState(0)

    const [subcategoriasActivo, setSubcategoriasActivo] = useState(false)
    const [subcat_img_principalActivo, setSubcatImgPrincipalActivo] = useState(false)
    const [subcat_img_principalHeight, setSubcatImgPrincipalHeight] = useState(0)
    const [subcat_img_principalWidth, setSubcatImgPrincipalWidth] = useState(0)



    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            solo_clientes: solo_clientes,
            activo: activo,
            precio: precio,
            descripcion: descripcion,
            img_principal: {
                activo: img_principalActivo,
                height: img_principalHeight,
                width: img_principalWidth,
            },
            categorias: {
                activo: (activo ? categoriasActivo : false),
                img_principal: {
                    activo: (activo && categoriasActivo ? cat_img_principalActivo : false),
                    height: cat_img_principalHeight,
                    width: cat_img_principalWidth,
                }
            },
            subcategorias: {
                activo: (activo && categoriasActivo ? subcategoriasActivo : false),
                img_principal: {
                    activo: (activo && categoriasActivo && subcategoriasActivo ? subcat_img_principalActivo : false),
                    height: subcat_img_principalHeight,
                    width: subcat_img_principalWidth,
                }
            },
        }

        updateRegistro(body)


    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        getConfig()

    }, [])

    useEffect(() => {

        if (isSuccessGetConfig && responseGetConfig.activo) {
            setSoloClientes(responseGetConfig.solo_clientes)
            setActivo(responseGetConfig.activo)
            setPrecio(responseGetConfig.precio)
            setDescripcion(responseGetConfig.descripcion)
            setPrecio(responseGetConfig.precio)
            setImgPrincipalActivo(responseGetConfig.img_principal.activo)
            setImgPrincipalHeight(responseGetConfig.img_principal.height)
            setImgPrincipalWidth(responseGetConfig.img_principal.width)

            setCategoriasActivo(responseGetConfig.categorias.activo)
            setCatImgPrincipalActivo(responseGetConfig.categorias.img_principal.activo)
            setCatImgPrincipalHeight(responseGetConfig.categorias.img_principal.height)
            setCatImgPrincipalWidth(responseGetConfig.categorias.img_principal.width)

            setSubcategoriasActivo(responseGetConfig.subcategorias.activo)
            setSubcatImgPrincipalActivo(responseGetConfig.subcategorias.img_principal.activo)
            setSubcatImgPrincipalHeight(responseGetConfig.subcategorias.img_principal.height)
            setSubcatImgPrincipalWidth(responseGetConfig.subcategorias.img_principal.width)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])


    if (permisosDelUsuario.indexOf(30) === -1) return <SinPermisos />

    if (
        isLoadingGetConfig ||
        isUninitializedGetConfig ||
        isLoadingUpdateRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(30) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="#">
                            Config.
                        </Link>
                        <Typography color="text.primary">Servicios</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Servicios</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setActivo}
                                value={activo}
                                helperText="Indica si la tienda utiliza servicios."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Sólo Clientes"
                                onChange={setSoloClientes}
                                value={solo_clientes}
                                helperText="Indica si sólo los clientes pueden visualizar los servicios."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Descripción"
                                onChange={setDescripcion}
                                value={(activo ? descripcion : false)}
                                helperText="Indica si los servicios tienen una descripción."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Precios"
                                onChange={setPrecio}
                                value={(activo ? precio : false)}
                                helperText="Indica si los servicios tienen un precio."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setImgPrincipalActivo}
                                value={(activo ? img_principalActivo : false)}
                                helperText="Indica si los servicios tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setImgPrincipalWidth}
                                value={(activo ? img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && img_principalActivo}
                                disabled={!activo || !img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setImgPrincipalHeight}
                                value={(activo ? img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && img_principalActivo}
                                disabled={!activo || !img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Categorías</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setCategoriasActivo}
                                value={(activo ? categoriasActivo : false)}
                                helperText="Indica si los servicios tienen categoría."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setCatImgPrincipalActivo}
                                value={(activo && categoriasActivo ? cat_img_principalActivo : false)}
                                helperText="Indica si las categorías tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setCatImgPrincipalWidth}
                                value={(activo && categoriasActivo && cat_img_principalActivo ? cat_img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && cat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !cat_img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setCatImgPrincipalHeight}
                                value={(activo && categoriasActivo && cat_img_principalActivo ? cat_img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && cat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !cat_img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Subcategorías</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setSubcategoriasActivo}
                                value={(activo && categoriasActivo ? subcategoriasActivo : false)}
                                helperText="Indica si los servicios tienen categoría."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setSubcatImgPrincipalActivo}
                                value={(activo && categoriasActivo && subcategoriasActivo ? subcat_img_principalActivo : false)}
                                helperText="Indica si las subcategorías tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setSubcatImgPrincipalWidth}
                                value={(activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo ? subcat_img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !subcategoriasActivo || !subcat_img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setSubcatImgPrincipalHeight}
                                value={(activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo ? subcat_img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !subcategoriasActivo || !subcat_img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}