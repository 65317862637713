import { useState, useEffect } from 'react'

import { NumericFormat } from 'react-number-format'
import Moment from 'react-moment'

import Loading from '../Loading'
import ButtonIcon from '../ButtonIcon'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'

import '../../../assets/css/table.css'

import CircleIcon from '@mui/icons-material/Circle'

export default function TablaPaginada2({
    FiltrosCustomizado,
    api,
    thead,
    tbody,
    columnasOptionsGet,
    autoCompleteAsync,
    configLocalstorage,
    configTable,
}) {

    const [page, setPage] = useState(1)
    const [textsearch, setTextsearch] = useState("")
    const [filtros, setFiltros] = useState(configTable.filtros)
    const [bloqueFechas, setBloqueFechas] = useState(configTable.from + "/" + configTable.to)
    const [columnasOptions, setColumnasOptions] = useState({
        visibles: configTable.visibles,
        orderBy: configTable.orderBy,
        getColumnas: columnasOptionsGet
    })

    const [
        getRegistros,
        {
            data: returnedData,
            isUninitialized,
            isLoading,
            isSuccess,
            isError,
        }
    ] = api()


    useEffect(() => {

        getRegistros({
            page,
            textsearch,
            from: bloqueFechas.split("/")[0],
            to: bloqueFechas.split("/")[1],
            orderby: JSON.stringify(columnasOptions.orderBy),
            filtros: JSON.stringify(filtros),
        })

    }, [page, textsearch, filtros, columnasOptions.orderBy, bloqueFechas])

    if (isLoading || isUninitialized) return (<Loading />)

    localStorage.setItem(configLocalstorage, JSON.stringify({
        page,
        textsearch,
        filtros: filtros,
        from: bloqueFechas.split("/")[0],
        to: bloqueFechas.split("/")[1],
        orderBy: columnasOptions.orderBy,
        visibles: columnasOptions.visibles,
    }))


    return (
        <Container className="containerBigTable" component="main" maxWidth="lg">
            {
                FiltrosCustomizado &&
                <FiltrosCustomizado

                    textsearch={textsearch}
                    setTextsearch={setTextsearch}

                    filtros={filtros}
                    setFiltros={setFiltros}

                    bloqueFechas={bloqueFechas}
                    setBloqueFechas={setBloqueFechas}

                    columnasOptions={columnasOptions}
                    setColumnasOptions={setColumnasOptions}

                    autoCompleteAsync={autoCompleteAsync}

                />
            }
            <div className="tableContainer">
                <table className="formato1" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            {
                                thead.map((element, index) => {

                                    let orden = ""
                                    for (let i = 0; i < columnasOptions.orderBy.length; i++) {
                                        if (columnasOptions.orderBy[i][0] == index) {
                                            orden = (columnasOptions.orderBy[i][1] == 1 ? "↑" : "↓")
                                        }
                                    }

                                    if (columnasOptions.visibles[index]) {
                                        return (
                                            <th key={index} align={element.align}>{element.label} {orden}</th>
                                        )
                                    }
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            returnedData?.totalItems > 0 && returnedData.registros.map((registro, index) => {

                                let styleEstadoTd = { backgroundColor: registro.estadoFondo, color: registro.estadoTexto }

                                return (
                                    <tr key={index}>
                                        {
                                            tbody.map((elemento, indexTd) => {

                                                if (columnasOptions.visibles[indexTd]) {

                                                    return (
                                                        <td key={indexTd} align={thead[indexTd].align} style={(elemento.tipo === 'estadoRegistro' ? styleEstadoTd : {})}>
                                                            {elemento.tipo === "img" && <img src={registro[elemento.valor]} height="35" />}
                                                            {elemento.tipo === "texto" && registro[elemento.valor]}
                                                            {elemento.tipo === "fecha" &&
                                                                <Moment
                                                                    format="DD/MM/YYYY"
                                                                    date={registro[elemento.valor]}
                                                                />
                                                            }
                                                            {elemento.tipo === "fechahora" &&
                                                                <Moment
                                                                    format="DD/MM/YYYY HH:mm:ss"
                                                                    date={registro[elemento.valor]}
                                                                />
                                                            }
                                                            {elemento.tipo === "precio" &&
                                                                <NumericFormat
                                                                    value={registro[elemento.valor]}
                                                                    className="foo"
                                                                    displayType={'text'}
                                                                    decimalSeparator={elemento.options[0]}
                                                                    thousandSeparator={elemento.options[1]}
                                                                    prefix={elemento.options[2]}
                                                                />
                                                            }
                                                            {elemento.tipo === "estado" && <CircleIcon style={{ fontSize: '0.9rem', color: (registro[elemento.valor] ? 'green' : 'red') }} />}
                                                            {elemento.tipo === "estadoRegistro" && <span style={{ fontSize: '0.9rem' }}>{registro[elemento.valor]}</span>}
                                                            {elemento.tipo === "botones" && elemento.valor.map((unBoton, indexBoton) => (
                                                                <ButtonIcon
                                                                    key={indexBoton}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    color={unBoton.color}
                                                                    startIcon={unBoton.icono}
                                                                    to={unBoton.ruta + registro.id}
                                                                    marginRight
                                                                />
                                                            ))}
                                                        </td>
                                                    )

                                                }

                                            })
                                        }
                                    </tr>
                                )


                            })
                        }
                        {
                            returnedData?.totalItems === 0 &&
                            <tr><td colSpan={thead.length}>No se encontraron registros.</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <Grid container>
                <Grid className="titulo" item xs={12} md={8}>
                    <Pagination
                        className="table-pagination"
                        showFirstButton showLastButton
                        shape="rounded"
                        count={returnedData?.pages}
                        page={returnedData?.page * 1}
                        onChange={(event, value) => setPage(value)}
                    />
                </Grid>
                <Grid item xs={12} md={4} >
                    <p>Se encontraron {returnedData?.totalItems} registros.</p>
                </Grid>
            </Grid>


        </Container>
    )

}