import { tiendasApi } from '../../api'

export const configVentasApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getConfigVentas: builder.mutation({
            query: () => ({
                url: '/config/ventas',
                method: 'GET',
            })
        }),
        updateConfigVentas: builder.mutation({
            query(body){
                return {
                    url: '/config/ventas',
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useGetConfigVentasMutation,
    useUpdateConfigVentasMutation,
} = configVentasApiSlice