import { React } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'


import HeaderVista from '../../elementos/HeaderVista'

import ConfigReportesPdfPedidos from './reportes-pdf/ConfigReportesPdfPedidos'

import '../../../assets/css/vistaFormulario.css'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import SettingsIcon from '@mui/icons-material/Settings'

export default function ConfigReportesPdf() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    if (permisosDelUsuario.indexOf(58) == -1) return (<p>No tienes acceso a visualizar los reportes PDF.</p>)

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="#">
                            Config.
                        </Link>
                        <Typography color="text.primary">Reportes PDF</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
            />
            <Container component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} >
                        <ConfigReportesPdfPedidos />
                    </Grid>
                    <Grid item xs={12} md={4} >
                        
                    </Grid>
                </Grid>
            </Container>
        </>
    )

}