import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { useGetClientesLoginsMutation } from '../../../../store/api/endpoints/clientes/clientesLoginsApiSlice'

import { GetClienteNombre } from '../clientes/GetClienteNombre'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'


export default function ClientesLogins() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    if (permisosDelUsuario.indexOf(50) == -1) return (<p>No tienes acceso a visualizar los usuarios.</p>)

    const thead = []
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Email", align: "left" })
    thead.push({ label: "Teléfono", align: "left" })
    thead.push({ label: "Whatsapp", align: "left" })
    thead.push({ label: "Ult. Login", align: "center" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "email" })
    tbody.push({ tipo: "texto", valor: "tel_fijo" })
    tbody.push({ tipo: "texto", valor: "tel_whatsapp" })
    tbody.push({ tipo: "fechahora", valor: "ult_login" })

    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/" + urlParams.dominio + '/ventas/clientes-logins/' + urlParams.idCliente + '/' })
    tbody.push({ tipo: "botones", valor: botones })

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/"}>
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.idCliente}>
                            <GetClienteNombre idCliente={urlParams.idCliente} />
                        </Link>
                        <Typography color="text.primary">Usuarios</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
                end={(permisosDelUsuario.indexOf(51) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/ventas/clientes-logins/" + urlParams.idCliente + "/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetClientesLoginsMutation}
                thead={thead}
                tbody={tbody}
                parametroEnUrl={urlParams.idCliente}
            />
        </>
    )

}

