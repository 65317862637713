import { createSlice } from "@reduxjs/toolkit"


export const configSlice = createSlice({
    name: 'config',
    initialState: {
        
    },
    reducers: {
        setStateConfigConfig: (state, action) => {
            state.config = action.payload
        },
    }
})

export const { setStateConfigConfig } = configSlice.actions

export const getStateConfig = (state) => state.config

