
export const pedidoArticulos = [
    {
        variable: "${ARTICULO_CANTIDAD}",
    },
    {
        variable: "${ARTICULO_TITULO}",
    },
    {
        variable: "${ARTICULO_PRECIOUNITARIO}",
    },
    {
        variable: "${ARTICULO_SUBTOTAL}",
    },
]

export const pedidoBody = [
    {
        variable: "${ARTICULOS}",
    },
    {
        variable: "${LOGO}",
    },
    {
        variable: "${PEDIDO_NUMERO}",
    },
    {
        variable: "${PEDIDO_FECHA}",
    },
    {
        variable: "${PEDIDO_DIRECCION}",
    },
    {
        variable: "${PEDIDO_LOCALIDAD}",
    },
    {
        variable: "${PEDIDO_CODIGOPOSTAL}",
    },
    {
        variable: "${PEDIDO_PROVINCIA}",
    },
    {
        variable: "${PEDIDO_PAIS}",
    },
    {
        variable: "${PEDIDO_ENVIO}",
    },
    {
        variable: "${PEDIDO_VENDEDOR}",
    },
    {
        variable: "${CLIENTE_NOMBRE}",
    },
    {
        variable: "${CLIENTE_EMAIL}",
    },
    {
        variable: "${CLIENTE_WHATSAPP}",
    },
    {
        variable: "${CLIENTE_TELFIJO}",
    },
    {
        variable: "${CLIENTE_DOCUMENTO}",
    },
    {
        variable: "${PEDIDO_OBSERVACION}",
    },
    {
        variable: "${PEDIDO_TOTAL}",
    },
    {
        variable: "${MARCA_NOMBRE}",
    },
    {
        variable: "${MARCA_TELFIJO}",
    },
    {
        variable: "${MARCA_WHATSAPP}",
    },
    {
        variable: "${MARCA_DIRECCION}",
    },
    {
        variable: "${MARCA_MAPA}",
    },
    {
        variable: "${MARCA_EMAIL}",
    },
]

