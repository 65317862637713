import { tiendasApi } from '../../api'

export const clientesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientes: builder.mutation({
            query: (headersTemp) => ({
                url: '/ventas/clientes/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getForAutocomplete: builder.mutation({
            query: (headersTemp) => ({
                url: '/ventas/clientes/autocomplete',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getCliente: builder.mutation({
            query: (id) => ({
                url: '/ventas/clientes/' + id,
                method: 'GET',
            })
        }),
        getNombreCliente: builder.mutation({
            query: (id) => ({
                url: '/ventas/clientes/' + id + '/nombre',
                method: 'GET',
            })
        }),
        createCliente: builder.mutation({
            query: (body) => ({
                url: '/ventas/clientes',
                method: 'POST',
                body: body,
            })
        }),
        updateCliente: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/ventas/clientes/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeCliente: builder.mutation({
            query: (id) => ({
                url: '/ventas/clientes/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetClientesMutation,
    useGetForAutocompleteMutation,
    useGetClienteMutation,
    useGetNombreClienteMutation,
    useCreateClienteMutation,
    useUpdateClienteMutation,
    useRemoveClienteMutation,
} = clientesApiSlice