import { tiendasApi } from '../../api'

export const configEnvioMetodosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getEnvioMetodosPaginated: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/envio-metodos/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getEnvioMetodosAll: builder.mutation({
            query: (headersTemp) => ({
                url: '/config/envio-metodos/all',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getEnvioMetodos: builder.mutation({
            query: (id) => ({
                url: '/config/envio-metodos/' + id,
                method: 'GET',
            })
        }),
        createEnvioMetodos: builder.mutation({
            query: (body) => ({
                url: '/config/envio-metodos',
                method: 'POST',
                body: body,
            })
        }),
        updateEnvioMetodos: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/config/envio-metodos/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeEnvioMetodos: builder.mutation({
            query: (id) => ({
                url: '/config/envio-metodos/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetEnvioMetodosPaginatedMutation,
    useGetEnvioMetodosAllMutation,
    useGetEnvioMetodosMutation,
    useCreateEnvioMetodosMutation,
    useUpdateEnvioMetodosMutation,
    useRemoveEnvioMetodosMutation,
} = configEnvioMetodosApiSlice