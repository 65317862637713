import { tiendasApi } from '../../api'

export const configArticulosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getConfigArticulos: builder.mutation({
            query: () => ({
                url: '/config/articulos',
                method: 'GET',
            })
        }),
        updateConfigArticulos: builder.mutation({
            query(body){
                return {
                    url: '/config/articulos',
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useGetConfigArticulosMutation,
    useUpdateConfigArticulosMutation,
} = configArticulosApiSlice