import { tiendasApi } from '../../api'

export const servcatApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getServCategorias: builder.mutation({
            query: (headersTemp) => ({
                url: '/servcat/paginated',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getServCategoriasForSelect: builder.mutation({
            query: () => ({
                url: '/servcat/all',
                method: 'GET',
            })
        }),
        getServCategoria: builder.mutation({
            query: (id) => ({
                url: '/servcat/' + id,
                method: 'GET',
            })
        }),
        createServCategoria: builder.mutation({
            query: (body) => ({
                url: '/servcat',
                method: 'POST',
                body: body,
            })
        }),
        updateServCategoria: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/servcat/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeServCategoria: builder.mutation({
            query: (id) => ({
                url: '/servcat/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetServCategoriasMutation,
    useGetServCategoriasForSelectMutation,
    useGetServCategoriaMutation,
    useCreateServCategoriaMutation,
    useUpdateServCategoriaMutation,
    useRemoveServCategoriaMutation,
} = servcatApiSlice