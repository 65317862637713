import { createSlice } from "@reduxjs/toolkit"


export const configVentasSlice = createSlice({
    name: 'configVentas',
    initialState: {
        
    },
    reducers: {
        setStateConfigVentas: (state, action) => {
            state.configVentas = action.payload
        },
    }
})

export const { setStateConfigVentas } = configVentasSlice.actions

export const getStateConfigVentas = (state) => state.configVentas

