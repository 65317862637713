import { tiendasApi } from '../../api'

export const configUsuariosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getConfigUsuarios: builder.mutation({
            query: () => ({
                url: '/config/usuarios',
                method: 'GET',
            })
        }),
        updateConfigUsuarios: builder.mutation({
            query(body){
                return {
                    url: '/config/usuarios',
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useGetConfigUsuariosMutation,
    useUpdateConfigUsuariosMutation,
} = configUsuariosApiSlice