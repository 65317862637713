import { React, useEffect } from 'react'
import { Outlet } from "react-router-dom"
import { useDispatch } from 'react-redux'


import { useGetSidebarDataMutation } from '../../../store/api/endpoints/sidebarDataApiSlice'
import { setStateSidebarData } from '../../../store/slices/sidebar'

import { useGetConfigUsuariosMutation } from '../../../store/api/endpoints/config/configUsuariosApiSlice'
import { setStateConfigUsuarios } from '../../../store/slices/configUsuarios'

import { useGetConfigServiciosMutation } from '../../../store/api/endpoints/config/configServiciosApiSlice'
import { setStateConfigServicios } from '../../../store/slices/configServicios'

import { useGetConfigArticulosMutation } from '../../../store/api/endpoints/config/configArticulosApiSlice'
import { setStateConfigArticulos } from '../../../store/slices/configArticulos'

import { useGetConfigClientesMutation } from '../../../store/api/endpoints/config/configClientesApiSlice'
import { setStateConfigClientes } from '../../../store/slices/configClientes'

import { useGetConfigVentasMutation } from '../../../store/api/endpoints/config/configVentasApiSlice'
import { setStateConfigVentas } from '../../../store/slices/configVentas'

import { useGetConfigMutation } from '../../../store/api/endpoints/config/configConfigApiSlice'
import { setStateConfigConfig } from '../../../store/slices/config'

import { useGetEnvioMetodosAllMutation } from '../../../store/api/endpoints/config/configEnvioMetodosApiSlice'
import { useGetEstadosPedidoAllMutation } from '../../../store/api/endpoints/config/configEstadosPedidoApiSlice'
import { useGetPagoMetodoAllMutation } from '../../../store/api/endpoints/config/configPagoMetodoApiSlice'
import { useGetPagoPlazosAllMutation } from '../../../store/api/endpoints/config/configPagoPlazosApiSlice'
import { setStateConfigDefaultsClientes } from '../../../store/slices/configDefaultsClientes'


import Loading from '../../elementos/Loading'

const Config = () => {

    const dispatch = useDispatch()
    

    const [
        getSidebar,
        {
            data: responseGetSidebar,
            isUninitialized: isUninitializedGetSidebar,
            isLoading: isLoadingGetSidebar,
            isSuccess: isSuccessGetSidebar,
            isError: isErrorGetSidebar,
        }
    ] = useGetSidebarDataMutation()

    const [
        getConfigUsuarios,
        {
            data: responseGetConfigUsuarios,
            isUninitialized: isUninitializedGetConfigUsuarios,
            isLoading: isLoadingGetConfigUsuarios,
            isSuccess: isSuccessGetConfigUsuarios,
            isError: isErrorGetConfigUsuarios,
        }
    ] = useGetConfigUsuariosMutation()

    const [
        getConfigServicios,
        {
            data: responseGetConfigServicios,
            isUninitialized: isUninitializedGetConfigServicios,
            isLoading: isLoadingGetConfigServicios,
            isSuccess: isSuccessGetConfigServicios,
            isError: isErrorGetConfigServicios,
        }
    ] = useGetConfigServiciosMutation()

    const [
        getConfigArticulos,
        {
            data: responseGetConfigArticulos,
            isUninitialized: isUninitializedGetConfigArticulos,
            isLoading: isLoadingGetConfigArticulos,
            isSuccess: isSuccessGetConfigArticulos,
            isError: isErrorGetConfigArticulos,
        }
    ] = useGetConfigArticulosMutation()

    const [
        getConfigClientes,
        {
            data: responseGetConfigClientes,
            isUninitialized: isUninitializedGetConfigClientes,
            isLoading: isLoadingGetConfigClientes,
            isSuccess: isSuccessGetConfigClientes,
            isError: isErrorGetConfigClientes,
        }
    ] = useGetConfigClientesMutation()

    const [
        getConfigVentas,
        {
            data: responseGetConfigVentas,
            isUninitialized: isUninitializedGetConfigVentas,
            isLoading: isLoadingGetConfigVentas,
            isSuccess: isSuccessGetConfigVentas,
            isError: isErrorGetConfigVentas,
        }
    ] = useGetConfigVentasMutation()

    const [
        getConfigConfig,
        {
            data: responseGetConfigConfig,
            isUninitialized: isUninitializedGetConfigConfig,
            isLoading: isLoadingGetConfigConfig,
            isSuccess: isSuccessGetConfigConfig,
            isError: isErrorGetConfigConfig,
        }
    ] = useGetConfigMutation()

    const [
        getConfigEnvioMetodos,
        {
            data: responseGetConfigEnvioMetodos,
            isUninitialized: isUninitializedGetConfigEnvioMetodos,
            isLoading: isLoadingGetConfigEnvioMetodos,
            isSuccess: isSuccessGetConfigEnvioMetodos,
            isError: isErrorGetConfigEnvioMetodos,
        }
    ] = useGetEnvioMetodosAllMutation()

    const [
        getConfigEstadosPedido,
        {
            data: responseGetConfigEstadosPedido,
            isUninitialized: isUninitializedGetConfigEstadosPedido,
            isLoading: isLoadingGetConfigEstadosPedido,
            isSuccess: isSuccessGetConfigEstadosPedido,
            isError: isErrorGetConfigEstadosPedido,
        }
    ] = useGetEstadosPedidoAllMutation()

    const [
        getConfigPagoMetodo,
        {
            data: responseGetConfigPagoMetodo,
            isUninitialized: isUninitializedGetConfigPagoMetodo,
            isLoading: isLoadingGetConfigPagoMetodo,
            isSuccess: isSuccessGetConfigPagoMetodo,
            isError: isErrorGetConfigPagoMetodo,
        }
    ] = useGetPagoMetodoAllMutation()

    const [
        getConfigPagoPlazos,
        {
            data: responseGetConfigPagoPlazos,
            isUninitialized: isUninitializedGetConfigPagoPlazos,
            isLoading: isLoadingGetConfigPagoPlazos,
            isSuccess: isSuccessGetConfigPagoPlazos,
            isError: isErrorGetConfigPagoPlazos,
        }
    ] = useGetPagoPlazosAllMutation()

    useEffect(() => {
        getSidebar()
        getConfigUsuarios()
        getConfigServicios()
        getConfigArticulos()
        getConfigClientes()
        getConfigVentas()
        getConfigConfig()

        getConfigEnvioMetodos()
        getConfigEstadosPedido()
        getConfigPagoMetodo()
        getConfigPagoPlazos()
        
    }, [])

    useEffect(() => {

        if (isSuccessGetSidebar) {
            dispatch(setStateSidebarData(responseGetSidebar))
        }

    }, [isSuccessGetSidebar, isErrorGetSidebar])

    useEffect(() => {

        if (isSuccessGetConfigUsuarios) {
            dispatch(setStateConfigUsuarios(responseGetConfigUsuarios))
        }

    }, [isSuccessGetConfigUsuarios, isErrorGetConfigUsuarios])

    useEffect(() => {

        if (isSuccessGetConfigServicios) {
            dispatch(setStateConfigServicios(responseGetConfigServicios))
        }

    }, [isSuccessGetConfigServicios, isErrorGetConfigServicios])

    useEffect(() => {

        if (isSuccessGetConfigArticulos) {
            dispatch(setStateConfigArticulos(responseGetConfigArticulos))
        }

    }, [isSuccessGetConfigArticulos, isErrorGetConfigArticulos])

    useEffect(() => {

        if (isSuccessGetConfigClientes) {
            dispatch(setStateConfigClientes(responseGetConfigClientes))
        }

    }, [isSuccessGetConfigClientes, isErrorGetConfigClientes])

    useEffect(() => {

        if (isSuccessGetConfigVentas) {
            dispatch(setStateConfigVentas(responseGetConfigVentas))
        }

    }, [isSuccessGetConfigVentas, isErrorGetConfigVentas])

    useEffect(() => {

        if (isSuccessGetConfigConfig) {
            dispatch(setStateConfigConfig(responseGetConfigConfig))
        }

    }, [isSuccessGetConfigConfig, isErrorGetConfigConfig])

    useEffect(() => {

        if (isSuccessGetConfigEnvioMetodos && isSuccessGetConfigEstadosPedido && isSuccessGetConfigPagoMetodo && isSuccessGetConfigPagoPlazos) {
            dispatch(setStateConfigDefaultsClientes({
                envioMetodos: responseGetConfigEnvioMetodos,
                estadosPedido: responseGetConfigEstadosPedido,
                pagoMetodo: responseGetConfigPagoMetodo,
                pagoPlazos: responseGetConfigPagoPlazos,
            }))
        }

    }, [isSuccessGetConfigEnvioMetodos, isSuccessGetConfigEstadosPedido, isSuccessGetConfigPagoMetodo, isSuccessGetConfigPagoPlazos])
    

    if (
        isUninitializedGetSidebar || 
        isLoadingGetSidebar ||
        isUninitializedGetConfigServicios ||
        isLoadingGetConfigServicios || 
        isUninitializedGetConfigUsuarios || 
        isLoadingGetConfigUsuarios || 
        isUninitializedGetConfigArticulos || 
        isLoadingGetConfigArticulos ||
        isUninitializedGetConfigClientes || 
        isLoadingGetConfigClientes || 
        isUninitializedGetConfigVentas || 
        isLoadingGetConfigVentas || 
        isUninitializedGetConfigConfig || 
        isLoadingGetConfigConfig ||

        isUninitializedGetConfigEnvioMetodos || 
        isLoadingGetConfigEnvioMetodos || 
        isUninitializedGetConfigEstadosPedido || 
        isLoadingGetConfigEstadosPedido || 
        isUninitializedGetConfigPagoMetodo || 
        isLoadingGetConfigPagoMetodo || 
        isUninitializedGetConfigPagoPlazos || 
        isLoadingGetConfigPagoPlazos
    ) return (<Loading />)

    return (<Outlet />)
}

export default Config