import { tiendasApi } from '../../api'

export const permisosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPermisos: builder.mutation({
            query: (headersTemp) => ({
                url: '/permisos/all',
                method: 'GET',
                headers: headersTemp,
            })
        }),
    })
})

export const {
    useGetPermisosMutation,
} = permisosApiSlice