import { React, forwardRef, useEffect, useState } from 'react';

import ButtonIcon from '../ButtonIcon'
import Select from '../Select'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Switch from '@mui/material/Switch'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import { Divider } from '@mui/material'

import SwapVertIcon from '@mui/icons-material/SwapVert'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

export default function TablaElegirColumnas({ columnasOptions, setColumnasOptions }) {

    const [
        getColumnas,
        {
            data: responseColumnas,
            isUninitialized: isUninitializedColumnas,
            isLoading: isLoadingColumnas,
            isSuccess: isSuccessColumnas,
            isError: isErrorColumnas,
        }
    ] = columnasOptions.getColumnas()

    const [open, setOpen] = useState(false)
    const [columnasTotales, setColumnasTotales] = useState([])
    const [orderByLocal, setOrderByLocal] = useState(columnasOptions.orderBy)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleToggle = (value) => {

        let temp = []
        for (let i = 0; i < columnasOptions.visibles.length; i++) {

            if (value == i) {
                temp.push(!columnasOptions.visibles[i])
            } else {
                temp.push(columnasOptions.visibles[i])
            }

        }

        setColumnasOptions({
            ...columnasOptions,
            visibles: temp
        })

    }

    const handleOrderByColumn = (e, indiceOrderBy) => {

        let orderByTemp = []
        for (let i = 0; i < orderByLocal.length; i++) {

            if (i == indiceOrderBy) {
                orderByTemp.push([
                    e,
                    orderByLocal[i][1]
                ])
            } else {
                orderByTemp.push(orderByLocal[i])
            }

        }

        setOrderByLocal(orderByTemp)

    }

    const handleOrderByColumnOpt = (e, indiceOrderBy) => {

        let orderByTemp = []
        for (let i = 0; i < orderByLocal.length; i++) {

            if (i == indiceOrderBy) {
                orderByTemp.push([
                    orderByLocal[i][0],
                    e
                ])
            } else {
                orderByTemp.push(orderByLocal[i])
            }

        }

        setOrderByLocal(orderByTemp)

    }

    const crearOrdenColumnas = () => {

        let orderByTemp = []
        for (let i = 0; i < orderByLocal.length; i++) {

            orderByTemp.push(orderByLocal[i])

        }
        orderByTemp.push([1, 1])

        setOrderByLocal(orderByTemp)

    }

    const eliminarOrdenColumna = (e, index) => {

        let orderByTemp = []
        for (let i = 0; i < orderByLocal.length; i++) {

            if (i != index) {
                orderByTemp.push(orderByLocal[i])
            }

        }

        setOrderByLocal(orderByTemp)

    }

    const aplicarOrdenColumnas = () => {

        setColumnasOptions({
            ...columnasOptions,
            orderBy: orderByLocal
        })

    }

    useEffect(() => {

        getColumnas()

    }, [])

    useEffect(() => {

        if (isSuccessColumnas) {
            setColumnasTotales(responseColumnas)
        }

    }, [isSuccessColumnas, isErrorColumnas])

    return (
        <>

            <ButtonIcon
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<SwapVertIcon />}
                noLink
                handleClick={handleClickOpen}
            >
                Columnas
            </ButtonIcon>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    Columnas
                </DialogTitle>
                <DialogContent>
                    <Divider />
                    <Container className="containerBigTable" component="main" maxWidth="lg">
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <h4>Mostrar Columnas</h4>
                                <List>
                                    {
                                        columnasTotales.map((item, index) => {
                                            return (
                                                <ListItem key={index}>
                                                    <ListItemText id="switch-list-label-wifi" primary={item.label} />
                                                    <Switch
                                                        edge="end"
                                                        onChange={() => handleToggle(item.value)}
                                                        checked={columnasOptions.visibles[item.value]}
                                                        inputProps={{
                                                            'aria-labelledby': 'switch-list-label-wifi',
                                                        }}
                                                    />
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            </Grid>
                            <Grid item xs={12} md={2}></Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <h4>Ordenar por</h4>
                                        {
                                            orderByLocal.map((orderColumn, orderColumnIndex) => (
                                                <div key={orderColumnIndex}>
                                                    <Grid container spacing={1} style={{ marginTop: '15px' }}>
                                                        <Grid item xs={6}>
                                                            {orderColumnIndex + 1}° Orden
                                                        </Grid>
                                                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                                                            {
                                                                orderColumnIndex > 0 &&
                                                                <ButtonIcon
                                                                    noLink
                                                                    variant="outlined"
                                                                    startIcon={<DeleteIcon />}
                                                                    color="error"
                                                                    handleClick={e => eliminarOrdenColumna(e, orderColumnIndex)}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Select
                                                        label="Ordenar Por"
                                                        value={orderColumn[0]}
                                                        options={columnasTotales.map((item, index) => ({
                                                            value: item.value,
                                                            label: item.label
                                                        }))}
                                                        onChange={e => handleOrderByColumn(e, orderColumnIndex)}
                                                    />
                                                    <Select
                                                        label="¿Ascendente o Descendente?"
                                                        value={orderColumn[1]}
                                                        options={[
                                                            {
                                                                value: 1,
                                                                label: 'Asendente'
                                                            },
                                                            {
                                                                value: 2,
                                                                label: 'Descendente'
                                                            }
                                                        ]}
                                                        onChange={e => handleOrderByColumnOpt(e, orderColumnIndex)}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p align="center">
                                            <ButtonIcon
                                                noLink
                                                variant="contained"
                                                color="dark"
                                                startIcon={<AddIcon />}
                                                handleClick={crearOrdenColumnas}
                                                marginRight
                                            >
                                                Nuevo Orden
                                            </ButtonIcon>
                                            <ButtonIcon
                                                noLink
                                                variant="contained"
                                                color="success"
                                                startIcon={<CheckIcon />}
                                                handleClick={aplicarOrdenColumnas}
                                            >
                                                Aplicar Orden
                                            </ButtonIcon>
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <ButtonIcon
                        handleClick={handleClose}
                        noLink
                    >
                        Cerrar
                    </ButtonIcon>
                </DialogActions>
            </Dialog>
        </>
    )

}