import { createSlice } from "@reduxjs/toolkit"


export const configServiciosSlice = createSlice({
    name: 'configServicios',
    initialState: {
        
    },
    reducers: {
        setStateConfigServicios: (state, action) => {
            state.configServicios = action.payload
        },
    }
})

export const { setStateConfigServicios } = configServiciosSlice.actions

export const getStateConfigServicios = (state) => state.configServicios

