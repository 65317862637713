import { React, useState } from 'react';
import { SketchPicker } from 'react-color';

import '../../assets/css/Input.css'

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export default function Input(props) {

    const handleChangeColor = (color, event) => {

        props.onChange(color.hex)

    }

    return (
        <SketchPicker
            color={props.value}
            onChangeComplete={handleChangeColor}
        />
    )

}