import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetArtCategoriasForSelectMutation } from '../../../../store/api/endpoints/articulos/artcatApiSlice'
import { useCreateArtSubcategoriaMutation } from '../../../../store/api/endpoints/articulos/artsubcatApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import CropImage from '../../../elementos/CropImage'

import '../../../../assets/css/vistaFormulario.css'

import BookmarksIcon from '@mui/icons-material/Bookmarks'
import CheckIcon from '@mui/icons-material/Check'

export default function SubcategoriaNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)

    const [
        getCategorias,
        {
            data: responseGetCategorias,
            isUninitialized: isUninitializedGetCategorias,
            isLoading: isLoadingGetCategorias,
            isSuccess: isSuccessGetCategorias,
            isError: isErrorGetCategorias,
        }
    ] = useGetArtCategoriasForSelectMutation()

    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreateArtSubcategoriaMutation()


    const [nombre, setNombre] = useState('')
    const [imgPrincipal, setImgPrincipal] = useState('')
    const [categoria, setCategoria] = useState('')
    const [categoriasOptions, setCategoriasOptions] = useState([])


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            categoria,
            img_principal: imgPrincipal,
        }

        createRegistro(body)


    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/articulos/subcategorias")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    useEffect(() => {

        getCategorias()

    }, [])

    useEffect(() => {

        if (isSuccessGetCategorias) {
            setCategoriasOptions(responseGetCategorias)
        }

    }, [isSuccessGetCategorias, isErrorGetCategorias])

    if (permisosDelUsuario.indexOf(14) === -1) return <SinPermisos />
    if (!configArticulos.configArticulos?.activo) return (<p>No están habilitados los artículos.</p>)
    if (!configArticulos.configArticulos?.subcategorias.activo) return (<p>No están habilitadas las subcategorias.</p>)

    if (isLoadingCreaRegistro || isUninitializedGetCategorias || isLoadingGetCategorias) return <Loading />

    if (permisosDelUsuario.indexOf(14) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/articulos"}>
                            Artículos
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/articulos/subcategorias"}>
                            Subcategorías
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<BookmarksIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        {
                            configArticulos.configArticulos?.subcategorias.img_principal.activo &&
                            <Grid item xs={12} md={4}>
                                <CropImage
                                    imagen={imgPrincipal}
                                    setRoute={setImgPrincipal}
                                    maxSize="1000000"
                                    width={(configArticulos.configArticulos?.subcategorias.img_principal?.width ? configArticulos.configArticulos?.subcategorias.img_principal.width : 0)}
                                    height={(configArticulos.configArticulos?.subcategorias.img_principal?.height ? configArticulos.configArticulos?.subcategorias.img_principal.height : 0)}
                                    ruta="articulos/subcategorias"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Categoria"
                                value={categoria}
                                options={categoriasOptions.map((item) => ({
                                    value: item.id,
                                    label: item.nombre
                                }))}
                                onChange={setCategoria}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<BookmarksIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}