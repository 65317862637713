import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { getStateConfigClientes } from '../../../../store/slices/configClientes'
import { getStateConfigVentas } from '../../../../store/slices/configVentas'
import { useGetPedidosMutation, useGetPedidosColumnasForOrderByMutation } from '../../../../store/api/endpoints/pedidos/pedidosApiSlice'

import PedidosFiltros from './PedidosFiltros'


import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada2 from '../../../elementos/TablaPaginada2/TablaPaginada2'

import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import RequestPageIcon from '@mui/icons-material/RequestPage'


export default function Pedidos() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)
    const configClientes = useSelector(getStateConfigClientes)
    const configVentas = useSelector(getStateConfigVentas)

    let visiblesTemp = []
    visiblesTemp.push(true) //estado
    visiblesTemp.push(true) //nro
    visiblesTemp.push(true) //fecha
    if (configVentas.configVentas.envio_metodo.activo) visiblesTemp.push(false) //entrega
    visiblesTemp.push(true) //cliente
    if (configClientes.configClientes.direccion.activo) visiblesTemp.push(false) //codigo postal
    if (configClientes.configClientes.documento.activo) visiblesTemp.push(false) //documento
    if (configArticulos.configArticulos.precio.activo) visiblesTemp.push(false) //total
    if (configArticulos.configArticulos.precio.activo) visiblesTemp.push(false) //cobranza
    visiblesTemp.push(true) //Acciones

    var configTable = {
        page: 1,
        textsearch: '',
        from: moment().add(-20, 'years').format("YYYY-MM-DD"),
        to: moment().format('YYYY-MM-DD'),
        orderBy: [[1, 2]],
        filtros: [null, null, null, [], null, null],
        visibles: visiblesTemp,
        clienteAnterior: "",
        cliente: "",
        clienteInput: "",
        clientesOptions: "",
        nropedidoAnterior: "",
        nropedido: "",
        nropedidoInput: "",
        nropedidoOptions: "",
    }
    var configLocalstorage = "conftable-ventas-pedidos"
    if (localStorage.getItem(configLocalstorage)) {
        configTable = JSON.parse(localStorage.getItem(configLocalstorage))
    }

    const [clienteAnterior, setClienteAnterior] = useState(configTable.clienteAnterior)
    const [cliente, setCliente] = useState((configTable.cliente ? configTable.cliente : ''))
    const [clienteInput, setClienteInput] = useState((configTable.clienteInput ? configTable.clienteInput : ''))
    const [clientesOptions, setClientesOptions] = useState((configTable.clientesOptions ? configTable.clientesOptions : []))

    const [nropedidoAnterior, setNropedidoAnterior] = useState(configTable.nropedidoAnterior)
    const [nropedido, setNropedido] = useState((configTable.nropedido ? configTable.nropedido : ''))
    const [nropedidoInput, setNropedidoInput] = useState((configTable.nropedidoInput ? configTable.nropedidoInput : ''))
    const [nropedidoOptions, setNropedidoOptions] = useState((configTable.nropedidoOptions ? configTable.nropedidoOptions : []))

    if (permisosDelUsuario.indexOf(44) == -1) return (<p>No tienes acceso a visualizar los pedidos.</p>)

    const thead = []
    thead.push({ label: "Estado", align: "center" })
    thead.push({ label: "N°", align: "right" })
    thead.push({ label: "Fecha", align: "center" })
    if (configVentas.configVentas.envio_metodo.activo) thead.push({ label: "Entrega", align: "center" })
    thead.push({ label: "Cliente", align: "left" })
    if (configClientes.configClientes.direccion.activo) thead.push({ label: "Cod. Postal", align: "right" })
    if (configClientes.configClientes.documento.activo) thead.push({ label: "Documento", align: "left" })
    if (configArticulos.configArticulos.precio.activo) thead.push({ label: "Total", align: "right" })
    if (configArticulos.configArticulos.precio.activo) thead.push({ label: "Cobranza", align: "right" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "estadoRegistro", valor: "estadoNombre" })
    tbody.push({ tipo: "texto", valor: "id" })
    tbody.push({ tipo: "fecha", valor: "fecha" })
    if (configVentas.configVentas.envio_metodo.activo) tbody.push({ tipo: "fecha", valor: "fecha_estimada_entrega" })
    tbody.push({ tipo: "texto", valor: "clienteNombre" })
    if (configClientes.configClientes.direccion.activo) tbody.push({ tipo: "texto", valor: "codigo_postal" })
    if (configClientes.configClientes.documento.activo) tbody.push({ tipo: "texto", valor: "clienteDocumento" })
    if (configArticulos.configArticulos.precio.activo) tbody.push({ tipo: "precio", options: [",", ".", "$ "], valor: "total" })
    if (configArticulos.configArticulos.precio.activo) tbody.push({ tipo: "precio", options: [",", ".", "$ "], valor: "cobranza" })

    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/" + urlParams.dominio + '/ventas/pedidos/' })
    tbody.push({ tipo: "botones", valor: botones })


    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Pedidos</Typography>
                    </Breadcrumbs>
                }
                icon={<RequestPageIcon />}
                end={(permisosDelUsuario.indexOf(45) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio +"/ventas/pedidos/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada2
                FiltrosCustomizado={PedidosFiltros}
                api={useGetPedidosMutation}
                thead={thead}
                tbody={tbody}
                columnasOptionsGet={useGetPedidosColumnasForOrderByMutation}
                autoCompleteAsync={{
                    funciones: {
                        cliente: {
                            setClienteAnterior,
                            setCliente,
                            setClienteInput,
                            setClientesOptions,
                        },
                        nropedido: {
                            setNropedidoAnterior,
                            setNropedido,
                            setNropedidoInput,
                            setNropedidoOptions,
                        }
                    },
                    valores: {
                        cliente: {
                            clienteAnterior,
                            cliente,
                            clienteInput,
                            clientesOptions,
                        },
                        nropedido: {
                            nropedidoAnterior,
                            nropedido,
                            nropedidoInput,
                            nropedidoOptions,
                        }
                    }

                }}
                configLocalstorage={configLocalstorage}
                configTable={configTable}
            />
        </>
    )

}

