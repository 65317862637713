import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigClientes } from '../../../../store/slices/configClientes'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { getStateConfigVentas } from '../../../../store/slices/configVentas'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import { openFeedback } from '../../../../store/slices/feedback'

import { useCreateClienteMutation } from '../../../../store/api/endpoints/clientes/clientesApiSlice'
import { useGetPagoMetodoAllMutation } from '../../../../store/api/endpoints/config/configPagoMetodoApiSlice'
import { useGetPagoPlazosAllMutation } from '../../../../store/api/endpoints/config/configPagoPlazosApiSlice'
import { useGetEnvioMetodosAllMutation } from '../../../../store/api/endpoints/config/configEnvioMetodosApiSlice'
import { useGetAllUsuariosMutation } from '../../../../store/api/endpoints/usuarios/usuarios'
import { useGetTiposDocumentosMutation, useGetTiposCondIvaMutation } from '../../../../store/api/endpoints/codigosAfip'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Divider } from '@mui/material'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import TextEditor from '../../../elementos/TextEditor'

import '../../../../assets/css/vistaFormulario.css'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import BadgeIcon from '@mui/icons-material/Badge'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import MailIcon from '@mui/icons-material/Mail'
import HttpIcon from '@mui/icons-material/Http'
import MapIcon from '@mui/icons-material/Map'


export default function ClienteNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configClientes = useSelector(getStateConfigClientes)
    const configArticulos = useSelector(getStateConfigArticulos)
    const configVentas = useSelector(getStateConfigVentas)


    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreateClienteMutation()

    const [
        getEnvioMetodos,
        {
            data: responseGetEnvioMetodos,
            isUninitialized: isUninitializedGetEnvioMetodos,
            isLoading: isLoadingGetEnvioMetodos,
            isSuccess: isSuccessGetEnvioMetodos,
            isError: isErrorGetEnvioMetodos,
        }
    ] = useGetEnvioMetodosAllMutation()

    const [
        getPagoMetodos,
        {
            data: responseGetPagoMetodos,
            isUninitialized: isUninitializedGetPagoMetodos,
            isLoading: isLoadingGetPagoMetodos,
            isSuccess: isSuccessGetPagoMetodos,
            isError: isErrorGetPagoMetodos,
        }
    ] = useGetPagoMetodoAllMutation()

    const [
        getPagoPlazos,
        {
            data: responseGetPagoPlazos,
            isUninitialized: isUninitializedGetPagoPlazos,
            isLoading: isLoadingGetPagoPlazos,
            isSuccess: isSuccessGetPagoPlazos,
            isError: isErrorGetPagoPlazos,
        }
    ] = useGetPagoPlazosAllMutation()

    const [
        getVendedores,
        {
            data: responseGetVendedores,
            isUninitialized: isUninitializedGetVendedores,
            isLoading: isLoadingGetVendedores,
            isSuccess: isSuccessGetVendedores,
            isError: isErrorGetVendedores,
        }
    ] = useGetAllUsuariosMutation()

    const [
        getTiposDocumentos,
        {
            data: responseGetTiposDocumentos,
            isUninitialized: isUninitializedGetTiposDocumentos,
            isLoading: isLoadingGetTiposDocumentos,
            isSuccess: isSuccessGetTiposDocumentos,
            isError: isErrorGetTiposDocumentos,
        }
    ] = useGetTiposDocumentosMutation()

    const [
        getTiposCondIva,
        {
            data: responseGetTiposCondIva,
            isUninitialized: isUninitializedGetTiposCondIva,
            isLoading: isLoadingGetTiposCondIva,
            isSuccess: isSuccessGetTiposCondIva,
            isError: isErrorGetTiposCondIva,
        }
    ] = useGetTiposCondIvaMutation()


    const [nombre, setNombre] = useState("")
    const [razon_social, setRazonSocial] = useState("")
    const [tipo_doc, setTipoDocumento] = useState("")
    const [documento, setDocumento] = useState("")
    const [condicion_iva, setCondicionIva] = useState("")
    const [direccion, setDireccion] = useState("")
    const [localidad, setLocalidad] = useState("")
    const [codigo_postal, setCodigoPostal] = useState("")
    const [provincia, setProvincia] = useState("")
    const [pais, setPais] = useState("")
    const [telefono, setTelefono] = useState("")
    const [whatsapp, setWhatsapp] = useState("")
    const [email, setEmail] = useState("")
    const [web, setWeb] = useState("")
    const [comentarios, setComentarios] = useState("")
    const [lista_precios, setListaPrecios] = useState("")
    const [pago_metodo_default, setPagoMetodoDefault] = useState("")
    const [pago_plazo_default, setPagoPlazoDefault] = useState("")
    const [envio_metodo_default, setEnvioMetodoDefault] = useState("")
    const [vendedor, setVendedor] = useState("")
    const [options_pago_metodos, setOptionsPagoMetodos] = useState([])
    const [options_pago_plazos, setOptionsPagoPlazos] = useState([])
    const [options_envios_metodos, setOptionsEnviosMetodos] = useState([])
    const [options_vendedores, setOptionsVendedores] = useState([])
    const [options_tiposDocumentos, setOptionsTiposDocumentos] = useState([])
    const [options_tiposCondIva, setOptionsTiposCondIva] = useState([])


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            razon_social,
            tipo_doc,
            documento,
            condicion_iva,
            direccion,
            localidad,
            codigo_postal,
            provincia,
            pais,
            telefono,
            whatsapp,
            email,
            web,
            comentarios,
            lista_precios,
            pago_metodo_default,
            pago_plazo_default,
            envio_metodo_default,
            vendedor,
        }

        createRegistro(body)


    }

    useEffect(() => {
        getEnvioMetodos()
        getPagoMetodos()
        getPagoPlazos()
        getVendedores()
        getTiposDocumentos()
        getTiposCondIva()
    }, [])

    useEffect(() => {

        if (isSuccessGetEnvioMetodos) {

            let arrTemp = [{ id: 0, nombre: '-' }]
            for (let i = 0; i < responseGetEnvioMetodos.length; i++) {
                arrTemp.push({
                    id: responseGetEnvioMetodos[i].id,
                    nombre: responseGetEnvioMetodos[i].nombre,
                })
            }

            setOptionsEnviosMetodos(arrTemp)
        }

    }, [isSuccessGetEnvioMetodos, isErrorGetEnvioMetodos])

    useEffect(() => {

        if (isSuccessGetPagoMetodos) {

            let arrTemp = [{ id: 0, nombre: '-' }]
            for (let i = 0; i < responseGetPagoMetodos.length; i++) {
                arrTemp.push({
                    id: responseGetPagoMetodos[i].id,
                    nombre: responseGetPagoMetodos[i].nombre,
                })
            }

            setOptionsPagoMetodos(arrTemp)
        }

    }, [isSuccessGetPagoMetodos, isErrorGetPagoMetodos])

    useEffect(() => {

        if (isSuccessGetPagoPlazos) {

            let arrTemp = [{ id: 0, nombre: '-' }]
            for (let i = 0; i < responseGetPagoPlazos.length; i++) {
                arrTemp.push({
                    id: responseGetPagoPlazos[i].id,
                    nombre: responseGetPagoPlazos[i].nombre,
                })
            }

            setOptionsPagoPlazos(arrTemp)
        }

    }, [isSuccessGetPagoPlazos, isErrorGetPagoPlazos])

    useEffect(() => {

        if (isSuccessGetVendedores) {

            setOptionsVendedores(responseGetVendedores)

        }

    }, [isSuccessGetVendedores, isErrorGetVendedores])

    useEffect(() => {

        if (isSuccessGetTiposDocumentos) {

            setOptionsTiposDocumentos(responseGetTiposDocumentos)

        }

    }, [isSuccessGetTiposDocumentos, isErrorGetTiposDocumentos])

    useEffect(() => {

        if (isSuccessGetTiposCondIva) {

            setOptionsTiposCondIva(responseGetTiposCondIva)

        }

    }, [isSuccessGetTiposCondIva, isErrorGetTiposCondIva])

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/ventas/clientes/" + responseCreaRegistro.codigo)

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    if (permisosDelUsuario.indexOf(39) === -1) return <SinPermisos />


    if (
        isLoadingCreaRegistro ||
        isUninitializedGetEnvioMetodos ||
        isLoadingGetEnvioMetodos ||
        isUninitializedGetPagoMetodos ||
        isLoadingGetPagoMetodos ||
        isUninitializedGetPagoPlazos ||
        isLoadingGetPagoPlazos ||
        isUninitializedGetVendedores ||
        isLoadingGetVendedores ||
        isUninitializedGetTiposCondIva ||
        isLoadingGetTiposCondIva
    ) return <Loading />

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes"}>
                            Clientes
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                            <Input
                                label="Nombre del Cliente"
                                icon={<PeopleAltIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Select
                                label="Vendedores"
                                value={vendedor}
                                options={options_vendedores.map((elemento, index) => ({
                                    value: elemento.id,
                                    label: elemento.nombre
                                }))}
                                onChange={setVendedor}
                                helperText="Vendedor asignado."
                                required
                            />
                        </Grid>
                        {/* ------ Teléfono y redes */}
                        {
                            (
                                configClientes.configClientes?.telefono.activo ||
                                configClientes.configClientes?.whatsapp.activo ||
                                configClientes.configClientes?.email.activo ||
                                configClientes.configClientes?.web.activo
                            ) &&
                            <>
                                <Grid item xs={12}><Divider></Divider></Grid>
                                <Grid item xs={12} md={12}>
                                    <h3 style={{ marginBottom: 0, textAlign: 'left' }}>Teléfono y Redes</h3>
                                </Grid>
                            </>

                        }
                        {
                            configClientes.configClientes?.telefono.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Teléfono Fijo"
                                    icon={<LocalPhoneIcon />}
                                    value={telefono}
                                    onChange={setTelefono}
                                    type="tel"
                                    required={configClientes.configClientes.telefono.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.whatsapp.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Whatsapp"
                                    icon={<WhatsAppIcon />}
                                    value={whatsapp}
                                    onChange={setWhatsapp}
                                    type="tel"
                                    required={configClientes.configClientes.whatsapp.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.email.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Email"
                                    icon={<MailIcon />}
                                    value={email}
                                    onChange={setEmail}
                                    type="mail"
                                    required={configClientes.configClientes.email.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.web.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Sitio Web"
                                    icon={<HttpIcon />}
                                    value={web}
                                    onChange={setWeb}
                                    type="text"
                                    required={configClientes.configClientes.web.required}
                                />
                            </Grid>
                        }
                        {/* ------- Facturación  */}
                        {
                            (
                                configClientes.configClientes?.tipo_doc.activo ||
                                configClientes.configClientes?.documento.activo ||
                                configClientes.configClientes?.razon_social.activo ||
                                configClientes.configClientes?.direccion.activo
                            ) &&
                            <>
                                <Grid item xs={12}><Divider></Divider></Grid>
                                <Grid item xs={12} md={12}>
                                    <h3 style={{ marginBottom: 0, textAlign: 'left' }}>Facturación</h3>
                                </Grid>
                            </>

                        }
                        {
                            configClientes.configClientes?.tipo_doc.activo &&
                            <Grid item xs={12} md={4}>
                                <Select
                                    label="Tipo Documento"
                                    value={tipo_doc}
                                    options={options_tiposDocumentos.map((elem) => {
                                        return {
                                            label: elem.descripcion,
                                            value: elem.codigo
                                        }
                                    })}
                                    onChange={setTipoDocumento}
                                    type="text"
                                    required={configClientes.configClientes.tipo_doc.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.documento.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="N° Documento"
                                    icon={<BadgeIcon />}
                                    value={documento}
                                    onChange={setDocumento}
                                    type="text"
                                    required={configClientes.configClientes.documento.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.razon_social.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Razón Social"
                                    icon={<BadgeIcon />}
                                    value={razon_social}
                                    onChange={setRazonSocial}
                                    type="text"
                                    required={configClientes.configClientes.razon_social.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.tipo_doc.activo &&
                            <Grid item xs={12} md={4}>
                                <Select
                                    label="Condición IVA"
                                    value={condicion_iva}
                                    options={options_tiposCondIva.map((elem) => {
                                        return {
                                            label: elem.descripcion,
                                            value: elem.codigo
                                        }
                                    })}
                                    onChange={setCondicionIva}
                                    type="text"
                                    required={configClientes.configClientes?.tipo_doc.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.direccion.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Dirección"
                                    icon={<MapIcon />}
                                    value={direccion}
                                    onChange={setDireccion}
                                    type="text"
                                    required={configClientes.configClientes.direccion.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.direccion.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Localidad"
                                    icon={<MapIcon />}
                                    value={localidad}
                                    onChange={setLocalidad}
                                    type="text"
                                    required={configClientes.configClientes.direccion.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.direccion.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Código Postal"
                                    icon={<MapIcon />}
                                    value={codigo_postal}
                                    onChange={setCodigoPostal}
                                    type="text"
                                    required={configClientes.configClientes.direccion.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.direccion.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="Provincia"
                                    icon={<MapIcon />}
                                    value={provincia}
                                    onChange={setProvincia}
                                    type="text"
                                    required={configClientes.configClientes.direccion.required}
                                />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.direccion.activo &&
                            <Grid item xs={12} md={4}>
                                <Input
                                    label="País"
                                    icon={<MapIcon />}
                                    value={pais}
                                    onChange={setPais}
                                    type="text"
                                    required={configClientes.configClientes.direccion.required}
                                />
                            </Grid>
                        }
                        {/* ------- Valores Predeterminados  */}
                        {
                            (
                                configArticulos.configArticulos.precio.activo ||
                                configVentas.configVentas.pago_metodo.activo ||
                                configVentas?.configVentas?.pago_plazo?.activo ||
                                configVentas?.configVentas?.envio_metodo?.activo
                            ) &&
                            <>
                                <Grid item xs={12}><Divider></Divider></Grid>
                                <Grid item xs={12} md={12}>
                                    <h3 style={{ marginBottom: 0, textAlign: 'left' }}>Valores Predeterminados de {nombre}</h3>
                                </Grid>
                            </>

                        }
                        {
                            configArticulos.configArticulos.precio.activo &&
                            <Grid item xs={12} md={4}>
                                <Select
                                    label="Lista de Precios"
                                    value={lista_precios}
                                    options={configArticulos.configArticulos.precio.nombres.map((elemento, index) => ({
                                        value: index + 1,
                                        label: elemento
                                    }))}
                                    onChange={setListaPrecios}
                                    helperText="Lista de precios del cliente."
                                    required={configArticulos.configArticulos.precio.activo}
                                />
                            </Grid>
                        }
                        {
                            configVentas.configVentas.pago_metodo.activo &&
                            <Grid item xs={12} md={4}>
                                <Select
                                    label="Método de Pago"
                                    value={pago_metodo_default}
                                    options={options_pago_metodos.map((elemento, index) => ({
                                        value: elemento.id,
                                        label: elemento.nombre
                                    }))}
                                    helperText="Pago por defecto del cliente."
                                    onChange={setPagoMetodoDefault}
                                    required={!configVentas.configVentas.pago_metodo.elige_cliente}
                                />
                            </Grid>
                        }
                        {
                            configVentas?.configVentas?.pago_plazo?.activo &&
                            <Grid item xs={12} md={4}>
                                <Select
                                    label="Plazo de Pago"
                                    value={pago_plazo_default}
                                    options={options_pago_plazos.map((elemento, index) => ({
                                        value: elemento.id,
                                        label: elemento.nombre
                                    }))}
                                    onChange={setPagoPlazoDefault}
                                    helperText="Plazo de pago por defecto del cliente."
                                    required={!configVentas.configVentas.pago_metodo.elige_cliente}
                                />
                            </Grid>
                        }
                        {
                            configVentas?.configVentas?.envio_metodo?.activo &&
                            <Grid item xs={12} md={4}>
                                <Select
                                    label="Método de Envío"
                                    value={envio_metodo_default}
                                    options={options_envios_metodos.map((elemento, index) => ({
                                        value: elemento.id,
                                        label: elemento.nombre
                                    }))}
                                    onChange={setEnvioMetodoDefault}
                                    helperText="Método de envío por defecto del cliente."
                                    required={!configVentas.configVentas.envio_metodo.elige_cliente}
                                />
                            </Grid>
                        }

                        {
                            configClientes.configClientes?.comentarios &&
                            <Grid item xs={12} md={12}>
                                <h3 style={{ marginBottom: 0, textAlign: 'left' }}>Observaciones</h3>
                                <br />
                            </Grid>
                        }
                        {
                            configClientes.configClientes?.comentarios &&
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextEditor textoHtml={comentarios} setTextoHtml={setComentarios} />
                                </Grid>
                            </Grid>
                        }

                        <Grid item xs={12}><Divider></Divider></Grid>

                    </Grid>

                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}