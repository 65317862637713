import { React, useState, useEffect } from 'react';

import '../../assets/css/Input.css';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';



export default function SelectMultiple(props) {

    const [opcionesElegidas, setOpcionesElegidas] = useState([]);

    useEffect(() => {

        setOpcionesElegidas(props.options.filter(function(option){ return(props.value.indexOf(option.value) >= 0) }));

    }, [props]);

    const handleChange = (e) => {

        //actualizo el value del padre
        props.onChange(e.target.value.map((elemento) => { return (elemento.value) }));

        //actualizo el value del textfield de este componente
        setOpcionesElegidas(e.target.value);

    }
    return (
        <TextField
            select
            sx={{
                color: 'red',
                marginTop: '15px'
            }}
            size={props.size}
            label={props.label}
            value={opcionesElegidas}
            disabled={props.disabled}
            onChange={handleChange}
            helperText={props.helperText}
            fullWidth
            required={props.required}
            type={props.type}
            SelectProps={{
                multiple: true,
                renderValue: (selected) => selected.map((elemento) => { return (elemento.label) }).join(', ')
            }}

        >
            {props.options.map((option) => (
                <MenuItem key={option.value} value={option}>
                    <Checkbox checked={opcionesElegidas.map((elemento) => { return (elemento.value) }).indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                </MenuItem>
            ))}
        </TextField>
    )

}