import { React } from 'react'
import { useSelector } from 'react-redux'


import { getUsuarioPermisos } from '../../store/slices/auth'

export default function Home() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    return (
        <>
            <p>Esto es el home</p>
        </>
    )

}