import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'

import { useUpdateClienteDireccionMutation, useGetClienteDireccionMutation } from '../../../../store/api/endpoints/clientes/clientesDireccionesApiSlice'

import { GetClienteNombre } from '../clientes/GetClienteNombre'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'


import '../../../../assets/css/vistaFormulario.css'

import FmdGoodIcon from '@mui/icons-material/FmdGood'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import CheckIcon from '@mui/icons-material/Check'

export default function ClientesDireccionesEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateClienteDireccionMutation()

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro
        }
    ] = useGetClienteDireccionMutation()


    const [nombre, setNombre] = useState('')
    const [calle, setCalle] = useState('')
    const [altura, setAltura] = useState('')
    const [piso, setPiso] = useState('')
    const [departamento, setDepartamento] = useState('')
    const [localidad, setLocalidad] = useState('')
    const [codigo_postal, setCodigoPostal] = useState('')
    const [provincia, setProvincia] = useState('')
    const [pais, setPais] = useState('')
    const [observaciones, setObservaciones] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            calle,
            altura,
            piso,
            departamento,
            localidad,
            codigo_postal,
            provincia,
            pais,
            observaciones,
        }

        updateRegistro({ body, idCliente: urlParams.idCliente, idDireccion: urlParams.id })


    }

    useEffect(() => {
        getRegistro({ idCliente: urlParams.idCliente, idDireccion: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetRegistro) {
            setNombre(responseGetRegistro.nombre)
            setCalle(responseGetRegistro.calle)
            setAltura(responseGetRegistro.altura)
            setPiso(responseGetRegistro.piso)
            setDepartamento(responseGetRegistro.departamento)
            setLocalidad(responseGetRegistro.localidad)
            setCodigoPostal(responseGetRegistro.codigo_postal)
            setProvincia(responseGetRegistro.provincia)
            setPais(responseGetRegistro.pais)
            setObservaciones(responseGetRegistro.observaciones)
        }

    }, [isSuccessGetRegistro, isErrorGetRegistro])

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/ventas/clientes-direcciones/" + urlParams.idCliente)

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    if (permisosDelUsuario.indexOf(56) === -1) return <SinPermisos />

    if (
        isUninitializedGetRegistro ||
        isLoadingGetRegistro ||
        isLoadingUpdateRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(56) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/"}>
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.idCliente}>
                            <GetClienteNombre idCliente={urlParams.idCliente} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes-direcciones/" + urlParams.idCliente}>
                            Direcciones
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<FmdGoodIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Nombre"
                                icon={<FmdGoodIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Calle"
                                icon={<FmdGoodIcon />}
                                value={calle}
                                onChange={setCalle}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Input
                                label="Altura"
                                icon={<FmdGoodIcon />}
                                value={altura}
                                onChange={setAltura}
                                type="tel"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Input
                                label="Piso"
                                icon={<FmdGoodIcon />}
                                value={piso}
                                onChange={setPiso}
                                type="text"
                                helperText="Si no tiene colocar #"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Input
                                label="Departamento"
                                icon={<FmdGoodIcon />}
                                value={departamento}
                                onChange={setDepartamento}
                                type="text"
                                helperText="Si no tiene colocar #"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="Localidad"
                                icon={<FmdGoodIcon />}
                                value={localidad}
                                onChange={setLocalidad}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="Código Postal"
                                icon={<FmdGoodIcon />}
                                value={codigo_postal}
                                onChange={setCodigoPostal}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="Provincia"
                                icon={<FmdGoodIcon />}
                                value={provincia}
                                onChange={setProvincia}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Input
                                label="País"
                                icon={<FmdGoodIcon />}
                                value={pais}
                                onChange={setPais}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label="Observaciones"
                                icon={<ChatBubbleIcon />}
                                value={observaciones}
                                onChange={setObservaciones}
                                type="text"
                                multiline
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}