import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigClientes } from '../../../../store/slices/configClientes'
import { useGetClientesMutation } from '../../../../store/api/endpoints/clientes/clientesApiSlice'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'


export default function Clientes() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configClientes = useSelector(getStateConfigClientes)

    if (permisosDelUsuario.indexOf(38) == -1) return (<p>No tienes acceso a visualizar los clientes.</p>)

    const thead = []
    thead.push({ label: "Nombre", align: "left" })
    if (configClientes.configClientes?.documento?.activo) thead.push({ label: "Documento", align: "left" })
    if (configClientes.configClientes?.razon_social?.activo) thead.push({ label: "Razón Social", align: "left" })
    if (configClientes.configClientes?.email?.activo) thead.push({ label: "Email", align: "left" })
    if (configClientes.configClientes?.telefono?.activo) thead.push({ label: "Teléfono", align: "left" })
    if (configClientes.configClientes?.whatsapp?.activo) thead.push({ label: "Whatsapp", align: "left" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "nombre" })
    if (configClientes.configClientes?.documento?.activo) tbody.push({ tipo: "texto", valor: "documento" })
    if (configClientes.configClientes?.razon_social?.activo) tbody.push({ tipo: "texto", valor: "razon_social" })
    if (configClientes.configClientes?.email?.activo) tbody.push({ tipo: "texto", valor: "email" })
    if (configClientes.configClientes?.telefono?.activo) tbody.push({ tipo: "texto", valor: "telefono" })
    if (configClientes.configClientes?.whatsapp?.activo) tbody.push({ tipo: "texto", valor: "whatsapp" })

    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/" + urlParams.dominio + '/ventas/clientes/' })
    tbody.push({ tipo: "botones", valor: botones })

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Clientes</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
                end={(permisosDelUsuario.indexOf(39) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/ventas/clientes/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetClientesMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}

