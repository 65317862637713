import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'
import { useCreatePagoPlazoMutation } from '../../../../store/api/endpoints/config/configPagoPlazosApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CheckIcon from '@mui/icons-material/Check'

export default function ConfigPagoPlazosNuevo() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreatePagoPlazoMutation()


    const [nombre, setNombre] = useState('')
    const [dias, setDias] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            dias,
        }

        createRegistro(body)


    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/config/pago-plazos")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    if (permisosDelUsuario.indexOf(43) === -1) return <SinPermisos />

    if (isLoadingCreaRegistro) return <Loading />

    if (permisosDelUsuario.indexOf(43) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/config/pago-plazos"}>
                            Plazos de Pago
                        </Link>
                        <Typography color="text.primary">Crear</Typography>
                    </Breadcrumbs>
                }
                icon={<CalendarMonthIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<CalendarMonthIcon />}
                                value={nombre}
                                onChange={setNombre}
                                helperText="Nombre del plazo ej: A 30 días"
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Días"
                                icon={<CalendarMonthIcon />}
                                value={dias}
                                onChange={setDias}
                                helperText="Cantidad de días desde la fecha de compra (sólo números)"
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}