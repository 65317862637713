import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigArticulos } from '../../../../store/slices/configArticulos'
import { getStateConfigClientes } from '../../../../store/slices/configClientes'
import { getStateConfigVentas } from '../../../../store/slices/configVentas'
import { useGetPedidoByIdMutation } from '../../../../store/api/endpoints/pedidos/pedidosApiSlice'

import PedidoArticulos from './PedidoArticulos'
import CambiosDeEstado from './CambiosDeEstado'

import pedidoPrint from '../pedidoPrint/PedidoPrint'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Moment from '../../../elementos/Moment'
import ConfirmDelete from '../../../elementos/ConfirmDelete'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Divider } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import RequestPageIcon from '@mui/icons-material/RequestPage'
import PedidoPrint from '../pedidoPrint/PedidoPrint'


export default function PedidoDetalle() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configArticulos = useSelector(getStateConfigArticulos)
    const configClientes = useSelector(getStateConfigClientes)
    const configVentas = useSelector(getStateConfigVentas)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetPedidoByIdMutation()

    useEffect(() => {
        getRegistro(urlParams.id)
    }, [])

    if (isUninitializedGetRegistro || isLoadingGetRegistro) return (<Loading />)

    if (isSuccessGetRegistro) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/pedidos"}>
                            Pedidos
                        </Link>
                        <Typography color="text.primary">N° {responseGetRegistro.encabezado.id}</Typography>
                    </Breadcrumbs>
                }
                icon={<RequestPageIcon />}
                end={<>
                    <PedidoPrint idPedido={urlParams.id} />
                    {(permisosDelUsuario.indexOf(48) > -1 ?
                        <ConfirmDelete
                            soloIcono
                            httpDelete={"ventas/pedidos/" + urlParams.id}
                            returnUrl={"/" + urlParams.dominio + "/ventas/pedidos"}
                            variant="outlined"
                            size="small"
                        /> : '')}
                </>}
            />
            <Container component="main" maxWidth="lg" style={{ padding: '0px 15px', fontSize: '0.75rem' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5} align="left">
                        <p><b>N°: </b>{urlParams.id}</p>
                        <Divider />
                        <p><b>Estado: </b>{responseGetRegistro.encabezado.estadoNombre}</p>
                        <Divider />
                        <p><b>Fecha: </b>{(responseGetRegistro.encabezado.fecha ? <Moment date={responseGetRegistro.encabezado.fecha} format='DD-MM-YYYY' /> : "-")}</p>
                        <Divider />
                        {
                            configVentas.configVentas.envio_metodo.activo && <>
                                <p><b>Método de envío: </b>{responseGetRegistro.encabezado.envioMetodo}</p>
                                <Divider />
                                <p><b>Entrega estimada: </b>{(responseGetRegistro.encabezado.fecha_estimada_entrega ? <Moment date={responseGetRegistro.encabezado.fecha_estimada_entrega} format='DD-MM-YYYY' /> : "-")}</p>
                                <Divider />
                                {
                                    responseGetRegistro.encabezado.metodo_envio > 0 &&
                                    <>
                                        <p><b>Dirección: </b>{responseGetRegistro.encabezado.direccion}, {responseGetRegistro.encabezado.localidad} ({responseGetRegistro.encabezado.codigo_postal}), {responseGetRegistro.encabezado.provincia}, {responseGetRegistro.encabezado.pais}</p>
                                        <Divider />
                                    </>
                                }

                            </>
                        }
                        {
                            configVentas.configVentas.pago_metodo.activo && <>
                                <p><b>Método de pago: </b>{responseGetRegistro.encabezado.pagoMetodo}</p>
                                <Divider />
                            </>
                        }
                        {
                            configVentas.configVentas.pago_plazo.activo && <>
                                <p><b>Plazo de pago: </b>{responseGetRegistro.encabezado.pagoPlazo}</p>
                                <Divider />
                            </>
                        }
                        {
                            configArticulos.configArticulos.precio.activo && <>
                                <p><b>Subtotal: </b>{responseGetRegistro.encabezado.subtotal}</p>
                                <Divider />
                                <p><b>Total: </b>{responseGetRegistro.encabezado.total}</p>
                                <Divider />
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={2}></Grid>
                    <Grid item xs={12} md={5} align="center">
                        <CambiosDeEstado
                            dominio={urlParams.dominio}
                            responseGetPedido={responseGetRegistro}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <PedidoArticulos
                            dominio={urlParams.dominio}
                            configArticulos={configArticulos}
                            responseGetPedido={responseGetRegistro}
                        />
                    </Grid>
                </Grid>

            </Container>
        </>
    )

}

