import { tiendasApi } from '../../api'

export const clientesLoginsApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientesLogins: builder.mutation({
            query(data){
                const { page, textsearch, parametroEnUrl } = data
                return {
                    url: "/ventas/clientes-logins/" + parametroEnUrl + "/paginated/",
                    method: 'GET',
                    headers: {
                        page,
                        textsearch,
                    },
                }
            }
        }),
        getClienteLogins: builder.mutation({
            query: (id) => ({
                url: '/ventas/clientes-logins/' + id,
                method: 'GET',
            })
        }),
        createClienteLogins: builder.mutation({
            query: (body) => ({
                url: '/ventas/clientes-logins',
                method: 'POST',
                body: body,
            })
        }),
        updateClienteLogins: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/ventas/clientes-logins/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeClienteLogins: builder.mutation({
            query: (id) => ({
                url: '/ventas/clientes-logins/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetClientesLoginsMutation,
    useGetClienteLoginsMutation,
    useCreateClienteLoginsMutation,
    useUpdateClienteLoginsMutation,
    useRemoveClienteLoginsMutation,
} = clientesLoginsApiSlice