import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigServicios } from '../../../../store/slices/configServicios'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetServCategoriasForSelectMutation } from '../../../../store/api/endpoints/servicios/servcatApiSlice'
import { useGetServSubcategoriaMutation, useUpdateServSubcategoriaMutation } from '../../../../store/api/endpoints/servicios/servsubcatApiSlice'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import CropImage from '../../../elementos/CropImage'

import '../../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import BookmarksIcon from '@mui/icons-material/Bookmarks'

export default function SubcategoriaEditar() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configServicios = useSelector(getStateConfigServicios)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getCategorias,
        {
            data: responseGetCategorias,
            isUninitialized: isUninitializedGetCategorias,
            isLoading: isLoadingGetCategorias,
            isSuccess: isSuccessGetCategorias,
            isError: isErrorGetCategorias,
        }
    ] = useGetServCategoriasForSelectMutation()

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetServSubcategoriaMutation()

    const [
        updateRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useUpdateServSubcategoriaMutation()


    const [nombre, setNombre] = useState('')
    const [categoria, setCategoria] = useState('')
    const [imgPrincipal, setImgPrincipal] = useState('')
    const [categoriasOptions, setCategoriasOptions] = useState([])
    const [isSpinner, setIsSpinner] = useState(true)


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            img_principal: imgPrincipal,
            categoria,
        }

        updateRegistro({ id: urlParams.id, body })

    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/servicios/subcategorias")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    useEffect(() => {

        getCategorias()

    }, [])

    useEffect(() => {

        if (isSuccessGetCategorias) {
            setCategoriasOptions(responseGetCategorias)
            getRegistro(urlParams.id)
        }

    }, [isSuccessGetCategorias, isErrorGetCategorias])

    useEffect(() => {

        if (isSuccessGetRegistro) {
            setImgPrincipal(responseGetRegistro.img_principal)
            setNombre(responseGetRegistro.nombre)
            setCategoria(responseGetRegistro.categoria)

            setIsSpinner(false)
        }

    }, [isSuccessGetRegistro, isErrorGetRegistro])

    if (permisosDelUsuario.indexOf(12) === -1) return <SinPermisos />
    if (!configServicios.configServicios?.activo) return (<p>No están habilitados los servicios.</p>)
    if (!configServicios.configServicios?.subcategorias.activo) return (<p>No están habilitadas las subcategorias.</p>)

    if (isSpinner || isUninitializedGetCategorias || isLoadingGetCategorias || isLoadingCreaRegistro || isUninitializedGetRegistro || isLoadingGetRegistro) return <Loading />

    if (isErrorGetCategorias || isErrorGetRegistro) return (<p>Ocurrió un error al cargar la página.</p>)

    if (permisosDelUsuario.indexOf(12) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/servicios">
                            Servicios
                        </Link>
                        <Link underline="hover" color="inherit" to="/servicios/subcategorias">
                            Subcategorías
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<BookmarksIcon />}
                end={(permisosDelUsuario.indexOf(13) !== -1 ? <ConfirmDelete
                    httpDelete={"servsubcat/" + urlParams.id}
                    returnUrl={"/servicios/subcategorias"}
                    variant="outlined"
                    size="small"
                /> : '')}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        {
                            (configServicios.configServicios?.subcategorias.img_principal.activo) &&
                            <Grid item xs={12} md={4}>
                                <CropImage
                                    imagen={imgPrincipal}
                                    setRoute={setImgPrincipal}
                                    maxSizeMB={1}
                                    width={configServicios.configServicios?.subcategorias.img_principal.width}
                                    height={configServicios.configServicios?.subcategorias.img_principal.height}
                                    ruta="servicios/subcategorias"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Categoria"
                                value={categoria}
                                options={categoriasOptions.map((item) => ({
                                    value: item.id,
                                    label: item.nombre
                                }))}
                                onChange={setCategoria}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<BookmarksIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}