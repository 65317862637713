import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'

import Loading from '../../elementos/Loading'
import SinPermisos from '../auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetConfigUsuariosMutation, useUpdateConfigUsuariosMutation } from '../../../store/api/endpoints/config/configUsuariosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'

import HeaderVista from '../../elementos/HeaderVista'
import SwitchInput from '../../elementos/SwitchInput'
import Input from '../../elementos/Input'

import '../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'

export default function ConfigUsuarios() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetConfigUsuariosMutation()


    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateConfigUsuariosMutation()


    const [img_principalActivo, setImgPrincipalActivo] = useState(false)
    const [img_principalRequired, setImgPrincipalRequired] = useState(false)
    const [img_principalHeight, setImgPrincipalHeight] = useState(0)
    const [img_principalWidth, setImgPrincipalWidth] = useState(0)

    const [posicionActivo, setPosicionActivo] = useState(false)
    const [posicionRequired, setPosicionRequired] = useState(false)

    const [emailActivo, setEmailActivo] = useState(false)
    const [emailRequired, setEmailRequired] = useState(false)

    const [telWhatsappActivo, setWhatsappActivo] = useState(false)
    const [telWhatsappRequired, setWhatsappRequired] = useState(false)

    const [telFijoActivo, setFijoActivo] = useState(false)
    const [telFijoRequired, setFijoRequired] = useState(false)



    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            img_principal: {
                activo: img_principalActivo,
                required: img_principalRequired,
                height: img_principalHeight,
                width: img_principalWidth,
            },
            posicion: {
                activo: posicionActivo,
                required: posicionRequired,
            },
            email: {
                activo: emailActivo,
                required: emailRequired,
            },
            tel_whatsapp: {
                activo: telWhatsappActivo,
                required: telWhatsappRequired,
            },
            tel_fijo: {
                activo: telFijoActivo,
                required: telFijoRequired,
            },
        }

        updateRegistro(body)


    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se actualizó el registro!"
            }))

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        getConfig()

    }, [])

    useEffect(() => {

        if (isSuccessGetConfig) {
            setImgPrincipalActivo(responseGetConfig.img_principal.activo)
            setImgPrincipalRequired(responseGetConfig.img_principal.required)
            setImgPrincipalHeight(responseGetConfig.img_principal.height)
            setImgPrincipalWidth(responseGetConfig.img_principal.width)
            setPosicionActivo(responseGetConfig.posicion.activo)
            setPosicionRequired(responseGetConfig.posicion.required)
            setEmailActivo(responseGetConfig.email.activo)
            setEmailRequired(responseGetConfig.email.required)
            setWhatsappActivo(responseGetConfig.tel_whatsapp.activo)
            setWhatsappRequired(responseGetConfig.tel_whatsapp.required)
            setFijoActivo(responseGetConfig.tel_fijo.activo)
            setFijoRequired(responseGetConfig.tel_fijo.required)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])


    if (permisosDelUsuario.indexOf(32) === -1) return <SinPermisos />

    if (
        isLoadingGetConfig ||
        isUninitializedGetConfig ||
        isLoadingUpdateRegistro
    ) return <Loading />

    if (permisosDelUsuario.indexOf(32) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="#">
                            Config.
                        </Link>
                        <Typography color="text.primary">Usuarios</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Imágen de Perfil</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setImgPrincipalActivo}
                                value={img_principalActivo}
                                helperText="Indica si los servicios tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setImgPrincipalWidth}
                                value={img_principalWidth}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={img_principalActivo}
                                disabled={!img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setImgPrincipalHeight}
                                value={img_principalHeight}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={img_principalActivo}
                                disabled={!img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Posición</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Posición Activo"
                                onChange={setPosicionActivo}
                                value={posicionActivo}
                                helperText="Indica si los usuarios tienen una posición (puesto de trabajo)."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Posición Obligatoria"
                                onChange={setPosicionRequired}
                                value={(posicionActivo ? posicionRequired : false)}
                                helperText="Indica si la posición es obligatoria."
                                disabled={!posicionActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Email</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Email Activo"
                                onChange={setEmailActivo}
                                value={emailActivo}
                                helperText="Indica si los usuarios tienen email"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Email Obligatorio"
                                onChange={setEmailRequired}
                                value={(emailActivo ? emailRequired : false)}
                                helperText="Indica si el email es obligatorio."
                                disabled={!emailActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Whatsapp</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Whatsapp Activo"
                                onChange={setWhatsappActivo}
                                value={telWhatsappActivo}
                                helperText="Indica si los usuarios tienen whatsapp"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Whatsapp Obligatorio"
                                onChange={setWhatsappRequired}
                                value={(telWhatsappActivo ? telWhatsappRequired : false)}
                                helperText="Indica si el whatsapp es obligatorio."
                                disabled={!telWhatsappActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Teléfono Fijo</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Tel Fijo Activo"
                                onChange={setFijoActivo}
                                value={telFijoActivo}
                                helperText="Indica si los usuarios tienen teléfono fijo"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Tel Fijo Obligatorio"
                                onChange={setFijoRequired}
                                value={(telFijoActivo ? telFijoRequired : false)}
                                helperText="Indica si el teléfono fijo es obligatorio."
                                disabled={!telFijoActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}