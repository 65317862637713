import { React, useState } from 'react';

import '../../assets/css/Input.css'

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

export default function Input(props) {

    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState(props.helperText);

    const soloNumeros = (e) => {
        return String(e.target.value)
        .match(/^[0-9]+$/i);
    }

    const soloNumerosDecimales = (e) => {
        return String(e.target.value)
        .match(/^[+-]?\d+(\.\d+)?$/);
    }

    const email = (e) => {
        return String(e.target.value)
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    const alfanumerico = (e) => {
        return String(e.target.value)
        .match(/^[a-z0-9]+$/i);
    }

    const validarRestricciones = (e) => {

        let todoValido = true;

        if( props.restricciones?.includes('soloNumeros') && !soloNumeros(e) ){
            setHelperText("¡Sólo números!");
            todoValido = false;
        }

        if( props.restricciones?.includes('soloNumerosDecimales') && !soloNumerosDecimales(e) ){
            setHelperText("¡Sólo números decimales!");
            todoValido = false;
        }

        if( props.restricciones?.includes('email') && !email(e) ){
            setHelperText("¡Ingresa un email válido!");
            todoValido = false;
        }

        if( props.restricciones?.includes('alfanumerico') && !alfanumerico(e) ){
            setHelperText("¡Sólo letras y números!");
            todoValido = false;
        }


        if( todoValido ){
            setError(false);
            setHelperText(props.helperText);
        }else{
            setError(true);
        }
        props.onChange(e.target.value);

    }

    return(
        <TextField
            sx={{
                color: 'red',
                marginTop: '15px'
            }}
            size={(props.size ? props.size : '')}
            label={props.label}
            value={props.value}
            onChange={ (e) => validarRestricciones(e) }
            helperText={helperText}
            fullWidth
            autoFocus={props.autoFocus}
            disabled={props.disabled}
            required={props.required}
            type={props.type} 
            error={error || props.error}
            multiline={props.multiline}
            InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                {props.icon}
                </InputAdornment>
            ),
            }}
        />
    )

}