import React from 'react'

import HomeIcon from '@mui/icons-material/Home'
import LocalCafeIcon from '@mui/icons-material/LocalCafe'
import StoreIcon from '@mui/icons-material/Store'
import TurnedInIcon from '@mui/icons-material/TurnedIn'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import WidgetsIcon from '@mui/icons-material/Widgets'
import CloseIcon from '@mui/icons-material/Close'
import RequestPageIcon from '@mui/icons-material/RequestPage'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import BakeryDiningIcon from '@mui/icons-material/BakeryDining'
import PaymentIcon from '@mui/icons-material/Payment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

export const SidebarIcono = ({codigo}) => {

    if( codigo == 1 ) return (<HomeIcon fontSize="small" />)
    if( codigo == 2 ) return (<LocalCafeIcon fontSize="small" />)
    if( codigo == 3 ) return (<StoreIcon fontSize="small" />)
    if( codigo == 4 ) return (<TurnedInIcon fontSize="small" />)
    if( codigo == 5 ) return (<BookmarksIcon fontSize="small" />)
    if( codigo == 6 ) return (<PeopleAltIcon fontSize="small" />)
    if( codigo == 7 ) return (<SettingsIcon fontSize="small" />)
    if( codigo == 8 ) return (<WidgetsIcon fontSize="small" />)
    if( codigo == 9 ) return (<RequestPageIcon fontSize="small" />)
    if( codigo == 10 ) return (<ReceiptIcon fontSize="small" />)
    if( codigo == 11 ) return (<PointOfSaleIcon fontSize="small" />)
    if( codigo == 12 ) return (<BakeryDiningIcon fontSize="small" />)
    if( codigo == 13 ) return (<PaymentIcon fontSize="small" />)
    if( codigo == 14 ) return (<CalendarMonthIcon fontSize="small" />)
    if( codigo == 15 ) return (<AccessTimeIcon fontSize="small" />)
    if( codigo == 16 ) return (<LocalShippingIcon fontSize="small" />)
    if( codigo == 17 ) return (<PictureAsPdfIcon fontSize="small" />)

    

    return (<CloseIcon />)

}
