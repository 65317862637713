import { React, useState } from 'react';
import moment from 'moment';

import '../../assets/css/RangoFechas.css';

import Select from './Select';
import Input from './Input';
import ButtonIcon from './ButtonIcon';

import Grid from '@mui/material/Grid';

import TodayIcon from '@mui/icons-material/Today';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';

const valoresRangoFechas = [
    {
        label: 'Todo el tiempo',
        value: moment().add('-20', 'years').format('YYYY-MM-DD') + "/" + moment().format('YYYY-MM-DD')
    },
    {
        label: 'Hoy',
        value: moment().format('YYYY-MM-DD') + "/" + moment().format('YYYY-MM-DD')
    },
    {
        label: 'Ayer',
        value: moment().add('-1', 'day').format('YYYY-MM-DD') + "/" + moment().add('-1', 'day').format('YYYY-MM-DD')
    },
    {
        label: 'Esta Semana',
        value: moment().weekday(0).format('YYYY-MM-DD') + "/" + moment().weekday(6).format('YYYY-MM-DD')
    },
    {
        label: 'Últimos 7 días',
        value: moment().add('-7', 'day').format('YYYY-MM-DD') + "/" + moment().format('YYYY-MM-DD')
    },
    {
        label: 'Últimos 30 días',
        value: moment().add('-30', 'day').format('YYYY-MM-DD') + "/" + moment().format('YYYY-MM-DD')
    },
    {
        label: 'Últimos 60 días',
        value: moment().add('-60', 'day').format('YYYY-MM-DD') + "/" + moment().format('YYYY-MM-DD')
    },
    {
        label: 'Este Mes',
        value: moment().startOf('month').format("YYYY-MM-DD") + "/" + moment().endOf('month').format('YYYY-MM-DD')
    },
    {
        label: 'El mes pasado',
        value: moment().add('-1', 'month').startOf('month').format("YYYY-MM-DD") + "/" + moment().add('-1', 'month').endOf('month').format('YYYY-MM-DD')
    },
    {
        label: 'Otra',
        value: "custom"
    }
]

export default function RangoFechas(props) {

    const bloqueFechasInicial = (valoresRangoFechas.map(elemento => (elemento.value)).includes(props.bloqueFechas) ? props.bloqueFechas : 'custom')

    const [rangoFechas, setRangoFechas] = useState(bloqueFechasInicial)
    const [fechaInicio, setFechaInicio] = useState(String(props.bloqueFechas).split("/")[0])
    const [fechaFin, setFechaFin] = useState(String(props.bloqueFechas).split("/")[1])

    const handleChangeRangoFechas = (rango) => {

        setRangoFechas(rango)

        if (rango !== 'custom') props.changeBloqueFechas(rango)

    }

    const aplicarOtrasFechas = () => {

        props.changeBloqueFechas(fechaInicio + "/" + fechaFin)

    }


    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Select
                    size={(props.size ? props.size : '')}
                    label="Rango Filtrado"
                    value={rangoFechas}
                    icon={<TodayIcon />}
                    defaultValue=""
                    options={valoresRangoFechas}
                    onChange={handleChangeRangoFechas}
                    type="text"
                    required
                />
            </Grid>
            {
                rangoFechas === 'custom' &&
                <>
                    <Grid item xs={12} md={3}>
                        <Input
                            size={(props.size ? props.size : '')}
                            label="Desde"
                            icon={<TodayIcon />}
                            value={fechaInicio}
                            onChange={setFechaInicio}
                            type="date"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Input
                            size={(props.size ? props.size : '')}
                            label="Hasta"
                            icon={<TodayIcon />}
                            value={fechaFin}
                            onChange={setFechaFin}
                            type="date"
                            required
                        />
                    </Grid>
                    {
                        props.botonAplicar &&
                        <Grid item xs={12} md={2} className="gridAplica">
                            <ButtonIcon
                                noLink
                                variant="contained"
                                startIcon={<CallMissedOutgoingIcon />}
                                handleClick={aplicarOtrasFechas}
                            >
                                Aplicar
                            </ButtonIcon>
                        </Grid>
                    }
                </>
            }

        </Grid>
    )

}