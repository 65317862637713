import { React } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getUsuarioPermisos } from '../../../../store/slices/auth'

import TablaPaginada from '../../../elementos/TablaPaginada'
import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'

import { useGetEstadosPedidoPaginatedMutation } from '../../../../store/api/endpoints/config/configEstadosPedidoApiSlice'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import '../../../../assets/css/vistaFormulario.css'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

export default function ConfigEstadosPedido() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    if (permisosDelUsuario.indexOf(42) == -1) return (<p>No tienes acceso a visualizar los estados de un pedido.</p>)


    const thead = []
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Estado Previo", align: "left" })
    thead.push({ label: "Color Fondo", align: "center" })
    thead.push({ label: "Color Texto", align: "center" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "estado_previo" })
    tbody.push({ tipo: "texto", valor: "color_fondo" })
    tbody.push({ tipo: "texto", valor: "color_texto" })

    let botones = []
    if (permisosDelUsuario.indexOf(42) > -1) botones.push({ color: "primary", icono: <EditIcon />, ruta: "/" + urlParams.dominio + '/config/estados-pedidos/' })
    tbody.push({ tipo: "botones", valor: botones })

    if (permisosDelUsuario.indexOf(42) > -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Estados de Pedidos</Typography>
                    </Breadcrumbs>
                }
                icon={<AccessTimeIcon />}
                end={(permisosDelUsuario.indexOf(42) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/config/estados-pedidos/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetEstadosPedidoPaginatedMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}