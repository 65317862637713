import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import { getStateConfigUsuarios } from '../../../store/slices/configUsuarios'
import { openFeedback } from '../../../store/slices/feedback'

import Loading from '../../elementos/Loading'
import SinPermisos from '../auth/SinPermisos'
import CheckboxList from '../../elementos/CheckboxList'
import Select from '../../elementos/Select'
import CropImage from '../../elementos/CropImage'
import SwitchInput from '../../elementos/SwitchInput'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import { TiposDeUsuarios } from './TiposDeUsuarios'

import { useGetUsuarioMutation, useUpdateUsuarioMutation } from '../../../store/api/endpoints/usuarios/usuarios'
import { useGetPermisosMutation } from '../../../store/api/endpoints/usuarios/permisos'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'

import Button from '@mui/material/Button'

import '../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import EmailIcon from '@mui/icons-material/Email'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import PasswordIcon from '@mui/icons-material/Password'



export default function CategoriaNueva() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configUsuarios = useSelector(getStateConfigUsuarios)

    const [
        getPermisos,
        {
            data: responseGetPermisos,
            isUninitialized: isUninitializedGetPermisos,
            isLoading: isLoadingGetPermisos,
            isSuccess: isSuccessGetPermisos,
            isError: isErrorGetPermisos,
        }
    ] = useGetPermisosMutation()

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetUsuarioMutation()

    const [
        updateRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useUpdateUsuarioMutation()


    const [activo, setActivo] = useState(false)
    const [nombre, setNombre] = useState('')
    const [img_principal, setImgPrincipal] = useState('')
    const [usuario, setUsuario] = useState('')
    const [posicion, setPosicion] = useState('')
    const [email, setEmail] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [tel_fijo, setTelFijo] = useState('')
    const [contrasena, setContrasena] = useState('')
    const [rol, setRol] = useState('')
    const [permisos, setPermisos] = useState([])
    const [permisosElegidos, setPermisosElegidos] = useState([])
    const [isSpinner, setIsSpinner] = useState(true)


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            activo,
            nombre,
            img_principal,
            posicion,
            usuario,
            email,
            tel_whatsapp,
            tel_fijo,
            contrasena,
            permisos: permisosElegidos,
        }

        updateRegistro({ id: urlParams.id, body })


    }

    useEffect(() => {
        getPermisos()
    }, [])

    useEffect(() => {

        if (isSuccessGetPermisos) {
            setPermisos(responseGetPermisos)
            getRegistro(urlParams.id)
        }

    }, [isSuccessGetPermisos, isErrorGetPermisos])

    useEffect(() => {

        if (isSuccessGetRegistro) {
            setActivo(responseGetRegistro.activo)
            setNombre(responseGetRegistro.nombre)
            setImgPrincipal(responseGetRegistro.img_principal)
            setEmail(responseGetRegistro.email)
            setTelWhatsapp(responseGetRegistro.tel_whatsapp)
            setTelFijo(responseGetRegistro.tel_fijo)
            setPosicion(responseGetRegistro.posicion)
            setUsuario(responseGetRegistro.usuario)
            setContrasena(responseGetRegistro.contrasena)
            setPermisosElegidos(responseGetRegistro.permisos)
            setIsSpinner(false)
        }

    }, [isSuccessGetRegistro, isErrorGetRegistro])

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/usuarios")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    useEffect(() => {
        if (rol !== '') setPermisosElegidos(TiposDeUsuarios[rol].permisos)
    }, [rol])

    if (permisosDelUsuario.indexOf(27) === -1) return <SinPermisos />

    if (isSpinner || isLoadingCreaRegistro || isLoadingGetPermisos || isUninitializedGetPermisos || isLoadingGetRegistro || isUninitializedGetRegistro) return <Loading />

    var columnasPorImg = 12
    var columnasPorImgHijos = 3
    if (configUsuarios.configUsuarios?.img_principal?.activo) {
        columnasPorImg = 8
        columnasPorImgHijos = 6
    }

    if (permisosDelUsuario.indexOf(27) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/usuarios"}>
                            Usuarios
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
                end={(permisosDelUsuario.indexOf(29) !== -1 ? <ConfirmDelete
                    httpDelete={"usuarios/" + urlParams.id}
                    returnUrl={"/" + urlParams.dominio + "/usuarios"}
                    variant="outlined"
                    size="small"
                /> : '')}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        {
                            configUsuarios.configUsuarios?.img_principal?.activo &&
                            <Grid item xs={12} md={4}>
                                <CropImage
                                    imagen={img_principal}
                                    setRoute={setImgPrincipal}
                                    maxSize="1000000"
                                    width={configUsuarios.configUsuarios?.img_principal?.width}
                                    height={configUsuarios.configUsuarios?.img_principal?.height}
                                    ruta="usuarios"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={columnasPorImg}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <SwitchInput
                                        label="Activo"
                                        value={(activo ? true : false)}
                                        onChange={setActivo}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Nombre"
                                        icon={<PeopleAltIcon />}
                                        value={nombre}
                                        onChange={setNombre}
                                        type="text"
                                        required
                                    />
                                </Grid>
                                {
                                    configUsuarios.configUsuarios?.email?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Email"
                                            icon={<EmailIcon />}
                                            value={email}
                                            onChange={setEmail}
                                            type="text"
                                            required={configUsuarios.configUsuarios?.email?.required}
                                        />
                                    </Grid>
                                }
                                {
                                    configUsuarios.configUsuarios?.tel_whatsapp?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Whatsapp"
                                            icon={<WhatsAppIcon />}
                                            value={tel_whatsapp}
                                            onChange={setTelWhatsapp}
                                            type="text"
                                            required={configUsuarios.configUsuarios?.tel_whatsapp?.required}
                                        />
                                    </Grid>
                                }
                                {
                                    configUsuarios.configUsuarios?.tel_fijo?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Fijo"
                                            icon={<LocalPhoneIcon />}
                                            value={tel_fijo}
                                            onChange={setTelFijo}
                                            type="text"
                                            required={configUsuarios.configUsuarios?.tel_fijo?.required}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Usuario"
                                        icon={<PeopleAltIcon />}
                                        value={usuario}
                                        onChange={setUsuario}
                                        type="text"
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Contrasena"
                                        icon={<PasswordIcon />}
                                        value={contrasena}
                                        onChange={setContrasena}
                                        type="password"
                                    />
                                </Grid>
                                {
                                    configUsuarios.configUsuarios?.posicion?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Posición"
                                            icon={<AccessibilityIcon />}
                                            value={posicion}
                                            onChange={setPosicion}
                                            type="text"
                                            required={configUsuarios.configUsuarios?.posicion?.required}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Select
                                label="Rol"
                                value={rol}
                                options={TiposDeUsuarios.map((item, index) => ({
                                    value: index,
                                    label: item.nombre
                                }))}
                                onChange={setRol}
                            />
                            <br />
                            <CheckboxList
                                titulo="Permisos"
                                opciones={permisos}
                                elegidos={permisosElegidos}
                                handleChange={setPermisosElegidos}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}