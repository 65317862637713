import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { useGetClientesDireccionesMutation } from '../../../../store/api/endpoints/clientes/clientesDireccionesApiSlice'

import { GetClienteNombre } from '../clientes/GetClienteNombre'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import FmdGoodIcon from '@mui/icons-material/FmdGood'


export default function ClientesDirecciones() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    if (permisosDelUsuario.indexOf(54) == -1) return (<p>No tienes acceso a visualizar los usuarios.</p>)

    const thead = []
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Calle", align: "left" })
    thead.push({ label: "Altura", align: "left" })
    thead.push({ label: "Localidad", align: "left" })
    thead.push({ label: "Código Postal", align: "center" })
    thead.push({ label: "Provincia", align: "left" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "calle" })
    tbody.push({ tipo: "texto", valor: "altura" })
    tbody.push({ tipo: "texto", valor: "localidad" })
    tbody.push({ tipo: "texto", valor: "codigo_postal" })
    tbody.push({ tipo: "texto", valor: "provincia" })

    let botones = []
    botones.push({ color: "primary", icono: <SearchIcon />, ruta: "/" + urlParams.dominio + '/ventas/clientes-direcciones/' + urlParams.idCliente + '/' })
    tbody.push({ tipo: "botones", valor: botones })

    return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/"}>
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/ventas/clientes/" + urlParams.idCliente}>
                            <GetClienteNombre idCliente={urlParams.idCliente} />
                        </Link>
                        <Typography color="text.primary">Direcciones</Typography>
                    </Breadcrumbs>
                }
                icon={<FmdGoodIcon />}
                end={(permisosDelUsuario.indexOf(55) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/ventas/clientes-direcciones/" + urlParams.idCliente + "/nueva"}
                >
                    Nueva
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetClientesDireccionesMutation}
                thead={thead}
                tbody={tbody}
                parametroEnUrl={urlParams.idCliente}
            />
        </>
    )

}

