import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigServicios } from '../../../../store/slices/configServicios'
import { useGetServSubcategoriasMutation } from '../../../../store/api/endpoints/servicios/servsubcatApiSlice'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import BookmarksIcon from '@mui/icons-material/Bookmarks'




export default function Subcategorias() {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configServicios = useSelector(getStateConfigServicios)

    if (permisosDelUsuario.indexOf(10) == -1) return (<p>No tienes acceso a visualizar las subcategorias.</p>)
    if (!configServicios.configServicios?.activo) return (<p>No están habilitados los servicios.</p>)
    if (!configServicios.configServicios?.categorias.activo) return (<p>No están habilitadas las subcategorías.</p>)
    if (!configServicios.configServicios?.subcategorias.activo) return (<p>No están habilitadas las subcategorías.</p>)

    const thead = []
    if (configServicios.configServicios?.subcategorias.img_principal.activo) thead.push({ label: "Imagen", align: "center" })
    thead.push({ label: "Categoria", align: "left" })
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    if (configServicios.configServicios?.subcategorias.img_principal.activo) tbody.push({ tipo: "img", valor: "img_principal" })
    tbody.push({ tipo: "texto", valor: "categoria" })
    tbody.push({ tipo: "texto", valor: "nombre" })

    let botones = []
    if (permisosDelUsuario.indexOf(12) > -1) botones.push({ color: "primary", icono: <EditIcon />, ruta: "/" + urlParams.dominio + '/servicios/subcategorias/' })
    tbody.push({ tipo: "botones", valor: botones })

    if (permisosDelUsuario.indexOf(10) > -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/servicios"}>
                            Servicios
                        </Link>
                        <Typography color="text.primary">Subcategorías</Typography>
                    </Breadcrumbs>
                }
                icon={<BookmarksIcon />}
                end={(permisosDelUsuario.indexOf(11) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/servicios/subcategorias/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetServSubcategoriasMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}

