import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './assets/css/Routes.css'

//Componentes Vistas
import Layout from './components/vistas/auth/Layout'
import Public from './components/vistas/auth/Public'
import RequireAuth from './components/vistas/auth/RequireAuth'
import Login from './components/vistas/auth/Login'
import Config from './components/vistas/auth/Config'

import Navbar from './components/vistas/Navbar'

import Home from './components/vistas/Home'


import Proveedores from './components/vistas/compras/proveedores/Proveedores'
import ProveedorNuevo from './components/vistas/compras/proveedores/ProveedorNuevo'

import Clientes from './components/vistas/ventas/clientes/Clientes'
import ClienteDetalle from './components/vistas/ventas/clientes/ClienteDetalle'
import ClienteNuevo from './components/vistas/ventas/clientes/ClienteNuevo'
import ClienteEditar from './components/vistas/ventas/clientes/ClienteEditar'

import ClientesLogins from './components/vistas/ventas/clientes-logins/ClientesLogins'
import ClientesLoginsNuevo from './components/vistas/ventas/clientes-logins/ClientesLoginsNuevo'
import ClientesLoginsDetalle from './components/vistas/ventas/clientes-logins/ClientesLoginsDetalle'
import ClientesLoginsEditar from './components/vistas/ventas/clientes-logins/ClientesLoginsEditar'

import ClientesDirecciones from './components/vistas/ventas/clientes-direcciones/ClientesDirecciones'
import ClientesDireccionesDetalle from './components/vistas/ventas/clientes-direcciones/ClientesDireccionesDetalle'
import ClientesDireccionesNueva from './components/vistas/ventas/clientes-direcciones/ClientesDireccionesNueva'
import ClientesDireccionesEditar from './components/vistas/ventas/clientes-direcciones/ClientesDireccionesEditar'

import Pedidos from './components/vistas/ventas/pedidos/Pedidos'
import PedidoDetalle from './components/vistas/ventas/pedidoDetalle/PedidoDetalle'
import PedidoCambiarEstado from './components/vistas/ventas/pedidoCambiarEstado/PedidoCambiarEstado'

import Servicios from './components/vistas/servicios/servicios/Servicios'
import ServicioNuevo from './components/vistas/servicios/servicios/ServicioNuevo'
import ServicioEditar from './components/vistas/servicios/servicios/ServicioEditar'
import ServiciosCategorias from './components/vistas/servicios/categorias/Categorias'
import ServiciosCategoriaNuevo from './components/vistas/servicios/categorias/CategoriaNuevo'
import ServiciosCategoriaEditar from './components/vistas/servicios/categorias/CategoriaEditar'
import ServiciosSubcategorias from './components/vistas/servicios/subcategorias/Subcategorias'
import ServiciosSubcategoriaNuevo from './components/vistas/servicios/subcategorias/SubcategoriaNuevo'
import ServiciosSubcategoriaEditar from './components/vistas/servicios/subcategorias/SubcategoriaEditar'

import Articulos from './components/vistas/articulos/articulos/Articulos'
import ArticuloNuevo from './components/vistas/articulos/articulos/ArticuloNuevo'
import ArticuloEditar from './components/vistas/articulos/articulos/ArticuloEditar'
import ArticulosCategorias from './components/vistas/articulos/categorias/Categorias'
import ArticulosCategoriaNuevo from './components/vistas/articulos/categorias/CategoriaNuevo'
import ArticulosCategoriaEditar from './components/vistas/articulos/categorias/CategoriaEditar'
import ArticulosSubcategorias from './components/vistas/articulos/subcategorias/Subcategorias'
import ArticulosSubcategoriaNuevo from './components/vistas/articulos/subcategorias/SubcategoriaNuevo'
import ArticulosSubcategoriaEditar from './components/vistas/articulos/subcategorias/SubcategoriaEditar'

import Usuarios from './components/vistas/usuarios/Usuarios'
import UsuarioNuevo from './components/vistas/usuarios/UsuarioNuevo'
import UsuarioEditar from './components/vistas/usuarios/UsuarioEditar'

import ConfigUsuarios from './components/vistas/config/ConfigUsuarios'
import ConfigServicios from './components/vistas/config/ConfigServicios'
import ConfigArticulos from './components/vistas/config/ConfigArticulos'
import ConfigClientes from './components/vistas/config/ConfigClientes'
import ConfigVentas from './components/vistas/config/ConfigVentas'
import ConfigReportesPdf from './components/vistas/config/ConfigReportesPdf'
import ConfigReportesPdfPedidoUpdate from './components/vistas/config/reportes-pdf/ConfigReportesPdfPedidoUpdate'
import ConfigReportesPdfPedidoCreate from './components/vistas/config/reportes-pdf/ConfigReportesPdfPedidoCreate'
import ConfigPagoMetodos from './components/vistas/config/pago-metodos/ConfigPagoMetodos'
import ConfigPagoMetodosNuevo from './components/vistas/config/pago-metodos/ConfigPagoMetodosNuevo'
import ConfigPagoMetodosEditar from './components/vistas/config/pago-metodos/ConfigPagoMetodosEditar'
import ConfigPagoPlazos from './components/vistas/config/pago-plazos/ConfigPagoPlazos'
import ConfigPagoPlazosNuevo from './components/vistas/config/pago-plazos/ConfigPagoPlazosNuevo'
import ConfigPagoPlazosEditar from './components/vistas/config/pago-plazos/ConfigPagoPlazosEditar'
import ConfigEnvioMetodos from './components/vistas/config/envio-metodos/ConfigEnvioMetodos'
import ConfigEnvioMetodosNuevo from './components/vistas/config/envio-metodos/ConfigEnvioMetodosNuevo'
import ConfigEnvioMetodosEditar from './components/vistas/config/envio-metodos/ConfigEnvioMetodosEditar'
import ConfigEstadosPedido from './components/vistas/config/estados-pedidos/ConfigEstadosPedido'
import ConfigEstadosPedidoNuevo from './components/vistas/config/estados-pedidos/ConfigEstadosPedidoNuevo'
import ConfigEstadosPedidosEditar from './components/vistas/config/estados-pedidos/ConfigEstadosPedidosEditar'



export default function Router() {



  return (
    <BrowserRouter>
      <Navbar />
      
      <Routes >

        <Route path="/:dominio" element={<Layout />} />
        <Route index path="/:dominio" element={<Public />} />
        <Route path="/:dominio/login" element={<Login />} />

        <Route element={<RequireAuth />}>
          <Route path="/:dominio"  element={<Config />}>
            <Route path="home" element={<Home />} />

            
            <Route path="compras/proveedores" element={<Proveedores />} />
            <Route path="compras/proveedores/nuevo" element={<ProveedorNuevo />} />

            <Route path="ventas/clientes" element={<Clientes />} />
            <Route path="ventas/clientes/:id" element={<ClienteDetalle />} />
            <Route path="ventas/clientes/nuevo" element={<ClienteNuevo />} />
            <Route path="ventas/clientes/:id/editar" element={<ClienteEditar />} />

            <Route path="ventas/clientes-logins/:idCliente" element={<ClientesLogins />} />
            <Route path="ventas/clientes-logins/:idCliente/nuevo" element={<ClientesLoginsNuevo />} />
            <Route path="ventas/clientes-logins/:idCliente/:id" element={<ClientesLoginsDetalle />} />
            <Route path="ventas/clientes-logins/:idCliente/:id/editar" element={<ClientesLoginsEditar />} />
            
            <Route path="ventas/clientes-direcciones/:idCliente" element={<ClientesDirecciones />} />
            <Route path="ventas/clientes-direcciones/:idCliente/nueva" element={<ClientesDireccionesNueva />} />
            <Route path="ventas/clientes-direcciones/:idCliente/:id" element={<ClientesDireccionesDetalle />} />
            <Route path="ventas/clientes-direcciones/:idCliente/:id/editar" element={<ClientesDireccionesEditar />} />

            <Route path="ventas/pedidos" element={<Pedidos />} />
            <Route path="ventas/pedidos/:id" element={<PedidoDetalle />} />
            <Route path="ventas/pedidos/:id/cambiar-estado" element={<PedidoCambiarEstado />} />

            <Route path="servicios" element={<Servicios />} />
            <Route path="servicios/nuevo" element={<ServicioNuevo />} />
            <Route path="servicios/:id" element={<ServicioEditar />} />
            <Route path="servicios/categorias" element={<ServiciosCategorias />} />
            <Route path="servicios/categorias/nuevo" element={<ServiciosCategoriaNuevo />} />
            <Route path="servicios/categorias/:id" element={<ServiciosCategoriaEditar />} />
            <Route path="servicios/subcategorias" element={<ServiciosSubcategorias />} />
            <Route path="servicios/subcategorias/nuevo" element={<ServiciosSubcategoriaNuevo />} />
            <Route path="servicios/subcategorias/:id" element={<ServiciosSubcategoriaEditar />} />

            
            <Route path="articulos" element={<Articulos />} />
            <Route path="articulos/nuevo" element={<ArticuloNuevo />} />
            <Route path="articulos/:id" element={<ArticuloEditar />} />
            <Route path="articulos/categorias" element={<ArticulosCategorias />} />
            <Route path="articulos/categorias/nuevo" element={<ArticulosCategoriaNuevo />} />
            <Route path="articulos/categorias/:id" element={<ArticulosCategoriaEditar />} />
            <Route path="articulos/subcategorias" element={<ArticulosSubcategorias />} />
            <Route path="articulos/subcategorias/nuevo" element={<ArticulosSubcategoriaNuevo />} />
            <Route path="articulos/subcategorias/:id" element={<ArticulosSubcategoriaEditar />} />

            
            <Route path="usuarios" element={<Usuarios />} />
            <Route path="usuarios/nuevo" element={<UsuarioNuevo />} />
            <Route path="usuarios/:id" element={<UsuarioEditar />} />


            
            <Route path="config/usuarios" element={<ConfigUsuarios />} />
            <Route path="config/servicios" element={<ConfigServicios />} />
            <Route path="config/articulos" element={<ConfigArticulos />} />
            <Route path="config/clientes" element={<ConfigClientes />} />
            <Route path="config/ventas" element={<ConfigVentas />} />
            <Route path="config/reportes-pdf" element={<ConfigReportesPdf />} />
            <Route path="config/pago-metodo" element={<ConfigPagoMetodos />} />
            <Route path="config/pago-metodo/nuevo" element={<ConfigPagoMetodosNuevo />} />
            <Route path="config/pago-metodo/:id" element={<ConfigPagoMetodosEditar />} />
            <Route path="config/pago-plazos" element={<ConfigPagoPlazos />} />
            <Route path="config/pago-plazos/nuevo" element={<ConfigPagoPlazosNuevo />} />
            <Route path="config/pago-plazos/:id" element={<ConfigPagoPlazosEditar />} />
            <Route path="config/envio-metodos" element={<ConfigEnvioMetodos />} />
            <Route path="config/envio-metodos/nuevo" element={<ConfigEnvioMetodosNuevo />} />
            <Route path="config/envio-metodos/:id" element={<ConfigEnvioMetodosEditar />} />
            <Route path="config/estados-pedidos" element={<ConfigEstadosPedido />} />
            <Route path="config/estados-pedidos/nuevo" element={<ConfigEstadosPedidoNuevo />} />
            <Route path="config/estados-pedidos/:id" element={<ConfigEstadosPedidosEditar />} />
            <Route path="config/reportes-pdf/pedidos" element={<ConfigReportesPdfPedidoCreate />} />
            <Route path="config/reportes-pdf/pedidos/:slug" element={<ConfigReportesPdfPedidoUpdate />} />

          </Route>

        </Route>

      </Routes>



    </BrowserRouter >
  );

}