import { tiendasApi } from '../api'

export const codigoAfipApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getTiposDocumentos: builder.mutation({
            query: token => ({
                url: 'tipos-afip/tipos-documentos',
                method: 'GET',
            })
        }),
        getTiposComprobantes: builder.mutation({
            query: token => ({
                url: 'tipos-afip/tipos-comprobantes',
                method: 'GET',
            })
        }),
        getTiposCondIva: builder.mutation({
            query: token => ({
                url: 'tipos-afip/tipos-condiva',
                method: 'GET',
            })
        }),
    })
})

export const {
    useGetTiposDocumentosMutation,
    useGetTiposComprobantesMutation,
    useGetTiposCondIvaMutation,
} = codigoAfipApiSlice