import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { openFeedback } from '../../../../store/slices/feedback'
import { useUpdateEstadosPedidoMutation, useGetEstadosPedidoMutation, useGetEstadosPedidoAllMutation } from '../../../../store/api/endpoints/config/configEstadosPedidoApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../auth/SinPermisos'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import InputColorPaleta from '../../../elementos/InputColorPaleta'
import Loading from '../../../elementos/Loading'
import ConfirmDelete from '../../../elementos/ConfirmDelete'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import CheckIcon from '@mui/icons-material/Check'

export default function ConfigEstadosPedidosEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getEstadoPedido,
        {
            data: responseEstadoPedido,
            isUninitialized: isUninitializedEstadoPedido,
            isLoading: isLoadingEstadoPedido,
            isSuccess: isSuccessEstadoPedido,
            isError: isErrorEstadoPedido,
        }
    ] = useGetEstadosPedidoMutation()

    const [
        getEstadosForSelect,
        {
            data: responseGetEstadosForSelect,
            isUninitialized: isUninitializedGetEstadosForSelect,
            isLoading: isLoadingGetEstadosForSelect,
            isSuccess: isSuccessGetEstadosForSelect,
            isError: isErrorGetEstadosForSelect,
        }
    ] = useGetEstadosPedidoAllMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro
        }
    ] = useUpdateEstadosPedidoMutation()



    const [nombre, setNombre] = useState('')
    const [color_fondo, setColorFondo] = useState('')
    const [color_texto, setColorTexto] = useState('')
    const [estado_previo, setEstadoPrevio] = useState('')
    const [estadosForSelect, setEstadosForSelect] = useState([])


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            color_fondo,
            color_texto,
            estado_previo,
        }

        updateRegistro({ id: urlParams.id, body })


    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/config/estados-pedidos")

        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateRegistro.data.message
            }))
        }

    }, [
        isSuccessUpdateRegistro,
        isErrorUpdateRegistro
    ])

    useEffect(() => {

        getEstadoPedido(urlParams.id)

    }, [])

    useEffect(() => {

        if (isSuccessEstadoPedido) {
            setNombre(responseEstadoPedido.nombre)
            setColorFondo(responseEstadoPedido.color_fondo)
            setColorTexto(responseEstadoPedido.color_texto)
            setEstadoPrevio(responseEstadoPedido.estado_previo)
        }

    }, [isSuccessEstadoPedido, isErrorEstadoPedido])

    useEffect(() => {

        getEstadosForSelect()

    }, [])

    useEffect(() => {

        if (isSuccessGetEstadosForSelect) {

            let arrTemp = []
            arrTemp.push({ id: "0", nombre: "Ninguno" })
            responseGetEstadosForSelect.forEach(element => {
                arrTemp.push({
                    id: element.id,
                    nombre: element.nombre,
                })
            })

            setEstadosForSelect(arrTemp)
        }

    }, [isSuccessGetEstadosForSelect, isErrorGetEstadosForSelect])

    if (permisosDelUsuario.indexOf(42) === -1) return <SinPermisos />

    if (isLoadingEstadoPedido || isUninitializedEstadoPedido || isLoadingUpdateRegistro || isLoadingGetEstadosForSelect || isUninitializedGetEstadosForSelect) return <Loading />

    if (permisosDelUsuario.indexOf(42) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/config/estados-pedidos"}>
                            Estados de Pedidos
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<AccessTimeIcon />}
                end={(permisosDelUsuario.indexOf(25) !== -1 ? <ConfirmDelete
                    httpDelete={"config/estados-pedidos/" + urlParams.id}
                    returnUrl={"/" + urlParams.dominio + "/config/estados-pedidos"}
                    variant="outlined"
                    size="small"
                /> : '')}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<AccessTimeIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                icon={<AccessTimeFilledIcon />}
                                label="Estado Anterior"
                                value={estado_previo}
                                options={estadosForSelect.map((item) => ({
                                    value: item.id,
                                    label: item.nombre
                                }))}
                                onChange={setEstadoPrevio}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <InputColorPaleta
                                label="Color Fondo"
                                value={color_fondo}
                                onChange={setColorFondo}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputColorPaleta
                                label="Color Texto"
                                value={color_texto}
                                onChange={setColorTexto}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <p align="center">Ejemplo de colores</p>
                            <div style={{ backgroundColor: color_fondo, color: color_texto, padding: '10px', textAlign: 'center' }}>
                                {nombre}
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}