import { React, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { openFeedback } from '../../../../store/slices/feedback'
import { useGetBySlugMutation, useUpdateBySlugMutation } from '../../../../store/api/endpoints/pedidos-pdf/pedidosPdfApiSlice'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Loading from '../../../elementos/Loading'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import { momentF } from '../../../elementos/Moment'

import { pedidoBody, pedidoArticulos } from '../../../services/VariablesPrintPdf'

import '../../../../assets/css/vistaFormulario.css'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import { Divider } from '@mui/material'

import SettingsIcon from '@mui/icons-material/Settings'
import UpdateIcon from '@mui/icons-material/Update'
import CheckIcon from '@mui/icons-material/Check'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import DataObjectIcon from '@mui/icons-material/DataObject'

export default function ConfigReportesPdfPedidoUpdate() {

    const urlParams = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [nombre, setNombre] = useState('')
    const [body, setBody] = useState('')
    const [articulos, setArticulos] = useState('')

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
            error: errorDataGetRegistro,
        }
    ] = useGetBySlugMutation()

    const [
        updateRegistro,
        {
            data: responseUpdateRegistro,
            isUninitialized: isUninitializedUpdateRegistro,
            isLoading: isLoadingUpdateRegistro,
            isSuccess: isSuccessUpdateRegistro,
            isError: isErrorUpdateRegistro,
            error: errorDataUpdateRegistro,
        }
    ] = useUpdateBySlugMutation()

    useEffect(() => {
        getRegistro(urlParams.slug)
    }, [])

    useEffect(() => {

        if (isSuccessGetRegistro) {
            setNombre(responseGetRegistro.nombre)
            setBody(responseGetRegistro.pdf.body)
            setArticulos(responseGetRegistro.pdf.articulos)
        }

        if (isErrorGetRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }

    }, [isSuccessGetRegistro, isErrorGetRegistro])

    const handleSubmit = (e) => {

        e.preventDefault()

        let bodyTemp = {
            nombre,
            pdf: {
                body: body,
                articulos: articulos,
            }
        }

        updateRegistro({ slug: urlParams.slug, body: bodyTemp })

    }

    useEffect(() => {

        if (isSuccessUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/config/reportes-pdf")
        }

        if (isErrorUpdateRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataGetRegistro.data.message
            }))
        }

    }, [isSuccessUpdateRegistro, isErrorUpdateRegistro])

    if (isUninitializedGetRegistro || isLoadingGetRegistro || isLoadingUpdateRegistro) return (<Loading />)

    if (isSuccessGetRegistro) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="#">
                            Config.
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/config/reportes-pdf"}>
                            Reportes PDF
                        </Link>
                        <Typography color="text.primary">{responseGetRegistro.nombre}</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
                end={<ConfirmDelete
                    soloIcono
                    httpDelete={"ventas/pedidos-pdf/" + urlParams.slug}
                    returnUrl={"/" + urlParams.dominio + "/config/reportes-pdf"}
                    variant="outlined"
                    size="small"
                />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Nombre"
                                icon={<FileCopyIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                label="Ult. Modif"
                                icon={<UpdateIcon />}
                                value={momentF(responseGetRegistro.ult_modif_fecha) + " - " + responseGetRegistro.ult_modif_usr_nombre}
                                type="text"
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <p><b>Variables para Body</b></p>
                            {
                                pedidoBody.map(element => {
                                    return (<p style={{ marginBottom: '0px', marginTop: '5px' }}>- {element.variable}</p>)
                                })
                            }
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Input
                                label="Body"
                                icon={<DataObjectIcon />}
                                value={body}
                                onChange={setBody}
                                type="text"
                                multiline
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <p><b>Variables para Loop de Artículos</b></p>
                            {
                                pedidoArticulos.map(element => {
                                    return (<p style={{ marginBottom: '0px', marginTop: '5px' }}>- {element.variable}</p>)
                                })
                            }
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Input
                                label="Artículos"
                                icon={<DataObjectIcon />}
                                value={articulos}
                                onChange={setArticulos}
                                type="text"
                                multiline
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}