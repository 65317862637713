import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigServicios } from '../../../../store/slices/configServicios'
import { useGetServiciosMutation } from '../../../../store/api/endpoints/servicios/serviciosApiSlice'

import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import TablaPaginada from '../../../elementos/TablaPaginada'

import '../../../../assets/css/table.css'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import LocalCafeIcon from '@mui/icons-material/LocalCafe'



const Servicios = () => {

    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configServicios = useSelector(getStateConfigServicios)

    if (permisosDelUsuario.indexOf(6) == -1) return (<p>No tienes acceso a visualizar los servicios.</p>)

    const thead = []
    if (configServicios.configServicios?.img_principal.activo) thead.push({ label: "Imagen", align: "center" })
    if (configServicios.configServicios?.categorias?.activo) thead.push({ label: "Categoría", align: "left" })
    if (configServicios.configServicios?.subcategorias?.activo) thead.push({ label: "Subcategoría", align: "left" })
    thead.push({ label: "Nombre", align: "left" })
    if (configServicios.configServicios?.precio) thead.push({ label: "Precio", align: "center" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    if (configServicios.configServicios?.img_principal.activo) tbody.push({ tipo: "img", valor: "img_principal" })
    if (configServicios.configServicios?.categorias?.activo) tbody.push({ tipo: "texto", valor: "categoria" })
    if (configServicios.configServicios?.subcategorias?.activo) tbody.push({ tipo: "texto", valor: "subcategoria" })
    tbody.push({ tipo: "texto", valor: "titulo" })
    if (configServicios.configServicios?.precio) tbody.push({ tipo: "texto", valor: "precio" })

    let botones = []
    if (permisosDelUsuario.indexOf(8) > -1) botones.push({ color: "primary", icono: <EditIcon />, ruta: "/" + urlParams.dominio + '/servicios/' })
    tbody.push({ tipo: "botones", valor: botones })

    if (permisosDelUsuario.indexOf(6) > -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Servicios</Typography>
                    </Breadcrumbs>
                }
                icon={<LocalCafeIcon />}
                end={(permisosDelUsuario.indexOf(7) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/" + urlParams.dominio + "/servicios/nuevo"}
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <TablaPaginada
                api={useGetServiciosMutation}
                thead={thead}
                tbody={tbody}
            />
        </>
    )

}

export default Servicios