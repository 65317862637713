import { React, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { useGetNombreClienteMutation } from '../../../../store/api/endpoints/clientes/clientesApiSlice'


export function GetClienteNombre({idCliente}) {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetNombreClienteMutation()

    useEffect(() => {

        getRegistro(idCliente)

    }, [])

    if (permisosDelUsuario.indexOf(38) == -1) return (<>-</>)

    if (
        isLoadingGetRegistro ||
        isUninitializedGetRegistro
    ) return (<></>)

    return (<>{responseGetRegistro.nombre}</>)

}

