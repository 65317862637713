import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import { getStateConfigServicios } from '../../../../store/slices/configServicios'
import { openFeedback } from '../../../../store/slices/feedback'

import Loading from '../../../elementos/Loading'
import SinPermisos from '../../auth/SinPermisos'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import CropImage from '../../../elementos/CropImage'

import { useGetServCategoriaMutation, useUpdateServCategoriaMutation } from '../../../../store/api/endpoints/servicios/servcatApiSlice'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'

import '../../../../assets/css/vistaFormulario.css'

import CheckIcon from '@mui/icons-material/Check'
import TurnedInIcon from '@mui/icons-material/TurnedIn'

export default function CategoriaEditar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const urlParams = useParams()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const configServicios = useSelector(getStateConfigServicios)

    const [
        getRegistro,
        {
            data: responseGetRegistro,
            isUninitialized: isUninitializedGetRegistro,
            isLoading: isLoadingGetRegistro,
            isSuccess: isSuccessGetRegistro,
            isError: isErrorGetRegistro,
        }
    ] = useGetServCategoriaMutation()

    const [
        updateRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useUpdateServCategoriaMutation()


    const [nombre, setNombre] = useState('')
    const [imgPrincipal, setImgPrincipal] = useState('')
    const [isSpinner, setIsSpinner] = useState(true)


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            img_principal: imgPrincipal
        }

        updateRegistro({ id: urlParams.id, body })

    }

    useEffect(() => {

        getRegistro(urlParams.id)

    }, [])

    useEffect(() => {

        if (isSuccessGetRegistro) {
            setNombre(responseGetRegistro.nombre)
            setImgPrincipal(responseGetRegistro.img_principal)

            setIsSpinner(false)
        }

    }, [isSuccessGetRegistro, isErrorGetRegistro])

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el registro!"
            }))

            navigate("/" + urlParams.dominio + "/servicios/categorias")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    if (permisosDelUsuario.indexOf(9) === -1) return <SinPermisos />
    if (!configServicios.configServicios?.activo) return (<p>No están habilitados los servicios.</p>)
    if (!configServicios.configServicios?.categorias.activo) return (<p>No están habilitadas las categorías.</p>)

    if (isSpinner || isLoadingCreaRegistro || isUninitializedGetRegistro || isLoadingGetRegistro) return <Loading />

    if (permisosDelUsuario.indexOf(5) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/servicios"}>
                            Servicios
                        </Link>
                        <Link underline="hover" color="inherit" to={"/" + urlParams.dominio + "/servicios/categorias"}>
                            Categorías
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<TurnedInIcon />}
                end={(permisosDelUsuario.indexOf(5) !== -1 ? <ConfirmDelete
                    httpDelete={"servcat/" + urlParams.id}
                    returnUrl={"/" + urlParams.dominio + "/servicios/categorias"}
                    variant="outlined"
                    size="small"
                /> : '')}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        {
                            configServicios.configServicios?.categorias.img_principal.activo &&
                            <Grid item xs={12} md={4}>
                                <CropImage
                                    imagen={imgPrincipal}
                                    setRoute={setImgPrincipal}
                                    maxSizeMB={1}
                                    width={configServicios.configServicios?.categorias.img_principal.width}
                                    height={configServicios.configServicios?.categorias.img_principal.height}
                                    ruta="servicios/categorias"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<TurnedInIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}